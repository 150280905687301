import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'
import HighchartsVue from 'highcharts-vue'  
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

Vue.config.productionTip = false

Vue.config.warnHandler = function (msg, vm, trace) {};

Vue.use(HighchartsVue)   

new Vue({
  store,
  router,
  vuetify, 
  icons: {
    iconfont: ['mdiSvg',  'mdi' , 'mdiSvg' , 'md' , 'fa' , 'fa4']
  },
  render: h => h(App)
}).$mount('#app')
