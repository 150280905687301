<template>
  <v-container class="containerView">
    <v-progress-linear
        :active="loading"
        color="#1890ff"
        indeterminate
        height="6"/>
    <v-row class="rowView" :style="[loading? {height:'calc(100% - 6px)'}:{height:'100%'}]">
      <v-col :cols=12 class="colView">
        <v-row class="rowView" style="height:45px;">
          <v-btn outlined color="#16304E" @click="snoozePopup=true">
            Snooze Selected
            <v-icon right>
              notifications_off
            </v-icon>
          </v-btn>
          <v-menu v-model="dateMenu" :close-on-content-click="false" :close-on-click="false"
                  :nudge-right="40" lazy transition="scale-transition" offset-y
                  full-width min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="dateText"
                            label="Date range" prepend-icon="event" readonly
                            v-on="on" style="max-width:300px;padding-top:0px;margin-left:20px;"/>
            </template>
            <v-col :cols=12 class="rowView" style="background-color:white;">
              <v-row class="rowView">
                <v-col :cols=6 class="colView">
                  <v-date-picker
                      v-model="startDate"
                      color="#1890ff"
                      scrollable
                      event-color="#1890ff"
                      :max="endDate"
                      :min="minDate"
                      :events="calcRange(startDate, endDate)"/>
                </v-col>
                <v-col :cols=6 class="colView">
                  <v-date-picker
                      v-model="endDate"
                      color="#1890ff" scrollable
                      event-color="#1890ff"
                      :min="startDate"
                      :max="maxDate"
                      :events="calcRange(startDate, endDate)"/>
                </v-col>
              </v-row>
              <v-row class="rowView" style="justify-content:flex-end;">
                <v-btn style="margin:5px;" @click="datesChange()" text>OK</v-btn>
                <v-btn style="margin:5px;" @click="dateMenu=false" text>Cancel</v-btn>
              </v-row>
            </v-col>
          </v-menu>
          <v-spacer/>
          <v-autocomplete v-model="tierModel" :items="['Tier 1','Tier 2', 'Tier 3']"
                          outlined flat dense clearable
                          label="Filter by tier"
                          style="font-size:14px;max-width:150px;margin:auto 10px;"
                          @change="filter"/>
          <v-autocomplete v-model="sevModel" :items="['High','Medium', 'Low']"
                          outlined flat dense clearable
                          label="Filter by severity"
                          style="font-size:14px;max-width:180px;margin:auto 10px;"
                          @change="filter"/>
          <v-autocomplete v-model="application.model" :items="application.options"
                          outlined flat dense
                          label="Filter by application"
                          style="font-size:14px;max-width:180px;margin:auto 10px;"
                          item-text="name"
                          item-value="value"
                          @change="filter"/>
          <v-text-field
              v-model="search"
              style="max-width:450px;padding-top:0px;"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              color='#1890ff'/>
        </v-row>
        <v-divider/>
        <v-row class="rowView" ref="parentDiv" style="height:calc(100% - 45px);">
          <v-data-table style="width:100%;height:100%;"
                        fixed-header disable-pagination hide-default-footer
                        v-model="selected"
                        :show-select="true"
                        item-key="id"
                        :height="dataTableHeight"
                        :headers="headers"
                        :items="alerts"
                        :search="search"
                        v-resize="updateDataTableHeight"
                        @click:row="rowClick">
            <template v-slot:item.snoozed="{ item }">
              <v-icon v-if="item.snoozed" style="margin:5px;">
                notifications_off
              </v-icon>
              <v-icon v-else style="margin:5px;" color="#1890ff">
                notifications
              </v-icon>
              <span v-if="item.snoozed">until {{ item.until }}</span>
            </template>
          </v-data-table>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="snoozePopup" width="550" persistent>
      <v-card>
        <v-col :cols=12 class="colView">
          <v-row class="rowView" style="height:45px;">
            <div class="titleColumn">Disable notifications until</div>

            <v-menu v-model="snoozeDateMenu"
                    :nudge-right="40" lazy transition="scale-transition" offset-y
                    full-width max-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="snoozeDate"
                              label="Date range" prepend-icon="event" readonly
                              v-on="on" style="max-width:300px;padding-top:0px;"/>
              </template>
              <v-col :cols=12 class="rowView" style="background-color:white;">
                <v-date-picker
                    v-model="snoozeDate"
                    color="#1890ff" scrollable/>
              </v-col>
            </v-menu>
          </v-row>
          <v-row class="rowView titleColumn" style="height:45px;">
            <div class="titleColumn">for the following alerts</div>
          </v-row>
          <v-data-table style="width:550px;"
                        fixed-header disable-pagination hide-default-footer
                        :headers="headers"
                        :items="selected"
                        height="400"/>
          <v-row class="rowView" style="height:45px;">
            <v-spacer/>
            <v-btn dark color="#1890ff" style="margin:5px;" @click="snooze()">
              OK
            </v-btn>
            <v-btn dark color="#1890ff" style="margin:5px;" @click="snoozePopup=false">
              Cancel
            </v-btn>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import {mapGetters, mapActions} from 'vuex';
import SingleValCard from '../controls/SingleValCard';

import InfoHover from '../controls/InfoHover';
import Highcharts from 'highcharts'
import axios from 'axios'


export default {
  name: 'FleetAlerts',
  components: {
    InfoHover
  },
  data: function () {
    return {
      loading: true,
      search: '',
      dataTableHeight: 100,
      headers: [
        {text: 'Alert', value: 'title', class: ['big-text', 'titleBlack', 'headerClass']},
        {text: 'Vessel', value: 'vesselName', class: ['big-text', 'titleBlack', 'headerClass']},
        {text: 'Signal', value: 'signal', class: ['big-text', 'titleBlack', 'headerClass']},
        {text: 'Timestamp', value: 'endDate', class: ['big-text', 'titleBlack', 'headerClass']},
        // { text: 'Tier', value: 'tier', class:['big-text', 'titleBlack', 'headerClass']},
        // { text: 'Severity', value: 'severity', class:['big-text', 'titleBlack', 'headerClass']},
        {text: '', value: 'snoozed', class: ['big-text', 'titleBlack', 'headerClass']}],
      alerts: [],
      allAlerts: [],
      selected: [],
      tierModel: null,
      sevModel: null,
      application: {
        model: '',
        options: [
          {
            name: 'None',
            values: ''
          }
        ]
      },
      snoozePopup: false,
      snoozeDate: null,
      dateMenu: false,
      startDate: null,
      endDate: null
    }
  },
  computed: {
    ...mapGetters(['getSnoozeAlertData', 'getVessels', 'getInfoDict', 'getAdminData', 'getAdminApiUrl']),
    dateText: function () {
      if ((this.startDate == null) || (this.endDate == null)) return null;
      else {
        return 'From ' + this.startDate + ' to ' + this.endDate;
      }
    },
    maxDate: function () {
      return new Date().toISOString().substring(0, 10);
    },
    minDate: function () {
      var d = new Date();
      d.setDate(d.getDate() - 100);

      return d.toISOString().substring(0, 10);
    }
  },
  methods: {
    ...mapActions(['fetchSnoozeAlertData', 'fetchSignalId', 'fetchIssueId']),
    rowClick(rowItem) {
      // let paramItem={
      //   'vesselName':rowItem.vesselName
      // };

      // if (rowItem.issueId!='')
      // {
      //   this.fetchIssueId(rowItem.issueId);

      //   this.$router.push({ name: 'vesselissues', params: paramItem });          
      // }
      // else 
      // {
      //   if (rowItem.signalId!='') this.fetchSignalId(rowItem.signalId);

      //   this.$router.push({ name: 'vesseldetails', params: paramItem });
      // }
    },
    datesChange() {
      let dates =
          {
            startDate: this.startDate,
            endDate: this.endDate
          };

      this.fetchSnoozeAlertData(dates);

      this.dateMenu = false;
    },
    calcRange(startDate, endDate) {
      let selArray = [];

      let currentDate = new Date(startDate);

      while (currentDate < new Date(endDate)) {
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        selArray.push(new Date(currentDate).toISOString().substring(0, 10));
      }

      selArray.pop();
      return selArray;
    },
    updateDataTableHeight() {
      this.dataTableHeight = this.$refs['parentDiv'].clientHeight;
    },
    differenceToText(diff) {
      let day = 24 * 60 * 60;
      let hour = 60 * 60;
      let min = 60;

      if (diff > day) return (diff / day) + " d";
      else if (diff > hour) return (diff / hour) + " h";
      else if (diff > min) return (diff / min) + " min";
      else return diff + " s";

    },
    async snooze() {
      let toBeSnoozed = [];
      //warning if date not selected

      toBeSnoozed = this.selected.map(item => {
        return {
          'vesselId': item.vesselId,
          'signalId': item.signalId,
          'methodRunningId': item.methodRunningId,
          'alertRuleId': item.alertRuleId,
          'until': this.snoozeDate
        }
      })
      const response = await axios.post(this.getAdminApiUrl + '/rulesets/dismissals/create/batch', toBeSnoozed);

      if (response.data.success) {
        //refresh data table
        this.selected = [];
        this.snoozePopup = false;
      }

    },
    filter() {
      this.alerts = this.allAlerts;

      if (this.sevModel != null) this.alerts = this.alerts.filter(item => item.severity == this.sevModel);
      if (this.tierModel != null) this.alerts = this.alerts.filter(item => item.tier == this.tierModel.match(/(\d+)/)[0]);

      if (this.application.model.length && this.application.model != 'None') {
        let matched_sigs = this.getAdminData.signals.filter(item => {return item[this.application.model] == 1}).map(item => {return item.name});

        this.alerts = this.alerts.filter(item => {
          return matched_sigs.indexOf(item.signal.trim()) > -1;
        })
      }
    }

  },
  mounted() {
  },
  watch: {
    getAdminData: {
      handler: function () {

        let dt = new Date();

        this.endDate = dt.toISOString().substring(0, 10);

        dt.setDate(dt.getDate() - 1);

        this.startDate = dt.toISOString().substring(0, 10);

        let dates =
            {
              startDate: this.startDate,
              endDate: this.endDate,
            };

        this.fetchSnoozeAlertData(dates);

        this.application.options = [{name: 'None', value: ''}, ...this.getAdminData.consumers.map(item => {
          return {name: item.name, value: item.look_up}
        })]
      },
      deep: true,
      immediate: true
    },
    getSnoozeAlertData: {
      handler: function () {

        this.alerts = [];

        Array.prototype.concat.apply([], Object.values(this.getSnoozeAlertData)).forEach(alert => {

          if (alert.type.includes('lvl')) alert.title = alert.target.charAt(0).toUpperCase() + alert.target.slice(1) + ' under ' + alert.level + ' %';
          else if (alert.type.includes('diff')) alert.title = 'Last update over ' + this.differenceToText(alert.difference) + ' ago';
          else if (alert.type.includes('hourly')) alert.title = 'Less data samples than ' + alert.level + '/hour';
          else alert.title = alert.issueTitle;

          if ((alert.signalId != '') && (alert.signalId != undefined))
            alert.signal = this.getAdminData.signals.find(item => item.id == alert.signalId).name;
          else alert.signal = '';

          alert.id = alert.imo + alert.alertId;

          if (this.alerts.find(item => item.id == (alert.imo + alert.alertId)) == undefined)
            this.alerts.push(alert);
        });

        this.allAlerts = this.alerts;

        this.loading = false;

      },
      deep: true,
      immediate: true
    },
    application: {
      handler: function () {
        if (this.application.model == 'None') {
          this.application.model = '';
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

table.v-table tbody td {
  font-size: 18px !important;
}

.statusCircle {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
}
</style>
