import { render, staticRenderFns } from "./AdminAlerts.vue?vue&type=template&id=642250cb&scoped=true"
import script from "./AdminAlerts.vue?vue&type=script&lang=js"
export * from "./AdminAlerts.vue?vue&type=script&lang=js"
import style0 from "./AdminAlerts.vue?vue&type=style&index=0&id=642250cb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642250cb",
  null
  
)

export default component.exports