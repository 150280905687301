import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {msalService} from "@/useAuth";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        groups: [],
        vessels: [],
        fleetOverview: {},
        groupOverview: {},
        vesselOverview: {},
        index: 0,
        selPeriod: null,
        selGroup: null,
        selIMO: null,
        lastUpdate: null,
        signalData: [],
        //overviewData:[],
        issuesData: [],
        issueCategories: [],
        issueCategoriesPeriods: [],
        // issueDetails:{},
        expandRowData: {},
        signalMapping: {},
        apiUrl: '',
        adminApiUrl: '',
        wsUrl: '',
        infoDict: {},
        adminData: {},
        adminVesselSignals: {},
        filterParameters: {
            'status': 'All',
            'completeness': [],
            'quality': [],
            'tier': '',
            'severity': '',
            'location': '',
            'active': false,
            'missing': false,
            'sensor': ''
        },
        IssueMethod: null,
        IssueId: null,
        signalId: null,
        alertData: {},
        snoozeAlertData: {},
        vessel_error: '',
        authorised: false,
        msalInstance: null,
        azure_app_id: '',
        azure_redirect_uri: '',
        azure_id_token: '',
        api_token: '',
        api_headers: '',
    },
    mutations: {
        seAPIToken: (state, api_token) => (state.api_token = api_token),
        seAPIHeaders: (state, api_headers) => (state.api_headers = api_headers),
        setAzureAppId: (state, azure_app_id) => (state.azure_app_id = azure_app_id),
        setAzureRedUri: (state, azure_redirect_uri) => (state.azure_redirect_uri = azure_redirect_uri),
        setAzureIdToken: (state, azure_id_token) => (state.azure_id_token = azure_id_token),
        setAuthorised: (state, authorised) => (state.authorised = authorised),
        setMSALInstance: (state, instance) => (state.msalInstance = instance),
        setGroups: (state, groups) => (state.groups = groups),
        setVessels: (state, vessels) => (state.vessels = vessels),
        setFleetOverview: (state, fleetOverview) => (state.fleetOverview = fleetOverview),
        setGroupOverview: (state, groupOverview) => (state.groupOverview = groupOverview),
        setVesselOverview: (state, vesselOverview) => (state.vesselOverview = vesselOverview),
        setIndex: (state, index) => (state.index = index),
        setAlertData: (state, alertData) => (state.alertData = alertData),
        setSnoozeAlertData: (state, snoozeAlertData) => (state.snoozeAlertData = snoozeAlertData),
        setIssueId: (state, IssueId) => (state.IssueId = IssueId),
        setSignalId: (state, signalId) => (state.signalId = signalId),
        setApiUrl: (state, apiUrl) => (state.apiUrl = apiUrl),
        setAdminApiUrl: (state, adminApiUrl) => (state.adminApiUrl = adminApiUrl),
        setWSUrl: (state, wsUrl) => (state.wsUrl = wsUrl),
        setInfoDict: (state, infoDict) => (state.infoDict = infoDict),
        setAdminData: (state, adminData) => (state.adminData = adminData),
        setAdminVesselSignals: (state, adminVesselSignals) => (state.adminVesselSignals = adminVesselSignals),
        setSelPeriod: (state, selPeriod) => (state.selPeriod = selPeriod),
        setSelGroup: (state, selGroup) => (state.selGroup = selGroup),
        setSelIMO: (state, selIMO) => (state.selIMO = selIMO),
        setLastUpdate: (state, lastUpdate) => (state.lastUpdate = lastUpdate),
        setSignalData: (state, signalData) => (state.signalData = signalData),
        // setOverviewData:(state, overviewData) => (state.overviewData = overviewData),
        setIssuesData: (state, issuesData) => (state.issuesData = issuesData),
        setIssueMethod: (state, issueMethod) => (state.IssueMethod = issueMethod),
        setIssueCategories: (state, issueCategories) => (state.issueCategories = issueCategories),
        setIssueCategoriesPeriods: (state, issueCategoriesPeriods) => (state.issueCategoriesPeriods = issueCategoriesPeriods),
        //setIssueDetails:(state, issueDetails) => (state.issueDetails = issueDetails),
        setFilterParameters: (state, filterParameters) => (state.filterParameters = filterParameters),
        setExpandRowData: (state, expandRowData) => (state.expandRowData = expandRowData),
        setSignalMapping: (state, signalMapping) => (state.signalMapping = signalMapping),
        setVesselError: (state, error) => (state.vessel_error = error)
    },
    getters: {
        getAPIToken: state => state.api_token,
        getAzureAppId: state => state.azure_app_id,
        getAzureRedUri: state => state.azure_redirect_uri,
        getAzureIdToken: state => state.azure_id_token,
        getMSALInstance: state => state.msalInstance,
        getAuthorised: state => state.authorised,
        getGroups: state => state.groups,
        getVessels: state => state.vessels,
        getFleetOverview: state => state.fleetOverview,
        getGroupOverview: state => state.groupOverview,
        getVesselOverview: state => state.vesselOverview,
        getIndex: state => state.index,
        getAlertData: state => state.alertData,
        getSnoozeAlertData: state => state.snoozeAlertData,
        getIssueId: state => state.IssueId,
        getSignalId: state => state.signalId,
        getApiUrl: state => state.apiUrl,
        getAdminApiUrl: state => state.adminApiUrl,
        getWSUrl: state => state.wsUrl,
        getInfoDict: state => state.infoDict,
        getAdminData: state => state.adminData,
        getAdminVesselSignals: state => state.adminVesselSignals,
        getSelPeriod: state => state.selPeriod,
        getSelGroup: state => state.selGroup,
        getSelIMO: state => state.selIMO,
        getLastUpdate: state => state.lastUpdate,
        getSignalData: state => state.signalData,
        // getOverviewData : state => state.overviewData,
        getIssuesData: state => state.issuesData,
        getIssueCategories: state => state.issueCategories,
        getIssueCategoriesPeriods: state => state.issueCategoriesPeriods,
        // getIssueDetails : state => state.issueDetails,
        getFilterParameters: state => state.filterParameters,
        getExpandRowData: state => state.expandRowData,
        getSignalMapping: state => state.signalMapping,
        getVesselError: state => state.vessel_error
    },
    actions: {
        async setAPIToken({commit, state}, token) {
            commit('seAPIToken', token)
        },
        async setAzureAppId({commit, state}, appId) {
            commit('setAzureAppId', appId)
        },
        async setAzureRedUri({commit, state}, redirectURI) {
            commit('setAzureRedUri', redirectURI)
        },
        async setAzureIdToken({commit, state}, token) {
            commit('setAzureIdToken', token)
        },
        async setAuthorised({commit, state}, authorised) {
            commit('setAuthorised', authorised);
        },
        async setMSALInstance({commit, state}, msalInstance) {
            commit('setMSALInstance', msalInstance);
        },
        async setIssueMethod({commit, state}, id) {
            commit('setIssueMethod', id);
        },
        async getActiveAccount({commit, state}) {
            if (state.msalInstance) {
                let activeAccount = state.msalInstance.getActiveAccount();

                if (activeAccount != null) {
                    commit('setAuthorised', true);
                } else {
                    commit('setAuthorised', false);
                }

                return activeAccount
            }

            return false
        },
        async getToken({commit, state}) {
            let token = state.msalInstance.getToken();

            return token
        },
        async fetchVesselsData({commit, state}) {
            if (state.authorised) {
                const response = await axios.get(state.apiUrl + '/account/nav')

                if (response.data.success) {
                    commit('setGroups', response.data.data.groups);
                    commit('setVessels', response.data.data.vessels);
                    commit('setAlertData', response.data.data.alerts);
                } else {
                    commit('setGroups', []);
                    commit('setVessels', []);
                    commit('setAlertData', []);
                }
            }
        },
        async fetchAlertData({commit, state}, alerts) {
            commit('setAlertData', alerts);
        },
        async fetchSnoozeAlertData({commit, state}, data) {
            if (state.authorised) {

                let startDate = data.startDate;
                let endDate = data.endDate;

                const response = await axios.get(state.apiUrl + '/alerts/' + startDate + '/' + endDate);

                if (response.data.success) {
                    commit('setSnoozeAlertData', response.data.data.alerts);
                } else {
                    commit('setSnoozeAlertData', {});
                }
            }
        },
        async fetchFleetOverview({commit, state}) {
            if (state.authorised) {
                const response = await axios.get(state.apiUrl + '/fleet');
                if (response.data.success) {
                    commit('setFleetOverview', response.data.data);
                } else {
                    commit('setFleetOverview', {});
                }
            }
        },
        async fetchGroupOverview({commit, state}, group) {
            if (state.authorised) {
                const response = await axios.get(state.apiUrl + '/groups/' + group.slice(-1));
                if (response.data.success) {
                    commit('setGroupOverview', response.data.data);
                } else {
                    commit('setGroupOverview', {});
                }
            }
        },
        async fetchVesselOverview({commit, state}, data) {
            if (state.authorised) {
                let imo = data.imo;
                let period = data.period;

                const response = await axios.get(state.apiUrl + '/vessels/' + imo + '/' + period);

                if (response.data.success) {
                    commit('setVesselOverview', response.data.data);
                    commit('setLastUpdate', response.data.data.date);

                    if (Object.keys(response.data.data).length < 1) {
                        let vessel = state.vessels.filter(item => {
                            return item.imo == data.imo
                        })

                        if (vessel.length) {
                            vessel = vessel[0].vessel;

                            commit('setVesselError', `No data were found for ${vessel}.`);
                        } else {
                            commit('setVesselError', `No data were found.`);
                        }

                    } else {
                        commit('setVesselError', ``);
                    }
                } else {
                    commit('setVesselOverview', {});
                    commit('setLastUpdate', null);
                }
            }
        },
        async fetchSignalData({commit, state}, imo) {
            if (state.authorised) {
                commit('setSignalData', []);

                const response = await axios.get(state.apiUrl + '/vessels/' + imo + '/signals');

                if (response.data.success) {
                    commit('setSignalData', response.data.data);
                } else {
                    commit('setSignalData', []);
                }
            }
        },
        async fetchIssuesCategories({commit, state}, data) {
            if (state.authorised) {
                commit('setIssueCategoriesPeriods', []);
                commit('setIssueCategories', []);

                const response = await axios.get(`${state.apiUrl}/issues/${state.selIMO}`);

                if (response.data.success) {
                    commit('setIssueCategories', response.data.data.issues);
                } else {
                    commit('setIssueCategories', []);
                }
            }
        },
        async fetchIssueCategoriesPeriods({commit, state}) {
            if (state.authorised) {
                commit('setIssueCategoriesPeriods', []);

                const response = await axios.get(`${state.apiUrl}/issues/${state.selIMO}/method/${state.IssueMethod}`);

                if (response.data.success) {
                    commit('setIssueCategoriesPeriods', response.data.data.issues);
                } else {
                    commit('setIssueCategoriesPeriods', []);
                }
            }
        },
        async fetchIssuesData({commit, state}, data) {
            if (state.authorised) {
                commit('setIssuesData', []);

                const response = await axios.get(state.apiUrl + '/issues/' + state.selIMO);

                if (response.data.success) {
                    commit('setIssuesData', response.data.data.issues);
                } else {
                    commit('setIssuesData', []);
                }
            }
        },
        async fetchExpandRowData({commit, state}, data) {
            if (state.authorised) {
                let imo = data.imo,
                    tag = data.tag,
                    startDate = data.startDate,
                    endDate = data.endDate;

                const response = await axios.get(state.apiUrl + '/vessels/' + imo + '/tag/' + tag + '/' + startDate + '/' + endDate);

                if (response.data.success) {

                    commit('setExpandRowData', response.data.data.data);
                } else {
                    commit('setExpandRowData', {});
                }
            }
        },
        async fetchSignalMapping({commit, state}) {
            if (state.authorised) {
                const response = await axios.get(state.apiUrl + '/signals');

                if (response.data.success) {
                    commit('setSignalMapping', response.data.data);
                } else {
                    commit('setSignalMapping', {});
                }
            }
        },
        async fetchFilterParameters({commit, state}, filterData) {

            commit('setFilterParameters', filterData);
        },
        async fetchAdminData({commit, state}) {
            if (state.authorised) {
                const response = await axios.get(state.adminApiUrl + '/accounts')

                if (response.data.success) {
                    commit('setAdminData', response.data.data);
                } else {
                    commit('setAdminData', {});
                }
            }
        },
        async fetchAdminVesselSignals({commit, state}, imo) {
            if (state.authorised) {
                const response = await axios.get(state.adminApiUrl + '/vessels/' + imo + '/signals')

                if (response.data.success) {
                    commit('setAdminVesselSignals', response.data.data);
                } else {
                    commit('setAdminVesselSignals', {});
                }
            }
        },
        async fetchIssueId({commit, state}, id) {

            commit('setIssueId', id);
        },
        async fetchSignalId({commit, state}, id) {

            commit('setSignalId', id);
        },
        async fetchSelIMO({commit, state}, imo) {

            commit('setSelIMO', imo);
        },
        async fetchLastUpdate({commit, state}, lastUpdate) {
            commit('setLastUpdate', lastUpdate);
        },
        async fetchSelGroup({commit, state}, group) {

            commit('setSelGroup', group);
        },
        async fetchSelPeriod({commit, state}, period) {

            commit('setSelPeriod', period);
        },
        async fetchApiUrl({commit, state}, url) {
            commit('setApiUrl', url);
        },
        async fetchAdminApiUrl({commit, state}, url) {
            commit('setAdminApiUrl', url);
        },
        async fetchWSUrl({commit, state}, url) {
            commit('setWSUrl', url);
        },
        async fetchInfoDict({commit, state}, dict) {
            commit('setInfoDict', dict);
        },
        async setAzureJWTCookie({commit, state}, cookie) {
            let date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            date.setTime(date.getTime() + (24 * 60 * 60 * 1000));

            document.cookie = `azure_jwt=${cookie}; expires=${date.toUTCString()}`;
        },
        async authOnAPI({commit, state}, token) {
            let response = await axios.post(state.apiUrl + '/account/sso', {token});

            token = response.data.data.token;

            let date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            date.setTime(date.getTime() + (24 * 60 * 60 * 1000));

            document.cookie = `api_jwt=${token}; expires=${date.toUTCString()}`;
        },
        async setAuthHeaders({commit, state}, token) {
            let headers = {"Authorization": `Bearer ${token}`};
            commit('seAPIHeaders', headers)

            axios.interceptors.request.use(
                config => {
                    config.headers = headers;
                    return config;
                },
                error => {
                    //Do something with request error
                    return Promise.reject(error);
                }
            );

            axios.interceptors.response.use(function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            }, async function (error) {
                await logout(commit, state)
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                return Promise.reject(error);
            })
        },
        async logout({commit, state}) {
            await logout(commit, state)
        }
    }
})

const logout = async (commit, state) => {
    try {
        await state.msalInstance.logout();
    } catch (err) {
    }

    commit('setAuthorised', false);

    let date = new Date('1970-01-01 00:00:00');

    document.cookie = `azure_jwt=; expires=${date.toUTCString()}`;
    document.cookie = `api_jwt=; expires=${date.toUTCString()}`;
    if (document.location.toString().indexOf('/login') < 0) {
        // this.$router.push({name: 'login'});
        document.location = '/login'
    }

}