<template>
  <v-navigation-drawer id="navDrawer" app permanent width="200">
    <div class="main-title" id="logo">
      ADQM
    </div>
    <v-expansion-panels multiple readonly accordion flat v-model="panel">
      <v-expansion-panel v-for="(item, panIndex) in accordionItems" :key="item" class="expPanel"
                         :class="{ expPanelActive: activeIndex == panIndex}">
        <v-expansion-panel-header hide-actions class="sub-title expHeader" @click="navigate(panIndex, null, item)">
          {{ item.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="'subItems' in item">
          <v-list dense>
            <v-list-item-group v-model="item.model">
              <v-list-item v-for="(subItem, index) in item.subItems" :key="subItem" @click="navigate(panIndex, index, item)" >
                <v-list-item-content
                    v-text="subItem"
                    :class="menuItemSelected(item, index)"
                    :style="menuItemStyle(item, subItem)"
                />
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-navigation-drawer>
</template>


<script>

import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'SideBar',
  components: {},
  data: function () {
    return {
      panel: [0, 1, 2],
      activeIndex: null,
      accordionItems:
          [
            {
              name: 'Fleet',
              view: 'fleet'
            },
            {
              name: 'Groups',
              subItems: [],
              model: null,
              view: 'group',
              paramName: 'groupName'
            },
            {
              name: 'Vessels',
              subItems: [],
              model: null,
              view: 'vessel',
              paramName: 'vesselName'
            },
            // {
            //   name: 'Admin Panel',
            //   view: 'adminVessels'
            // }
          ]
    }
  },

  computed: {
    ...mapGetters(['getGroups', 'getVessels', 'getApiUrl', 'getSelGroup', 'getFleetOverview'])
  },
  methods: {
    ...mapActions(['fetchVesselsData', 'fetchSignalMapping', 'fetchSelIMO', 'fetchSelGroup', 'fetchLastUpdate']),
    navigate(panIndex, index, item) {
      if ('model' in item) item.model = index;

      this.activeIndex = panIndex;
      let selPanel = this.accordionItems[this.activeIndex];

      if ('subItems' in selPanel) {
        if (index == null) index = 0;

        let paramItem = {};

        paramItem[selPanel['paramName']] = selPanel.subItems[index];

        if (panIndex == 1) {
          this.accordionItems[2].subItems = this.getVessels.filter(item => {
            return item.is_active.data[0] && item.group == selPanel.subItems[index];
          }).map(item => {
            return item.vessel;
          }).sort();

          this.accordionItems[2].model = null;
        }
        this.$router.push({name: selPanel.view, params: paramItem});

      } else {
        this.accordionItems[1].model = null;
        this.accordionItems[2].model = null;

        this.accordionItems[2].subItems = this.getVessels
            .filter((item) => {
              return item.is_active.data[0]
            })
            .map(item => {
              return item.vessel;
            }).sort();

        this.$router.push({name: selPanel.view});
      }

    },
    menuItemSelected(list, index){
      return (list.model == index ? "big-text listItemSel" : "big-text listItem")
    },
    menuItemStyle(list, item) {
      if (list.name === 'Vessels') {
        return {
          fontSize: '14px !important',
        }
      } else {
        return {}
      }
    }
  },
  watch: {
    getApiUrl: {
      handler: function () {
        if (this.getApiUrl == '') return;

        this.fetchVesselsData();
        this.fetchSignalMapping();
      },
      deep: true,
      immediate: true
    },
    getGroups: {
      handler: function () {

        this.accordionItems[1].subItems = this.getGroups;

        let params = this.$route.params;
        if ('groupName' in params) {
          this.fetchSelGroup(params.groupName);

          this.activeIndex = 1;
          this.accordionItems[1].model = this.accordionItems[1].subItems.indexOf(params.groupName);
        }
      },
      deep: true,
      immediate: true
    },
    getVessels: {
      handler: function () {

        if (this.getSelGroup != null) {
          this.accordionItems[2].subItems = this.getVessels
              .filter(item => {
                return item.is_active.data[0] && item.group == this.getSelGroup;
              }).map(item => {
                return item.vessel;
              }).sort();
        } else {
          this.accordionItems[2].subItems = this.getVessels
              .filter((item) => {
                return item.is_active.data[0]
              }).map(item => {
                return item.vessel;
              }).sort();
        }

        let params = this.$route.params;
        if ('vesselName' in params) {
          let vessel = this.getVessels.find(item => item.vessel == params.vesselName);

          if (vessel != 'undefined' && vessel != null) {
            let imo = vessel.imo;

            this.fetchSelIMO(imo);

            this.activeIndex = 2;
            this.accordionItems[2].model = this.accordionItems[2].subItems.indexOf(params.vesselName);
          }
        }
      },
      deep: true,
      immediate: true
    },
    '$route.params': {
      handler: function () {
        let params = this.$route.params;

        if (('groupName' in params) && (this.accordionItems[1].subItems[this.accordionItems[1].model] != params.groupName)) {
          this.activeIndex = 1;
          this.accordionItems[1].model = this.accordionItems[1].subItems.indexOf(params.groupName);
        } else if (('vesselName' in params) && (this.accordionItems[2].subItems[this.accordionItems[2].model] != params.vesselName)) {
          this.activeIndex = 2;
          this.accordionItems[2].model = this.accordionItems[2].subItems.indexOf(params.vesselName);
        }

        // document.getElementsByClassName('listItemSel').forEach(el=>el.scrollIntoView());

      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style scoped>
#navDrawer {
  background-color: #16222d;
  color: #809dbe;
}

#logo {
  min-height: 70px;
  padding: 10px;

}

.v-expansion-panel-content {
  max-height: 400px;
}

.expPanel {
  background-color: inherit !important;
  color: inherit !important;
}

.expPanelActive {
  background-color: #1d2d3d !important;
  color: white !important;
}

.expHeader {
  padding: 10px !important;
}

.v-list {
  height: 100%;
  overflow-y: auto;
}

.v-list-item {
  padding: 0px;
}

.v-list-item--dense .v-list-item__content, .v-list--dense .v-list-item .v-list-item__content {
  padding: 8px;
}

.listItem {
  color: #809dbe;
}

.listItemSel {
  background-color: #1d2d3d;
  color: white;
}

</style>

<style>


</style>
