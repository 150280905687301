<template>
  <v-toolbar :height="70" id="toolBar">
    <v-btn-toggle v-model="activeTab" tile group>
      <v-btn v-for="tab in tabList" :key="tab" :value="tab.view" :height="70"
             :style="btnStyle(tab.view)">
        {{ tab.name }}
      </v-btn>
    </v-btn-toggle>
    <v-spacer/>
    <div style="height:100%;display: flex;align-items: center;">
      <v-btn text @click="searchDialog = true">Search Tag
        <v-icon right>mdi-magnify</v-icon>
      </v-btn>
      <v-icon medium @click="repDialog = true">mdi-file-chart</v-icon>
      <v-menu bottom :close-on-content-click="false" v-model="alertMenu">
        <template v-slot:activator="{ on }">
          <v-badge
              :content="alertNum"
              :value="alertNum"
              color="#FF4D4F"
              overlap
              id="alertBadge"
          >
            <v-icon medium @click="alertMenu=true">notifications_none</v-icon>
          </v-badge>
        </template>
        <v-expansion-panels flat style="overflow-y:auto;max-height:600px;max-width:350px;background-color:white;">
          <v-expansion-panel v-for="(value, name) in alertDict" :key="name">
            <v-expansion-panel-header>{{ name }} ({{ value.length }})</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list three-line style="overflow-y:auto;max-width:350px;">
                <v-list-item-group>
                  <v-list-item v-for="(item, index) in value" :key="index">
                    <v-list-item-content @click="selectAlert(item)">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle v-if="item.signal!=''">{{ item.signal }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ item.vesselName }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- <v-list v-if="alerts.length>0" three-line style="overflow-y:auto;max-height:600px;max-width:350px;">
          <v-list-item-group v-model="selectedAlert">
            <v-list-item v-for="(item, index) in alerts" :key="index" >
              <v-list-item-content @click="alertMenu=false">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.signal!=''">{{item.signal}}</v-list-item-subtitle>              
                <v-list-item-subtitle>{{item.vesselName}}</v-list-item-subtitle>
              </v-list-item-content> 
            </v-list-item>
          </v-list-item-group>
        </v-list> -->
        <!-- <div v-else style="background-color:#DBE7F0;padding:15px;font-size:16px;font-weight:500;">No alerts found</div> -->
      </v-menu>

      <v-menu open-on-hover v-model="dropdown_model">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" style="margin: 0; padding: 0; min-width: 2rem;">
            <v-icon medium>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div style="height:100%;display:flex;flex-direction:column;text-align: left;" v-show="selVessel!=''">
      <div class="titleColumn" style="height:40px;text-align: left;">{{ selVessel }}</div>
      <div v-if="lastUpdate.length" class="big-text titleBlack" style="height:30px;padding: 0px 12px;">Last Update:
        {{ lastUpdate }} UTC
      </div>
    </div>
    <v-dialog v-model="repDialog" max-width="800" persistent>
      <v-card :style="`height:${calculatedBodyHeight};`">
        <v-card-title class="titleColumn" style="height: 40px; max-height: 40px;">Export Report</v-card-title>
        <v-card-text style="height: calc(90% - 40px); margin-top: 1rem;">
          <v-container class="containerView">
            <v-row class="titleBlack big-text rowView">Report Source</v-row>
            <v-row class="rowView">
              <v-radio-group v-model="selReport" style="margin-top:0px;" :column="false">
                <v-radio
                    v-for="rep in reportChoices"
                    :value="rep"
                    :key="rep"
                    style="margin-right:20px;"
                    color='#1890ff'
                >
                  <template v-slot:label>
                    <v-col>
                      <v-autocomplete
                          v-if="'selItems' in rep"
                          :label="rep.label"
                          v-model="rep.selModel"
                          :items="rep.selItems"
                          rounded flat hide-details dense
                          color="#1890ff"
                          style="font-size:16px;max-width:250px;margin:10px 0px;"
                          :disabled="selReport!=rep"
                      />
                      <div v-else>{{ rep.label }}</div>
                    </v-col>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-row>
            <v-row v-if="reportSourceVesselSelected" class="titleBlack big-text rowView">Report Type</v-row>
            <v-row v-if="reportSourceVesselSelected" class="rowView">
              <v-radio-group v-model="selType" style="margin-top:0px;" :column="false">
                <v-radio
                    v-for="rep in reportTypes"
                    :value="rep"
                    :key="rep"
                    style="margin-right:20px;"
                    color='#1890ff'
                >
                  <template v-slot:label>
                    <v-col>
                      <v-autocomplete
                          v-if="'selItems' in rep"
                          :label="rep.label"
                          v-model="rep.selModel" :items="rep.selItems"
                          rounded flat hide-details dense
                          color="#1890ff"
                          style="font-size:16px;max-width:250px;margin:10px 0px;"
                      />
                      <div v-else>{{ rep.label }}</div>
                    </v-col>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-row>
            <v-row class="titleBlack big-text rowView">Time period</v-row>
            <v-row class="rowView" style="min-height:80px;">
              <v-autocomplete
                  :disabled="selReport==null"
                  v-model="reportPeriod" :items="periodChoices" item-text="value" return-object
                  rounded flat hide-details dense
                  color="#1890ff"
                  style="font-size:16px;max-width:220px;margin:10px 0px;"
              />
              <v-menu
                  v-model="startMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="startDate" label="From" append-icon="mdi-calendar" readonly dense
                                v-bind="attrs" v-on="on"
                                style="max-width:180px;margin-right:20px;margin-top:10px;" color='#1890ff'
                                :disabled="reportPeriod.disable||(selReport==null)"/>
                </template>
                <v-date-picker v-model="startDate" @input="startMenu = false" :max="endDate" :events="calcRange()"
                               color='#1890ff' event-color='#1890ff'/>
              </v-menu>
              <v-menu
                  v-model="endMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="endDate" label="To" append-icon="mdi-calendar" readonly dense
                                v-bind="attrs" v-on="on"
                                style="max-width:180px;margin-top:10px;" color='#1890ff'
                                :disabled="reportPeriod.disable||(selReport==null)"/>
                </template>
                <v-date-picker v-model="endDate" @input="endMenu = false" :min="startDate" :max="today"
                               :events="calcRange()" color='#1890ff' event-color='#1890ff'/>
              </v-menu>
            </v-row>
            <v-row v-if="reportTypeVesselSelected" class="titleBlack big-text rowView">Signal Options</v-row>
            <v-row v-if="reportTypeVesselSelected" class="rowView">
              <v-col v-for="sig in signalChoices" :key="sig">
                <v-autocomplete
                    multiple
                    v-model="sig.selModel" :items="sig.selItems" :label="sig.label"
                    rounded flat hide-details dense
                    color="#1890ff"
                    style="font-size:16px;max-width:220px;margin:10px 0px;"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="height: calc(10% - 40px);">
          <v-container class="containerView">
            <v-row>
              <v-spacer/>
              <v-btn text @click="repDialog = false">Cancel</v-btn>
              <v-btn text @click="exportReport()">OK</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="searchDialog" max-width="800">
      <v-card>
        <v-card-title class="titleColumn">
          Search Signal Across Fleet
          <v-spacer/>
          <v-text-field
              v-model="search"
              style="max-width:450px;padding-top:0px;"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              color='#1890ff'
          />
        </v-card-title>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-data-table
                  style="height:100%;margin:5px;width:100%;"
                  :height="300"
                  single-select item-key="tag" @click:row="rowClick"
                  fixed-header disable-pagination hide-default-footer
                  :headers="sigHeaders" :items="sigItems"
                  :search="search"
                  :calculate-widths="true"
              >
              </v-data-table>
            </v-row>
            <v-row class="titleColumn" style="height:45px;margin-top:10px;">
              Vessels with selected signal
            </v-row>
            <v-row>
              <v-data-table
                  style="width:100%;height:100%;"
                  height="500"
                  fixed-header disable-pagination hide-default-footer
                  :headers="selSignalHead"
                  :items="selSignalData"
                  sort-by="name"
                  @click:row="vesselClick"
                  @current-items="current"
              >
                <template v-slot:item.completeness="{ item }">
                  <div>{{ (item.completeness != -100 ? item.completeness.toFixed(1) : '-') }}</div>
                </template>
                <template v-slot:item.quality="{ item }">
                  <div>{{ (item.quality != -100 ? item.quality.toFixed(1) : '-') }}</div>
                </template>
              </v-data-table>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-toolbar>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import axios from 'axios';
import {msalService} from "@/useAuth";

export default {
  name: 'TopBar',
  components: {},
  data: function () {
    return {
      title: 'Overview',
      tabDict: {
        'fleet': [{name: 'Fleet Overview', view: 'fleet'}, {name: 'Fleet Alerts', view: 'fleetalerts'}],
        'fleetalerts': [{name: 'Fleet Overview', view: 'fleet'}, {name: 'Fleet Alerts', view: 'fleetalerts'}],
        'group': [{name: 'Group Overview', view: 'group'}],
        'vessel': [
          {name: 'Vessel Overview', view: 'vessel'},
          {name: 'Signals View', view: 'vesseldetails'},
          {name: 'Issues View', view: 'vesselissues'},
          {name: 'Data View', view: 'vesseldata'}
        ],
        'vesseldetails': [
          {name: 'Vessel Overview', view: 'vessel'},
          {name: 'Signals View', view: 'vesseldetails'},
          {name: 'Issues View', view: 'vesselissues'},
          {name: 'Data View', view: 'vesseldata'}
        ],
        'vesselissues': [{name: 'Vessel Overview', view: 'vessel'}, {
          name: 'Signals View',
          view: 'vesseldetails'
        }, {name: 'Issues View', view: 'vesselissues'}, {name: 'Data View', view: 'vesseldata'}],
        'vesseldata': [{name: 'Vessel Overview', view: 'vessel'}, {
          name: 'Signals View',
          view: 'vesseldetails'
        }, {name: 'Issues View', view: 'vesselissues'}, {name: 'Data View', view: 'vesseldata'}],
        'adminVessels': [{name: 'Vessels', view: 'adminVessels'}, {
          name: 'Signals',
          view: 'adminSignals'
        }, {name: 'Methods', view: 'adminMethods'}, {name: 'Alerts', view: 'adminAlerts'}],
        'adminSignals': [{name: 'Vessels', view: 'adminVessels'}, {
          name: 'Signals',
          view: 'adminSignals'
        }, {name: 'Methods', view: 'adminMethods'}, {name: 'Alerts', view: 'adminAlerts'}],
        'adminMethods': [{name: 'Vessels', view: 'adminVessels'}, {
          name: 'Signals',
          view: 'adminSignals'
        }, {name: 'Methods', view: 'adminMethods'}, {name: 'Alerts', view: 'adminAlerts'}],
        'adminAlerts': [{name: 'Vessels', view: 'adminVessels'}, {
          name: 'Signals',
          view: 'adminSignals'
        }, {name: 'Methods', view: 'adminMethods'}, {name: 'Alerts', view: 'adminAlerts'}],
        'fleetReport': [{name: 'Fleet Report', view: 'fleetReport'}],
        'groupReport': [{name: 'Group Report', view: 'groupReport'}],
        'vesselReport': [{name: 'Vessel Report', view: 'vesselReport'}],
        'timelineReport': [{name: 'Timeline Report', view: 'timelineReport'}],
        'groupReportTimeline': [{name: 'Group Timeline Report', view: 'groupReportTimeline'}],
        'vesselReportTimeline': [{name: 'Vessel Timeline Report', view: 'vesselReportTimeline'}]
      },
      search: '',
      sigHeaders: [
        {text: 'Name', value: 'name', class: "big-text titleBlack"},
        {text: 'Tag', value: 'tag', class: "big-text titleBlack"},
        {text: 'System', value: 'system', class: "big-text titleBlack"},
        {text: 'Location', value: 'location', class: "big-text titleBlack"}
      ],
      sigItems: [],
      selSignal: null,
      selSignalHead: [
        {
          value: 'index',
          text: '#',
          class: "big-text titleBlack"
        },
        {
          text: 'VESSEL',
          value: 'name',
          class: "big-text titleBlack"
        },
        {
          text: 'COMPLETENESS %',
          value: 'completeness',
          class: "big-text titleBlack"
        },
        {
          text: 'QUALITY %',
          value: 'quality',
          class: "big-text titleBlack"
        },
        {
          text: 'LAST UPDATE',
          value: 'dateFormatted',
          class: "big-text titleBlack"
        }
      ],
      selSignalData: [],
      tabList: [],
      activeTab: 'fleet',
      selVessel: '',
      selPeriod: null,
      periods: ['Last Day', 'Last Week', 'Last Month'],
      searchDialog: false,
      repDialog: false,
      reportChoices: [
        {value: 'fleet', label: 'Fleet', tlValue: 'group'},
        {value: 'group', label: 'Group', selItems: [], selModel: null, tlValue: 'group'},
        {value: 'vessel', label: 'Vessel', selItems: [], selModel: null, tlValue: 'vessel'},
      ],
      reportTypes: [
        {value: 'report', label: 'Vessel Status', tlValue: 'group'},
        {value: 'data', label: 'Vessel Data', tlValue: 'group'}
      ],
      signalChoices: [
        {value: 'severity', label: 'Severity', selItems: ['High', 'Medium', 'Low'], selModel: []},
        // {value: 'tier', label: 'Tier', selItems: ['Tier 1', 'Tier 2', 'Tier 3'], selModel: []},
        {value: 'systems', label: 'System', selItems: [], selModel: []}
      ],
      periodChoices: [
        {value: 'Last Update', disable: true},
        {value: 'Last Week', disable: false, days: 7},
        {value: 'Last Month', disable: false, days: 30},
        {value: 'Other Period', disable: false}
      ],
      reportPeriod: {value: 'Last Update', disable: true},
      startDate: null,
      endDate: null,
      startMenu: false,
      endMenu: false,
      selReport: null,
      selType: null,
      alertNum: 0,
      alertMenu: false,
      alerts: [],
      alertDict: {},
      dropdown_model: null
    }
  },
  computed: {
    ...mapGetters([
      'getVessels',
      'getGroups',
      'getApiUrl',
      'getLastUpdate',
      'getIssuesData',
      'getSignalMapping',
      'getAlertData',
      'getAdminApiUrl',
      'getSelIMO',
      'getAdminData',
      'getApiUrl',
      'getAuthorised',
      'getAzureAppId',
      'getAzureRedUri',
      'getMSALInstance'
    ]),
    today: function () {
      return new Date().toISOString().substring(0, 10);
    },
    lastUpdate: function () {
      if (this.$route.name.toLowerCase() != 'group' && this.$route.name.toLowerCase() != 'fleet' && this.getLastUpdate != null) {
        let temp = new Date(this.getLastUpdate);

        return temp.toISOString().substring(0, 10) + ' ' + temp.toISOString().substring(11, 16);
      } else {
        return '';
      }

    },
    disabledBtn: function () {
      if (this.selReport == null) return true;

      let tmp = this.reportChoices.find(item => item.value == this.selReport);

      if ('selModel' in tmp) {
        if (tmp.selModel == null) return true;
      }
      return false;
    },
    reportSourceVesselSelected: function () {
      return this.selReport != null && this.selReport.value == 'vessel';
    },
    reportTypeSelected: function () {
      return this.selReport != null;
    },
    reportTypeVesselSelected: function () {
      return this.reportSourceVesselSelected && this.selType != null;
    },
    calculatedBodyHeight: function () {
      if (this.reportSourceVesselSelected) {
        return '35rem';
      } else {
        return '25rem';
      }
    }
  },
  watch: {
    getAlertData: {
      handler: function () {

        if (Object.keys(this.getAdminData).length == 0) return;

        this.alertDict = {};

        Object.keys(this.getAlertData).forEach(imo => {

          if ((this.getVessels.find(item => item.imo == imo) != undefined) && (this.getAlertData[imo].length > 0)) {
            let vesselName = this.getVessels.find(item => item.imo == imo).vessel, vessAlerts = [];

            this.getAlertData[imo].forEach(alert => {
              if (alert.type.includes('lvl')) alert.title = alert.target.charAt(0).toUpperCase() + alert.target.slice(1) + ' under ' + alert.level + ' %';
              else if (alert.type.includes('diff')) alert.title = 'Last update over ' + this.differenceToText(alert.difference) + ' ago';
              else if (alert.type.includes('hourly')) alert.title = 'Less data samples than ' + alert.level + '/hour';
              else alert.title = alert.issueTitle;

              if ((alert.signalId != '') && (alert.signalId != undefined))
                alert.signal = this.getAdminData.signals.find(item => item.id == alert.signalId).name;

              vessAlerts.push(alert);
            });

            this.alertDict[vesselName] = vessAlerts;
          }
        })

        this.alertDict = Object.fromEntries(Object.entries(this.alertDict).sort());

        this.alertNum = Array.prototype.concat.apply([], Object.values(this.getAlertData)).length;
        this.alertNum = this.alertNum > 99 ? '99+' : this.alertNum
      },
      deep: true,
      immediate: true
    },
    '$route.params': {
      handler: function () {

        if (this.selPeriod == null) this.selPeriod = this.periods[0];

        this.tabList = this.tabDict[this.$route.name];

        if (this.tabList) {

          let findActive = this.tabList.find(item => item.view == this.$route.name);

          if (findActive == undefined) this.activeTab = this.tabList[0].view;
          else this.activeTab = findActive.view;

          if ('vesselName' in this.$route.params) this.selVessel = this.$route.params.vesselName;
          else this.selVessel = '';
        } else {
          this.selVessel = ''
        }
      },
      deep: true,
      immediate: true
    },
    activeTab: {
      handler: function () {

        if (this.getAuthorised && (this.activeTab != undefined) && (this.$route.name != this.activeTab)) {
          this.$router.push({name: this.activeTab, params: this.$route.params});
        }
      },
      deep: true,
      immediate: true
    },
    reportPeriod: {
      handler: function () {

        if ('days' in this.reportPeriod) {
          this.endDate = new Date().toISOString().substring(0, 10);

          var dayOffset = 24 * 60 * 60 * 1000;

          var tempDate = new Date();

          tempDate.setTime(new Date().getTime() - dayOffset * this.reportPeriod.days);

          this.startDate = tempDate.toISOString().substring(0, 10);

        } else {
          this.startDate = null;
          this.endDate = null;
        }

      },
      deep: true,
      immediate: true
    },
    selPeriod: {
      handler: function () {
        let periodForStore = this.selPeriod.toLowerCase().replace(" ", "_");
        this.fetchSelPeriod(periodForStore);
      },
      deep: true,
      immediate: true
    },
    getGroups: {
      handler: function () {
        this.reportChoices[1].selItems = this.getGroups;
      },
      deep: true,
      immediate: true
    },
    getVessels: {
      handler: function () {
        this.reportChoices[2].selItems = this.getVessels.filter((item) => item.is_active.data[0]).map(item => item.vessel).sort();
      },
      deep: true,
      immediate: true
    },
    getAdminData: {
      handler: function () {
        if (this.getAdminData.signals) {
          try {
            this.sigItems = this.getAdminData.signals.filter(item => item.tag != null);

            let systems_index = this.signalChoices.findIndex((item) => {
              return item.value == 'systems'
            })

            this.signalChoices[systems_index].selItems = this.getAdminData.systems.map(item => item.name);

            if (Object.keys(this.getAlertData).length == 0) return;

            this.alertDict = {};

            Object.keys(this.getAlertData).forEach(imo => {

              if ((this.getVessels.find(item => item.imo == imo) != undefined) && (this.getAlertData[imo].length > 0)) {
                let vesselName = this.getVessels.find(item => item.imo == imo).vessel, vessAlerts = [];

                this.getAlertData[imo].forEach(alert => {
                  if (alert.type.includes('lvl')) alert.title = alert.target.charAt(0).toUpperCase() + alert.target.slice(1) + ' under ' + alert.level + ' %';
                  else if (alert.type.includes('diff')) alert.title = 'Last update over ' + this.differenceToText(alert.difference) + ' ago';
                  else if (alert.type.includes('hourly')) alert.title = 'Less data samples than ' + alert.level + '/hour';
                  else alert.title = alert.issueTitle;

                  if ((alert.signalId != '') && (alert.signalId != undefined))
                    alert.signal = this.getAdminData.signals.find(item => item.id == alert.signalId).name;

                  vessAlerts.push(alert);
                });

                this.alertDict[vesselName] = vessAlerts;
              }
            })

            this.alertDict = Object.fromEntries(Object.entries(this.alertDict).sort());

            this.alertNum = Array.prototype.concat.apply([], Object.values(this.getAlertData)).length;
            this.alertNum = (this.alertNum > 99 ? '99+' : this.alertNum)
          } catch (e) {
          }
        }
      },
      deep: true,
      immediate: true
    },
    selReport: {
      handler: function () {
        if (this.selReport != null) {
          this.reportChoices.forEach(rep => {
            if (rep != this.selReport) rep.selModel = null;
          })
        }
      },
      deep: true,
      immediate: true
    },
    reportSourceVesselSelected: function () {
      this.selType = null
    }
  },
  methods: {
    ...mapActions([
      'fetchSelIMO',
      'fetchSelPeriod',
      'fetchIssueId',
      'fetchSignalId',
      'fetchAlertData',
      'setAuthorised',
      'logout'
    ]),
    selectAlert: async function (alert) {
      this.alertMenu = false;

      let paramItem = {
        'vesselName': alert.vesselName
      };

      if (alert.issueId != '') {
        this.fetchIssueId(alert.issueId);

        this.$router.push({name: 'vesselissues', params: paramItem});
      } else {
        if (alert.signalId != '') this.fetchSignalId(alert.signalId);

        this.$router.push({name: 'vesseldetails', params: paramItem});
      }

      const response = await axios.post(this.getApiUrl + '/alerts/dismiss/' + alert.imo + '/' + alert.alertId, {});

      if (response.data.success) {
        this.fetchAlertData(response.data.data.alerts);
      }
    },
    differenceToText(diff) {
      let day = 24 * 60 * 60;
      let hour = 60 * 60;
      let min = 60;

      if (diff > day) return (diff / day) + " d";
      else if (diff > hour) return (diff / hour) + " h";
      else if (diff > min) return (diff / min) + " min";
      else return diff + " s";

    },
    calcRange() {
      let selArray = [];

      let currentDate = new Date(this.startDate);

      while (currentDate < new Date(this.endDate)) {
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        selArray.push(new Date(currentDate).toISOString().substring(0, 10));
      }

      selArray.pop();
      return selArray;
    },
    current(e) {

      let tmp = e.map(t => t.name);

      this.selSignalData.forEach(vess => {
        vess.index = tmp.indexOf(vess.name) + 1;
      })

    },
    async rowClick(item, row) {

      document.body.style.cursor = 'progress';
      this.selSignalData = [];

      row.select(true);
      this.selSignal = item;

      const response = await axios.get(this.getApiUrl + '/signals/' + this.selSignal.tag + '/vessels');

      this.selSignalData = response.data.data.results;

      this.selSignalData.forEach(item => {

        let vessMap = this.getAdminData.vessels.find(v => v.imo == item.imo);

        item.name = vessMap.name;

        item.dateFormatted = item.date.substring(0, 16).replace('T', ' ');
      })

      document.body.style.cursor = 'default';
    },
    vesselClick(data) {
      this.fetchSignalId(this.selSignal.id);
      this.$router.push({name: 'vesseldetails', params: {'vesselName': data.name}});

      this.searchDialog = false;
    },
    btnStyle(tab) {
      if (this.$route.name != tab)
        return {
          'color': '#1890ff',
          'text-transform': 'none',
          'font-weight': 500
        };
      else
        return {
          'color': '#1890ff',
          'text-transform': 'none',
          'font-weight': 500,
          'border-bottom': '3px solid #1890ff'
        };
    },
    async exportReport() {
      document.body.style.cursor = 'wait';

      let url, pdfName, leftHeader, rightHeader;

      if ((this.startDate != null) && (this.endDate != null)) {
        rightHeader = this.startDate + ' to ' + this.endDate;

        if ('selModel' in this.selReport) {
          if (this.selReport.selModel != null) {
            url = '/report/' + this.selReport.tlValue + '/timeline/' + this.selReport.selModel + '/' + this.startDate + '/' + this.endDate;
            pdfName = 'ADQMReport_' + this.selReport.selModel + '_' + this.startDate + '_to_' + this.endDate + '.pdf';
            leftHeader = this.selReport.selModel;
          } else {
            return;
          }
        } else {
          url = '/report/' + this.selReport.tlValue + '/timeline/' + this.selReport.value + '/' + this.startDate + '/' + this.endDate;
          pdfName = 'ADQMReport_' + this.selReport.value + '_' + this.startDate + '_to_' + this.endDate + '.pdf';
          leftHeader = this.selReport.value;
        }
      } else {
        url = '/report/' + this.selReport.value;
        let dt = new Date().toISOString().substring(0, 16).replace('T', ' ');
        rightHeader = dt;

        if ('selModel' in this.selReport) {
          if (this.selReport.selModel != null) {
            url += '/' + this.selReport.selModel;
            pdfName = 'ADQMReport_' + this.selReport.selModel + '_' + dt + '.pdf';
            leftHeader = this.selReport.selModel;
          } else {
            return;
          }
        } else {
          pdfName = 'ADQMReport_' + this.selReport.value + '_' + dt + '.pdf';
          leftHeader = this.selReport.value;
        }
      }

      let postData = {
        'path': url,
        'leftHeader': leftHeader,
        'rightHeader': rightHeader
      }

      if (this.selReport.value === 'vessel') {
        postData.report_type = this.selType.value;
        postData.vessel = this.selReport.selModel;

        if (this.selType.value == 'data') {
          pdfName = pdfName.replace('pdf', 'csv');
          postData.filters = {};

          this.signalChoices.forEach(sig => {
            if (sig.selModel.length > 0) {
              postData.filters[sig.value] = sig.selModel;
            }
          })
        }

        if (this.startDate != null && this.endDate != null) {
          postData.filters = {};

          if (this.selType.value == 'data') {
            postData.startDate = this.startDate;
            postData.endDate = this.endDate;
          }

          this.signalChoices.forEach(sig => {
            if (sig.selModel.length > 0) {
              postData.filters[sig.value] = sig.selModel;
            }
          })
        }
      }

      const response = await axios.post(this.getApiUrl + '/report', {...postData}, {responseType: 'blob'});

      var tempUrl = window.URL.createObjectURL(new Blob([response.data]));
      var link = document.createElement('a');
      link.href = tempUrl;
      link.setAttribute('download', pdfName);
      document.body.appendChild(link);
      link.click();

      this.repDialog = false;

      document.body.style.cursor = 'default';
    }
  },
}
</script>

<style>

#toolBar {
  color: #595959;
  position: absolute;
  left: 200px;
  width: calc(100% - 200px);
}

.v-toolbar__content, .v-toolbar__extension {
  padding: 0px 16px;
}

.v-btn-toggle--group > .v-btn.v-btn {
  margin: 0px;
}

#alertBadge .v-badge__badge {
  height: 25px !important;
  width: 25px !important;

  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
}

</style>
