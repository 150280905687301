<template>
   <v-container class="containerView" style="background-color:white;max-width:200mm !important;">    
    <v-row class="rowView">
      <v-col :cols=6 class="colView" >    
        <v-row class="subtleBlue main-title rowView" style="align-items: center;">{{vesselName}}</v-row>
        <v-row class="labelLightBlack sub-title rowView" style="align-items: center;">Last Update: {{reportDate}}</v-row>
      </v-col>
    </v-row>  
    <v-row class="rowView" > 
      <v-col :cols="12" class="colView" >  
        <v-card-title> 
          KPI
        </v-card-title>
        <v-card-actions>
          <v-col :cols="12" class="rowView" >  
            <v-row class="rowView">  
              <highcharts style="height:100%;width:100%;" :options="kpiGauge" ></highcharts> 
            </v-row>  
            <v-row class="rowView">  
              <highcharts  style="height:100%;width:100%;"  :options="kpiHistory" ></highcharts> 
            </v-row> 
          </v-col>
        </v-card-actions>  
      </v-col>
    </v-row>
    <v-row class="rowView" style="page-break-after:always;"> 
      <v-col :cols="12" class="colView">  
        <v-card-title>
          Signal Status
        </v-card-title>
        <v-card-actions v-if="getVesselOverview.statistics">            
          <v-data-table style="width:100%;height:100%;" 
            disable-sort     
            hide-default-footer      
            :headers="signalStatusHead"
            :items="signalStatus">
            <template v-slot:item.active="{ item }">
              <div class="signalStatusHoverable" @click="signalStatusClick('active', item)"  style="cursor: pointer;">
                {{ item.active }}
              </div>
            </template>
            <template v-slot:item.missing="{ item }">
              <div class="signalStatusHoverable" @click="signalStatusClick('missing', item)"  style="cursor: pointer;">
                {{ item.missing }}
              </div>
            </template>
            <template v-slot:item.expected="{ item }">
              <div class="signalStatusHoverable" @click="signalStatusClick('expected', item)"  style="cursor: pointer;">
                {{ item.expected }}
              </div>
            </template>
          </v-data-table>
        </v-card-actions>  
      </v-col>
    </v-row>
    
    <v-row class="rowView" style="page-break-after:always;"> 
      <v-col :cols="12" class="colView">  
        <v-card-title>
          Completeness
        </v-card-title>
        <v-card-actions>
          <v-col :cols="12" class="rowView" style="height:100%;"> 
            <v-row class="rowView" style="height:50%;">  
              <v-col :cols="7" class="colView" > 
                <highcharts style="width:100%;height:100%;" :options="complGauge" ></highcharts>
              </v-col>
              <v-col :cols="5" class="colView" v-if="groupSelect!=null">
                <v-row  v-for="(param, index) in countParameters[groupSelect]" :key=param.tag  class="rowView" style="height:25%;">
                  <v-col :cols=12  class="rowView" style='cursor: pointer;' v-on:click="goToIssues(param, 'completeness')">
                      <v-divider v-if="index>0"/>
                      <v-row  class="rowView sub-title centerAlign subtleBlue" style="height:40%;">{{param.title}}</v-row>
                      <v-row  class="rowView centerAlign darkBlue" style="height:60%;font-size:32px; font-weight:500;">{{issueData['completeness'][param.tag]}}</v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider/>    
            <v-row class="rowView" style="height:50%;"> 
              <v-card-title>Top 5 Sensor Issues</v-card-title>
              <v-card-actions>
                <v-data-table style="width:100%;height:100%;"                
                  hide-default-footer      
                  :headers="complTopIssuesHead"
                  :items="complTopIssues"
                  sort-by='completeness'
                  :sort-desc=false
                  @click:row="issueClick">
                </v-data-table>
              </v-card-actions>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-row class="rowView" style="page-break-after:always;"> 
      <v-col :cols="12" class="colView">
        <v-card-title>
          Quality
        </v-card-title>
        <v-card-actions>
          <v-col :cols="12"  class="rowView" style="height:100%;"> 
            <v-row class="rowView" style="height:50%;">   
              <v-col :cols="7" class="colView" > 
                <highcharts style="width:100%;height:100%;" :options="qualGauge" ></highcharts>
              </v-col>
              <v-col :cols="5" class="colView"  v-if="groupSelect!=null">          
                <v-row  v-for="(param, index) in countParameters[groupSelect]" :key=param.tag  class="rowView" style="height:25%;">
                  <v-col :cols=12  class="rowView" style='cursor: pointer;'  v-on:click="goToIssues(param, 'quality')">
                      <v-divider v-if="index>0"/>
                      <v-row  class="rowView sub-title centerAlign subtleBlue" style="height:40%;">{{param.title}}</v-row>
                      <v-row  class="rowView centerAlign darkBlue" style="height:60%;font-size:32px; font-weight:500;">{{issueData['quality'][param.tag]}}</v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider/>
              
            <v-row class="rowView" style="height:50%;"> 
              <v-card-title>Top 5 Sensor Issues</v-card-title>
              <v-card-actions>
                <v-data-table style="width:100%;height:100%;"                
                  hide-default-footer      
                  :headers="qualTopIssuesHead"
                  :items="qualTopIssues"
                  sort-by='quality'
                  :sort-desc=false
                  @click:row="issueClick">
                </v-data-table>
              </v-card-actions>  
            </v-row>
          </v-col>
        </v-card-actions>  
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';   
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';

highchartsMore(Highcharts);
solidGauge(Highcharts);

export default {
  name: 'VesselReport',
  components: {
  },  
  data: function () {    
    return {     
      vessels: {},
      selPeriod:null,
      periods:['Last Day', 'Last Week', 'Last Month'],
      complGauge:{
        chart: {
          animation: false,
          type: 'solidgauge'
        },          
        credits:{
          enabled:false
        },
        title: {
          text: undefined
        },
        pane: 
        {
          startAngle: -180,
          endAngle: 180,
          background: [{
              outerRadius: '100%',
              innerRadius: '90%',
              // backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
              //     .setOpacity(0.3)
              //     .get(),
              borderWidth: 0
          }, {
              outerRadius: '88%',
              innerRadius: '78%',
              // backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
              //     .setOpacity(0.3)
              //     .get(),
              borderWidth: 0
          }, {
              outerRadius: '76%',
              innerRadius: '66%',
              // backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2])
              //     .setOpacity(0.3)
              //     .get(),
              borderWidth: 0
          }]
        },
        tooltip: {
          enabled:false

          // borderWidth: 0,
          // backgroundColor: 'none',
          // shadow: false,
          // style: {
          //   fontSize: '20px'
          // },
          // valueSuffix: '%',
          // pointFormat: '{series.name}<br><span style="font-size: 2em;font-weight:500; color: {point.color};">{point.y}</span>',
          // positioner: function (labelWidth, labelHeight) {
          //   return {
          //     x: (this.chart.chartWidth - labelWidth) / 2,
          //     y: (this.chart.plotHeight -labelHeight)/ 2
          //   };
          // }
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: []
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
                enabled: true
            },
            linecap: 'round',
            stickyTracking: false,
            rounded: true
          }
        },
        series: []
      },
      qualGauge:{
        chart: {
          animation: false,
          type: 'solidgauge'
        },          
        credits:{
          enabled:false
        },
        title: {
          text: undefined
        },
        pane: 
        {
          startAngle: -180,
          endAngle: 180,
          background: [{
              outerRadius: '100%',
              innerRadius: '90%',
              backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                  .setOpacity(0.3)
                  .get(),
              borderWidth: 0
          }, {
              outerRadius: '88%',
              innerRadius: '78%',
              backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
                  .setOpacity(0.3)
                  .get(),
              borderWidth: 0
          }, {
              outerRadius: '76%',
              innerRadius: '66%',
              backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2])
                  .setOpacity(0.3)
                  .get(),
              borderWidth: 0
          }]
        },
        tooltip: {
          enabled:false
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: []
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: true
            },
            linecap: 'round',
            stickyTracking: false,
            rounded: true
          }
        },
        series: []
      },  
      kpiGauge:{
        chart: {
          height:350,
          animation: false,
          type: 'solidgauge'
        },          
        credits:{
          enabled:false
        },
        title: {
          text: undefined
        },
        pane: 
        {
          startAngle: -180,
          endAngle: 180,
          background: [{
              outerRadius: '100%',
              innerRadius: '85%',
              borderWidth: 0
          }
          // ,{
          //     outerRadius: '88%',
          //     innerRadius: '78%',
          //     backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
          //         .setOpacity(0.3)
          //         .get(),
          //     borderWidth: 0
          // }, {
          //     outerRadius: '76%',
          //     innerRadius: '66%',
          //     backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2])
          //         .setOpacity(0.3)
          //         .get(),
          //     borderWidth: 0
          // }
          ]
        },
        tooltip: {
          enabled:false
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: []
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: true
            },
            linecap: 'round',
            stickyTracking: false,
            rounded: true
          }
        },
        series: []
      }, 
      kpiHistory:{   
        credits:{
          enabled:false
        },
        time:{
          useUTC:true
        },       
        chart: {
          height:300,
          animation: false,
          zoomType: 'xy'
        },
        title: {
          text: undefined
        },
        yAxis:{
          max:100,
          min:0,
          gridLineWidth: 1,
          title: {
            text:undefined
          }
        },
        xAxis:{
          title:
          {
            text:undefined
          },
          type:'datetime',
          labels: {
            format: '{value: %e %b}'
          },
          tickInterval: 24 * 3600 * 1000
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return new Date(this.x).toISOString().slice(0, 10) +
              ' : <b>' + this.y.toFixed(1) + ' %</b>';
          }
        },
        series: [{      
          type:'scatter',  
          data:[],
          marker:{
            enabled:true
          },
          animation: false,                        
          states: {
            hover: {
                enabled: false
            },
            normal:{
            animation:false
            }, 
            inactive:{
            opacity: 1
            }
          }
        }]
      },     

      groupDropDown:['Severity','Tier'],
      groupSelect:null,
      vesselName:'',
      reportDate:null,
     
      countParameters:
      {
        "Tier":[
          {
            tag:'total',
            title:'Total Signal Issues'
          },{
            tag:'tier1',
            title:'Tier 1'
          },{
            tag:'tier2',
            title:'Tier 2'
          },{
            tag:'tier3',
            title:'Tier 3'
          }
        ],
        "Severity":[
          {
            tag:'total',
            title:'Total Signal Issues'
          },{
            tag:'high',
            title:'High Severity'
          },{
            tag:'medium',
            title:'Medium Severity'
          },{
            tag:'low',
            title:'Low Severity'
          }
        ]
      },
      issueData:{}, 
      signalStatusHead: [
        { 
          text: '',
          value: 'location', 
          align:'start', 
        },
        { 
          text: 'ACTIVE',
          value: 'active', 
          align:'center', 
          class:"big-text titleBlack"
        },
        { 
          text: 'MISSING',
          value: 'missing', 
          align:'center', 
          class:"big-text titleBlack"
        },
        { 
          text: 'EXPECTED',
          value: 'expected', 
          align:'center', 
          class:"big-text titleBlack"
        }
      ],
      signalStatus:[],
      complTopIssuesHead: [
        { 
          text: 'SENSOR NAME',
          value: 'sensor', 
          align:'start', 
          class:"big-text titleBlack"
        },
        {
          text:'COMPLETENESS',
          align: 'center',
          value: 'completeness', 
          class:"big-text titleBlack"
        },
        { 
          text: 'DATE',
          value: 'timestamp', 
          align:'end', 
          class:"big-text titleBlack"
        }
      ],
      qualTopIssuesHead: [        
        { 
          text: 'SENSOR NAME',
          value: 'sensor', 
          align:'start', 
          class:"big-text titleBlack"
        },
        {
          text:'QUALITY',
          align: 'center',
          value: 'quality', 
          class:"big-text titleBlack"
        },
        { 
          text: 'DATE',
          value: 'timestamp', 
          align:'end', 
          class:"big-text titleBlack"
        }
      ],
      complTopIssues: [],    
      qualTopIssues:[]
    }
      
  },  
  computed:{
    ...mapGetters(['getVesselOverview', 'getVessels','getSignalMapping', 'getInfoDict', 'getLastUpdate']) 
  },
  mounted(){
  },
  methods:{
    ...mapActions(['fetchVesselOverview','fetchFilterParameters','fetchSelPeriod']),
    getStatusColor(status){
      if (status=='Alert') return '#FFE5E5';
      else if (status=='Warning') return '#FFEDD8';
      else return '#DFF8EA';
    },
    getStatusTextColor(status){                
      if (status=='Alert') return '#FF4D4F';
      else if (status=='Warning') return '#FFAA40';
      else return '#23CE75';                
    },
    setGroupControls()
    { 
      let groupData = this.getVesselOverview[this.groupSelect.toLowerCase()];

      let groupList = this.countParameters[this.groupSelect].slice(1);

      let radius = 100, radWidth = 10, radMargin = 2 ;

      this.complGauge.series=[];
      this.qualGauge.series=[];
      this.kpiGauge.series=[];

      this.kpiHistory.series[0].data=[];

      for(let i=0;i<groupList.length;i++)
      {
        let tag = groupList[i].tag;
        let title =  groupList[i].title.replace(" Severity","");
        
        let dcVal = groupData["completeness"][tag];
        let dcColor= '#FF4D4F';

        if (dcVal>80) dcColor ='#23CE75';
        else if (dcVal>50) dcColor = '#FFAA40';

        this.complGauge.pane.background[i].backgroundColor = Highcharts.color(dcColor).setOpacity(0.3).get();

        this.complGauge.series.push({
          name: title,
          data: [{
            color: dcColor,//Highcharts.getOptions().colors[i],
            radius: radius+'%',
            innerRadius: (radius-radWidth)+'%',
            y: dcVal
          }],
          dataLabels: {              
            y: -45 +i*30,
            borderWidth: 0,
            useHTML:true,
            format: '<div style="text-align:center;"><span style="font-size: 14px; font-weight:500;color: #638399;">'+title+'</span><span style="padding-left:10px;font-size:18px;color:'+dcColor+';">{y}%</span></div>'
          },
          animation:false,
          states: {
            normal:{
              animation:false
            }
          },
        })

        let qualVal = groupData["quality"][tag];
        
        let qualColor= '#FF4D4F';

        if (qualVal>80) qualColor ='#23CE75';
        else if (qualVal>50) qualColor = '#FFAA40';

        this.qualGauge.pane.background[i].backgroundColor = Highcharts.color(qualColor).setOpacity(0.3).get();

        this.qualGauge.series.push({
          name: title,
          data: [{
            color: qualColor,//Highcharts.getOptions().colors[i],
            radius: radius+'%',
            innerRadius: (radius-radWidth)+'%',
            y: qualVal
          }],
          dataLabels: {              
            y: -45 +i*30,
            borderWidth: 0,
            useHTML:true,
            format: '<div style="text-align:center;"><span style="font-size: 14px; font-weight:500;color: #638399;">'+title+'</span><span style="padding-left:10px;font-size: 18px;color:'+qualColor+';">{y}%</span></div>'
          },
          animation:false,
          states: {
            normal:{
              animation:false
            }
          },
        })        

        radius = radius - (radWidth+radMargin);
      }
      
      let kpiVal = groupData['weighted_kpi'];
      let kpiColor= '#FF4D4F';

      if (kpiVal>80) kpiColor ='#23CE75';
      else if (kpiVal>50) kpiColor = '#FFAA40';

      this.kpiGauge.pane.background[0].backgroundColor = Highcharts.color(kpiColor).setOpacity(0.3).get();

      this.kpiGauge.series.push({
        name: 'KPI',
        data: [{
          color: kpiColor,//Highcharts.getOptions().colors[i],
          radius: '100%',
          innerRadius: '85%',
          y:  Math.round(kpiVal * 10) / 10 
        }],
        dataLabels: {             
          y: -40,
          borderWidth: 0,
          useHTML:true,
          format: '<div style="text-align:center;"><span style="font-size: 60px;color:'+kpiColor+';">{y}%</span></div>'
        },        
        animation:false,
        states: {
          normal:{
            animation:false
          }
        },
      })
      
      this.getVesselOverview.kpiHistory[this.groupSelect.toLowerCase()].forEach(item=>{
        var date = new Date(item[0]);

        let val = item[1];
        let clr =  '#FF4D4F';

        if (val>80) clr ='#23CE75';
        else if (val>50) clr = '#FFAA40';

        this.kpiHistory.series[0].data.push(
        {
          x:date.getTime(), 
          y:val,
          color:clr});
      })

      // for (let i=0;i<2;i++)
      // {
      //   var date = new Date();
      //   date.setMinutes(date.getMinutes() - 1440*i); 

      //   let val = Math.round(Math.random()*50 + 50);
      //   let clr =  '#FF4D4F';

      //   if (val>80) clr ='#23CE75';
      //   else if (val>50) clr = '#FFAA40';

      //   this.kpiHistory.series[0].data.push(
      //   {
      //     x:date.getTime(), 
      //     y:val,
      //     color:clr});
      // }      

      if (this.kpiHistory.series[0].data.length>10) this.kpiHistory.xAxis.tickInterval= 7*24 * 3600 * 1000;
      else if (this.kpiHistory.series[0].data.length>5)  this.kpiHistory.xAxis.tickInterval= 2*24 * 3600 * 1000;
      else this.kpiHistory.xAxis.tickInterval= 24 * 3600 * 1000;
      
      this.issueData = {
        'completeness':{},
        'quality':{}
      };

      this.countParameters[this.groupSelect].forEach(param=>{
        this.issueData['completeness'][param.tag] = groupData["completeness_issues"][param.tag];
        this.issueData['quality'][param.tag] = groupData["quality_issues"][param.tag];
      })
    },
    issueClick(item)
    {
      let data = 
      {
        'status':'All',
        'completeness':[],
        'quality':[],
        'tier':'',
        'severity':'',
        'location':'',
        'active':false,
        'missing':false,
        'sensor':item.sensor
      };
      
      this.fetchFilterParameters(data);
      this.$router.push({ name:'vesseldetails', params: this.$route.params });
    },
    signalStatusClick(type,item)
    {
      let data = 
      {
        'status':'All',
        'completeness':[],
        'quality':[],
        'tier':'',
        'severity':'',
        'location':'',
        'active':false,
        'missing':false,
        'sensor':''
      };

      if (item.location!='Total') data['location'] = item.location;

      if (type!='expected') data[type] = true;
      
      this.fetchFilterParameters(data);
      this.$router.push({ name:'vesseldetails', params: this.$route.params });
    },
    goToIssues(param, type)
    {
      let data = 
      {
        'status':'All',
        'completeness':[],
        'quality':[],
        'tier':'',
        'severity':'',
        'location':'',
        'active':false,
        'missing':false,
        'sensor':''
      };

      data[type] = [0,90];

      if (param.tag!='total')
      {
        let groupTag = this.groupSelect.charAt(0).toLowerCase() + this.groupSelect.slice(1);

        let groupVal = param.tag.replace(groupTag,'');

        groupVal = groupVal.charAt(0).toUpperCase() + groupVal.slice(1);

        data[groupTag] = groupVal;
      }
      
      if (type=='quality') data['completeness']=[1,100];

      this.fetchFilterParameters(data);
      this.$router.push({ name:'vesseldetails', params: this.$route.params });
    }
  }, 
  watch: {    
    getVesselOverview(){

      this.complTopIssues=[];
      this.qualTopIssues=[];
      this.signalStatus=[];

      for(let i=0;i<this.getVesselOverview.completeness_top_issues.length;i++)
      {
        let issue = this.getVesselOverview.completeness_top_issues[i];

        let ts='';
        if ((issue.date!=null)&&(issue.date!=''))
        {
          let temp = new Date(issue.date);
         // temp.setMinutes(temp.getMinutes() - temp.getTimezoneOffset());
          ts = temp.toISOString().slice(0,10);
        }

        this.complTopIssues.push({
          completeness:issue.completeness.toFixed(1)+' %',
          sensor:issue.sensor,
          timestamp : ts
        })      
        
      }

      for(let i=0;i<this.getVesselOverview.quality_top_issues.length;i++)
      {
        let issue = this.getVesselOverview.quality_top_issues[i];
        
        let ts='';
        if (issue.date!=null) 
        {
          let temp = new Date(issue.date);
         // temp.setMinutes(temp.getMinutes() - temp.getTimezoneOffset());
          ts = temp.toISOString().slice(0,10);
        }

        // if (issue.completeness!=0)
          this.qualTopIssues.push({
            quality:issue.quality.toFixed(1)+' %',
            sensor:issue.sensor,
            timestamp :ts
          })        
      }

      let statisticsKeys = Object.keys(this.getVesselOverview.statistics);

      for (let i=0;i<statisticsKeys.length;i++)
      {
        let key = statisticsKeys[i];

        this.signalStatus.push(this.getVesselOverview.statistics[key]);

        this.signalStatus[i]['location'] = key.charAt(0).toUpperCase() + key.slice(1);
      }
      this.setGroupControls();

    },
    groupSelect: {
      handler: function() {
        if (this.groupSelect == null)
        return;       

        this.setGroupControls();

      },
      deep: true,
      immediate: true
    },
    '$route.params': {
      handler: function() {      
        this.groupSelect = this.groupDropDown[0];       
                
        this.vesselName = this.$route.params.vesselName;
      },
      deep: true,
      immediate: true
    },
    getLastUpdate: {
      handler: function() { 
        this.reportDate =  new Date(this.getLastUpdate).toISOString().substring(0,16).replace('T', ' ');
      }
    }
  }
}
</script>

<style scoped>



table.v-table tbody td {
  font-size: 18px !important;
}

  .signalStatusHoverable:hover{
    font-weight: bold;
    color: white;
    font-size: 1.25rem;
  }

</style>
