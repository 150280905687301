<template>
  <v-container class="containerView h-100" style="align-content: center;">
    <v-row class="rowView d-flex justify-center">
      <v-col center :cols=5 class="colView d-flex justify-center">
        <v-card class="container card card-shadow">
          <v-card-title class="titleColumn justify-center align-start" style="height: 10rem;">
            <PropanLogo :size="`26rem`"/>
          </v-card-title>
          <v-card-text class="justify-center align-center" style="height: 9rem;">
            <v-row class="rowView d-flex justify-center">
              <img src="ADQM_logo_white.svg" width="120" height="120"/>
              <!--              <img src="ADQM_blue_let.png" width="320" height="120"/>-->
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn variant="outlined" size="x-large" @click="login()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="loading" id="login-overlay">
      <v-row class="rowView d-flex justify-center">
        <v-col center :cols=2 class="colView d-flex justify-center">
          <v-card class="container card card-shadow">
            <v-card-title class="titleColumn justify-center align-start" style="height: 3rem;">
              Loading
            </v-card-title>
            <v-card-actions>
              <span class="loader"></span>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import {msalService} from '@/useAuth'
import {mapActions, mapGetters} from "vuex";
import PropanLogo from '@/components/PropanLogo.vue'

export default {
  name: 'Login',
  components: {
    PropanLogo
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters(['getAzureAppId', 'getAzureRedUri', 'getMSALInstance']),
    loading: function () {
      return this.getAzureRedUri.length === 0;
    }
  },
  methods: {
    ...mapActions(['setAzureJWTCookie', 'authOnAPI']),
    login: async function () {
      if (this.getAzureRedUri.length > 0) {
        await this.getMSALInstance.logout()
        await this.getMSALInstance.login()
      }
    },
    handleRedirect: async function () {
      if (this.getAzureRedUri.length > 0) {
        try {
          let account = await this.getMSALInstance.handleRedirect();

          if (account) {
            let token = await this.getMSALInstance.getToken();

            await this.setAzureJWTCookie(token);
            await this.authOnAPI(token);

            location.reload()
          }
        } catch (e) {}
      }
    }
  },
  mounted() {
  },
  watch: {
    getAzureRedUri: function (value) {
      if (this.getAzureRedUri.length > 0) {
        this.handleRedirect()
      }
    }
  }
}
</script>

<style>
#login-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-content: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>