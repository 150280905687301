<template>
  <v-container class="containerView">
    <v-row class="rowView" style="height:100%;">
      <v-col :cols=12 class="colView">
        <v-row class="rowView" style="height:45px;">
          <v-spacer/>
          <v-text-field
              v-model="search"
              style="max-width:450px;padding-top:0px;"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              color='#1890ff'
          ></v-text-field>
          <v-btn outlined color="#16304E" style="margin:auto 10px;" @click="openPopup('addEditPopup','add', '')">
            Add New
            <v-icon right>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn outlined color="#16304E" style="margin:auto 10px;" @click="downloadExcel()">
            Active Signals Table
            <v-icon right>
              mdi-file-table-box-multiple
            </v-icon>
          </v-btn>

        </v-row>
        <v-divider/>
        <v-row class="rowView" style="height:calc(100% - 45px);" ref="parentDiv" v-resize="updateDataTableHeight">
          <v-data-table
              style="height:100%;margin:5px;width:100%;"
              v-model="selectedRows"
              :show-select="true"
              :height="dataTableHeight"
              fixed-header disable-pagination hide-default-footer
              :headers="headers" :items="items"
              :search="search"
              :calculate-widths="true">
            <template v-slot:item.actions="{ item }">
              <v-icon color="#1890ff" :disabled="!item.actions.edit" style="margin:5px;"
                      @click="openPopup('addEditPopup','edit', item.imo)">
                mdi-pencil
              </v-icon>
              <v-icon right color="#FF4D4F" :disabled="!item.actions.delete" style="margin:5px;"
                      @click="openPopup('deletePopup', 'delete', item.imo)">
                mdi-close
              </v-icon>
            </template>
            <template v-slot:item.isActive="{ item }">
              <v-checkbox
                  style="width:24px;height:24px;margin:auto 0px;"
                  :input-value="item.isActive"
                  value
                  disabled
              ></v-checkbox>
            </template>
          </v-data-table>
        </v-row>

      </v-col>
    </v-row>
    <v-dialog v-model="addEditPopup" width="1400" persistent style="max-height: none;">
      <v-card outlined rounded="lg">
        <v-card-title v-if="popUpMode=='edit'">{{ selVessel.name }}</v-card-title>
        <v-card-title v-else>Add a new vessel</v-card-title>
        <v-card-actions>
          <v-container class="containerView">
            <v-row class="rowView" style="height:400px;overflow-y:auto;" :key="redraw">
              <v-col :cols="2" class="colView">
                <v-row class="rowView" style="height:85px;" v-for="item in dropDown" :key="item">
                  <v-col :cols="12" class="rowView">
                    <div class="big-text subtleBlue">{{ item.title }}</div>
                    <v-autocomplete v-model="item.model" :items="item.options"
                                    :class="item.errorInput ? 'errorInput' : ''"
                                    :persistent-hint="item.errorInput" :hint="item.errorMsg"
                                    outlined flat dense
                                    style="font-size:14px;"/>
                  </v-col>
                </v-row>
                <v-row class="rowView" v-for="item in checkBox" :key="item">
                  <v-col :cols="12" class="rowView">
                    <v-checkbox
                        style="width:24px;height:24px;margin:auto 0px;"
                        v-model="item.model"
                        :label="item.title"
                        color="#1890ff"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col :cols="2.5" class="colView" v-for="n in 4" :key="n">
                <v-row class="rowView" style="height:85px;" v-for="item in columnInputs(n,4)" :key="item">
                  <v-col :cols="12" class="rowView">
                    <div class="big-text subtleBlue">{{ item.title }}</div>
                    <v-text-field v-model="item.model" outlined dense
                                  :class="item.errorInput ? 'errorInput' : ''"
                                  :persistent-hint="item.errorInput" :hint="item.errorMsg"
                                  style="font-size:14px;"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="rowView big-text subtleBlue" style="height:40px;">
              {{ activeSignals.title }}
              <v-spacer/>
              <v-text-field
                  style="max-width:400px;"
                  v-model="popUpSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
              <v-btn @click.stop="selectedSignals=[]" outlined depressed style="margin-left:10px;" color="#1890ff">
                Clear Selection
                <!-- <v-icon right>
                  mdi-backspace
                </v-icon> -->
              </v-btn>
            </v-row>
            <v-row class="rowView">
              <v-data-table style="width:100%;"
                            v-model="selectedSignals"
                            item-key="id"
                            height="400"
                            :search="popUpSearch"
                            fixed-header disable-pagination hide-default-footer
                            :headers="activeSignals.headers"
                            :items="activeSignals.items"
                            sort-by='sensor'
                            :sort-desc=false
                            @current-items="currentItems = $event"
                            @click:row="rowClick">
                <template v-slot:item.active="{ item }">
                  <v-checkbox
                      style="width:24px;height:24px;margin:auto;"
                      v-model="item.active"
                      color="#1890ff"
                      @change="activeChanged(item)"
                      @click="editedID=item.id"
                  ></v-checkbox>
                </template>
                <template v-slot:item.interval="{ item }">
                  <v-text-field style="max-width:50px;margin:auto;font-size:14px;"
                                hide-details
                                label=""
                                v-model="item.interval"
                                color="#1890ff"
                                @change="intervalChanged(item)"
                                @click="editedID=item.id"
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-row>


            <v-row class="rowView" style="height:45px;">
              <v-spacer/>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="addEditVessel()">
                OK
              </v-btn>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="addEditPopup=false">
                Cancel
              </v-btn>

            </v-row>
          </v-container>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletePopup" width="600" persistent>
      <v-card v-if="selVessel!=null">
        <v-card-title>
          Are you sure you want to delete {{ selVessel.name }} ?
        </v-card-title>
        <v-card-actions>
          <v-row class="rowView" style="height:45px;">
            <v-spacer/>
            <v-btn dark color="#1890ff" style="margin:5px;" @click="deleteVessel()">
              OK
            </v-btn>
            <v-btn dark color="#1890ff" style="margin:5px;" @click="deletePopup=false">
              Cancel
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loginPopup" width="400" persistent>
      <v-card>
        <v-card-title>
          Admin Password
        </v-card-title>
        <v-card-actions>
          <v-col class="colView">
            <v-row class="rowView">
              <v-text-field
                  required v-model="password"
                  outlined dense :type="pwdShow ? 'text' : 'password'"
                  style="font-size:14px;"
                  :class="pwdError ? 'errorInput' : ''"
                  :persistent-hint="pwdError" :hint="pwdMsg"
                  :append-icon="pwdShow ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="pwdShow = !pwdShow"
                  @keydown.enter="login()"/>
            </v-row>
            <v-row class="rowView" style="height:45px;">
              <v-spacer/>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="login()">
                Submit
              </v-btn>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="leaveAdmin()">
                Cancel
              </v-btn>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-container>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import Highcharts from 'highcharts'
import axios from 'axios'

export default {
  name: 'AdminVessels',
  components: {},
  data: function () {
    return {
      password: null,
      pwdError: false,
      pwdMsg: '',
      pwdShow: false,
      redraw: 0,
      selectedSignals: [],
      fieldTitles: {
        'aShop': 'aShop',
        'actions': '',
        'b': 'b',
        'bsr_low': 'Low BSR',
        'bsr_upper': 'Upper BSR',
        'class': 'Class',
        'country': 'Country',
        'cylOilCapacity': 'Cylinder Oil Capacity',
        'd': 'd',
        'dieselGenOilCapacity': 'Diesel Gen. Oil Capacity',
        'fdmActive': 'FDM Active',
        'group': 'Group',
        'id': '',
        'imo': 'IMO',
        'isActive': 'Active',
        'lbp': 'lbp',
        'loa': 'loa',
        'mainEngOilCapacity': 'Main Engine Oil Capacity',
        'mcr': 'MCR',
        'me_fo_shop_max': 'me_fo_shop_max',
        'nShop': 'nShop',
        'n_mcr': 'nMCR',
        'name': 'Name',
        't_max': 't_max',
        't_min': 't_min',
        'trim_max': 'Max Trim',
        'trim_min': 'Min Trim',
        'type': 'Type'
      },
      headerTags: ['name', 'group', 'class', 'type', 'imo', 'isActive', 'country'/*,'aShop','bsr_low','bsr_upper','cylOilCapacity','dieselGenOilCapacity','mainEngOilCapacity','mcr','me_fo_shop_max','nShop','n_mcr','t_max','t_min'*/],
      headers: [],
      items: [],
      deletePopup: false,
      addEditPopup: false,
      loginPopup: true,
      popUpMode: '',
      selVessel: null,
      checkBox: [
        {
          field: 'isActive',
          model: null
        }
      ],
      dropDown: [
        {
          field: 'class',
          optionsTag: 'classes',
          model: null,
          options: [],
          errorInput: false
        },
        {
          field: 'group',
          optionsTag: 'groups',
          model: null,
          options: [],
          errorInput: false
        },
        {
          field: 'type',
          optionsTag: 'types',
          model: null,
          options: [],
          errorInput: false
        },
        {
          field: 'country',
          optionsTag: 'countries',
          model: null,
          options: [],
          errorInput: false
        }
      ],
      activeSignals: {
        title: 'Active Signals',
        headers: [
          {
            text: 'SENSOR',
            value: 'sensor',
            class: "big-text titleBlack"
          },
          {
            text: 'LOCATION',
            value: 'location',
            class: "big-text titleBlack"
          },
          {
            text: 'SIGNAL',
            value: 'signal',
            class: "big-text titleBlack"
          },
          {
            text: 'ACTIVE',
            value: 'active',
            align: 'center',
            class: "big-text titleBlack"
          },
          {
            text: 'INTERVAL',
            value: 'interval',
            align: 'center',
            class: "big-text titleBlack"
          }],
        items: []
      },
      vesselSignals: {
        headers: [],
        items: []
      },
      inputTags: ['name', 'imo', 'bsr_low', 'bsr_upper', 'cylOilCapacity', 'dieselGenOilCapacity', 'mainEngOilCapacity', 'mcr', 'n_mcr', 'me_fo_shop_max', 'aShop', 'nShop', 't_max', 't_min'],
      input: [],
      dataTableHeight: 100,
      search: '',
      popUpSearch: '',
      editedID: '',
      shiftKeyOn: false,
      currentItems: [],
      selectedRows: []
    }
  },
  created() {
    const self = this;
    self.keyDownHandler = function ({key}) {
      if (key == "Shift") self.shiftKeyOn = true;
    };
    self.keyUpHandler = function ({key}) {
      if (key == "Shift") self.shiftKeyOn = false;
    };
    window.addEventListener("keydown", this.keyDownHandler);
    window.addEventListener("keyup", this.keyUpHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyDownHandler);
    window.removeEventListener("keyup", this.keyUpHandler);
  },
  computed: {
    ...mapGetters(['getVessels', 'getSignalMapping', 'getAdminData', 'getAdminVesselSignals', 'getAdminApiUrl']),
  },
  mounted() {
    if (this.$cookies.get("adminLogged")) this.loginPopup = false;
  },
  methods: {
    ...mapActions(['fetchAdminVesselSignals', 'fetchAdminData']),
    async downloadExcel() {

      let csvContent = "data:text/csv;charset=utf-8,";
      let sigFields = ['name', 'system', 'location', 'tier'];
      let sigHeaders = ['Name', 'System', 'Location', 'Tier'];

      let vesselsActive = [];
      let activeCount = Array(sigFields.length).fill(" ");

      for (let i = 0; i < this.selectedRows.length; i++) {
        let imo = this.selectedRows[i].imo;

        const response = await axios.get(this.getAdminApiUrl + '/vessels/' + imo + '/signals')

        if (response.data.success) {
          vesselsActive.push(response.data.data);
          activeCount.push(response.data.data.filter(item => item.isActive).length);
        }
      }

      let vessels = this.selectedRows.map(row => row.name);

      csvContent += sigHeaders.join(",") + "," + vessels.join(",") + "\r\n";

      csvContent += activeCount.join(",") + "\r\n";

      const locations = [...new Set(this.getAdminData.signals.map(item => item.location))];

      locations.forEach(loc => {
        let locationSignals = this.getAdminData.signals.filter(item => item.location == loc);

        let systems = [...new Set(locationSignals.map(item => item.system))];

        systems.forEach(sys => {
          let sysSignals = locationSignals.filter(item => item.system == sys);

          csvContent += sys + "\r\n";

          sysSignals.forEach(sig => {
            let item = [];
            sigFields.forEach(field => item.push(sig[field]));

            vesselsActive.forEach(vess => {
              let tmp = vess.find(t => t.signalId == sig.id);

              if ((tmp != undefined) && (tmp.isActive)) item.push('+');
              else item.push(' ');
            })

            csvContent += item.join(",") + "\r\n";

          })

        })

      })

      // for (let i=0;i<this.getAdminData.signals.length;i++)
      // {
      //   let sig = this.getAdminData.signals[i];

      //   let item=[];
      //   sigFields.forEach(field=>item.push(sig[field]));

      //   vesselsActive.forEach(vess=>{
      //     let tmp = vess.find(t=>t.signalId==sig.id);

      //     if ((tmp!=undefined)&&(tmp.isActive)) item.push('+');
      //     else item.push(' ');
      //   })

      //   csvContent += item.join(",") + "\r\n";
      // }


      var encodedUri = encodeURI(csvContent),
          link = document.createElement("a");

      link.setAttribute("href", encodedUri.replaceAll('#', '%23'));
      link.setAttribute("download", "ADQM_ActiveSignals.csv");
      document.body.appendChild(link); // Required for FF

      link.click();

    },
    rowClick(data, item) {
      if (this.editedID == data.id) {
        this.editedID = null;
        return;
      }

      if ((this.shiftKeyOn) && (this.selectedSignals.length >= 1)) {
        let startIndex = this.currentItems.findIndex(t => this.selectedSignals.includes(t));
        let endIndex = this.currentItems.indexOf(data);

        if (startIndex > endIndex) {
          let temp = startIndex;
          startIndex = endIndex;
          endIndex = temp;
        }
        for (let i = startIndex; i <= endIndex; i++) {
          this.selectedSignals.push(this.currentItems[i]);
        }

      } else {
        if (this.selectedSignals.includes(data)) this.selectedSignals.splice(this.selectedSignals.indexOf(data), 1)
        else this.selectedSignals.push(data);
      }
    },
    async login() {
      const response = await axios.post(this.getAdminApiUrl + '/login', {
        password: this.password
      });

      if (response.data.success) {
        this.$cookies.set("adminLogged", true, "1d");
        this.loginPopup = false;
      } else {
        this.pwdError = true;
        this.pwdMsg = "The password you submited is wrong.";
      }
    },
    leaveAdmin() {
      this.$router.push({name: 'fleet'});
    },
    columnInputs(column, columns) {
      const total = this.input.length;
      const step = Math.ceil(total / columns);

      let top = (step * column);
      const bottom = top - step;

      return this.input.filter(item =>
          this.input.indexOf(item) >= bottom
          && this.input.indexOf(item) < top,
      );
    },
    intervalChanged(row) {
      if (this.selectedSignals.includes(row)) {
        let newInterval = row.interval;

        this.selectedSignals.forEach(item => item.interval = newInterval);
      }
    },
    activeChanged(row) {
      if (this.selectedSignals.includes(row)) {
        let newActive = row.active;

        this.selectedSignals.forEach(item => item.active = newActive);
      }
    },
    openPopup(model, mode, imo) {
      if (imo != '') {
        this.selVessel = this.items.find(item => item.imo == imo);
      }

      if (mode == 'edit') {
        this.loadVesselForEdit(imo);
      } else {
        this.checkBox.forEach(item => {
          item.model = null;
        })

        this.dropDown.forEach(item => {
          item.model = null;
          item.errorInput = false;
          item.errorMsg = '';
        })

        this.input.forEach(item => {
          item.model = null;
          item.errorInput = false;
          item.errorMsg = '';
        })

        let sigs = this.getAdminData.signals;
        let systems = this.getAdminData.systems;

        this.activeSignals.items = [];

        sigs.forEach(sig => {

          let sysMap = systems.find(sys => sys.id == sig.systemId);

          this.activeSignals.items.push({
            sensor: sysMap.name,
            location: sysMap.location,
            signal: sig.name,
            active: false,
            interval: 1,
            id: sig.id
          });
        })
      }

      this.popUpMode = mode;

      this[model] = true;
    },
    loadVesselForEdit(imo, loadSignals) {
      if (typeof loadSignals == 'undefined') {
        loadSignals = true
      }

      this.checkBox.forEach(item => {
        item.model = this.selVessel[item.field];
      })

      this.dropDown.forEach(item => {
        item.model = this.selVessel[item.field];
        item.errorInput = false;
        item.errorMsg = '';
      })

      this.input.forEach(item => {
        item.model = this.selVessel[item.field];
        item.errorInput = false;
        item.errorMsg = '';
      })

      if (loadSignals) {
        this.fetchAdminVesselSignals(imo);
      }
    },
    async addEditVessel() {
      let postData = {};

      this.checkBox.forEach(item => {
        if (item.model) postData[item.field] = 1;
        else postData[item.field] = 0;
      })

      this.dropDown.forEach(item => {
        postData[item.field] = item.model;
      })

      this.input.forEach(item => {
        postData[item.field] = item.model;
      })

      let postUrl = '';

      if (this.popUpMode == 'edit') {
        let selIMO = this.selVessel.imo;

        postData.signals = [];

        this.getAdminVesselSignals.forEach(item => {
          let popUpSig = this.activeSignals.items.find(sig => sig.id == item.signalId),
              isActive = false;

          if (item.isActive) isActive = true;

          if ((popUpSig.active != isActive) || (popUpSig.interval != item.fieldSamplingFreq)) {
            let postActive = 0;
            if (popUpSig.active) postActive = 1;

            postData.signals.push({
              id: popUpSig.id,
              isActive: postActive,
              fieldSamplingFreq: popUpSig.interval
            })
          }
        })

        postUrl = this.getAdminApiUrl + '/vessels/update/' + selIMO;

      } else {
        postData.activeSignals = this.activeSignals.items.filter(sig => sig.active);

        postUrl = this.getAdminApiUrl + '/vessels/create';
      }

      const response = await axios.post(postUrl, postData);

      if (response.data.success) {
        this.fetchAdminData();
        this.addEditPopup = false;
      } else {
        this.setErrorMessages(response.data.data);
      }
    },
    async deleteVessel() {
      let selIMO = this.selVessel.imo;

      const response = await axios.post(this.getAdminApiUrl + '/vessels/delete/' + selIMO, {});

      if (response.data.success) {
        //this.items = this.items.filter(item=> item.imo != selIMO);
        this.fetchAdminData();
        this.deletePopup = false;
      }
    },
    setErrorMessages(errorDict) {
      this.dropDown.forEach(item => {
        if (item.field in errorDict) {
          item.errorInput = true;
          let msg = errorDict[item.field];

          if (msg == 'required') item.errorMsg = 'This field is required.'
        } else {
          item.errorInput = false;
          item.errorMsg = '';
        }
      })

      this.input.forEach(item => {
        if (item.field in errorDict) {
          item.errorInput = true;

          let msg = errorDict[item.field];

          if (msg == 'required') item.errorMsg = 'This field is required.'
          else item.errorMsg = 'The value of this field should be a ' + msg + '.'
        } else {
          item.errorInput = false;
          item.errorMsg = '';
        }
      })

      this.redraw++;
    },
    updateDataTableHeight() {
      this.dataTableHeight = this.$refs['parentDiv'].clientHeight;
    }
  },
  watch: {
    selectedSignals: {
      handler: function () {
      },
      deep: true,
      immediate: true
    },
    selVessel: {
      handler: function () {


      },
      deep: true,
      immediate: true
    },
    getAdminData: {
      handler: function () {
        this.headers = [];

        this.headers = this.headerTags.map(item => {
          return {text: this.fieldTitles[item], value: item, class: ['big-text', 'titleBlack', 'headerClass']};//, width: this.fieldTitles[item].length*10 + 40};
        });

        //this.headers.push({ text: "", value: 'actions', class:['big-text', 'titleBlack'], align:'right', width:80});     

        this.headers.splice(0, 0, {
          text: "",
          value: 'actions',
          class: ['big-text', 'titleBlack'],
          align: 'right',
          width: 80
        });

        this.items = this.getAdminData.vessels.map(item => {
          item.actions =
              {
                edit: true,
                delete: true
              };
          if (item.isActive == 0) item.isActive = false;
          else item.isActive = true;
          return item;
        })

        this.dropDown.forEach(item => {
          item.title = this.fieldTitles[item.field];

          item.options = this.getAdminData[item.optionsTag].map(op => op.name);
        })

        this.checkBox.forEach(item => {
          item.title = this.fieldTitles[item.field];
        })

        this.input = [];

        this.inputTags.forEach(tag => {
          this.input.push({
            field: tag,
            title: this.fieldTitles[tag],
            model: null,
            errorInput: false
          });
        });

        if (this.selVessel != null) {
          this.loadVesselForEdit(this.selVessel.imo, false);
        }
      },
      deep: true,
      immediate: true
    },
    getAdminVesselSignals: {
      handler: function () {

        let sigs = this.getAdminData.signals;
        let systems = this.getAdminData.systems;

        this.activeSignals.items = [];

        this.getAdminVesselSignals.forEach(item => {
          let isActive = false;
          if (item.isActive) isActive = true;

          let sigMap = sigs.find(sig => sig.id === item.signalId);

          let sysMap = systems.find(sys => sys.id === sigMap.systemId);

          this.activeSignals.items.push({
            sensor: sysMap.name,
            location: sysMap.location,
            signal: sigMap.name,
            active: isActive,
            interval: item.fieldSamplingFreq,
            id: item.signalId
          });
        })
      },
      deep: true,
      immediate: true
    },


  }
}
</script>

<style scoped>

.errorInput >>> .v-messages__message {
  color: #FF4D4F;
  font-size: 14px;
  padding: 2px;
  font-weight: 500;
}

.errorInput.v-text-field--outlined >>> fieldset {
  border-color: #FF4D4F;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
  margin-left: 8px;
}

.v-text-field {
  margin: auto;
  padding-top: 0px;
}

</style>

<style>
/* .v-data-table--fixed-header > .v-data-table__wrapper > table > tbody > tr > td 
{ 
  -moz-user-select: none;
  -ms-user-select: none; 
  user-select: none;
} */

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100% !important;
}

.v-data-table tbody tr.v-data-table__selected {
  background-color: rgba(24, 144, 255, 0.4) !important;
}
</style>
