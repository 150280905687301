<template>
  <v-container class="containerView">
    <v-progress-linear
        :active="loading"
        color="#1890ff"
        indeterminate
        height="6"/>
    <v-row class="rowView" :style="[loading? {height:'calc(100% - 6px)'}:{height:'100%'}]">
      <v-col :cols=12 class="colView">
        <v-row class="rowView" style="height:45px;">
          <v-btn-toggle v-model="status" tile dense group>
            <v-btn v-for="st in statusOptions" :key="st" :value="st.value"
                   :style="btnStyle(st)">
              {{ st.title }}
            </v-btn>
          </v-btn-toggle>
          <v-spacer/>
          <v-text-field
              v-model="search"
              style="max-width:450px;padding-top:0px;"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              color='#1890ff'
          ></v-text-field>
          <v-btn @click.stop="filterDrawer = !filterDrawer" text icon style="margin:auto;" color="#1890ff">
            <v-icon medium>filter_alt</v-icon>
          </v-btn>

          <v-btn @click="resetFilterParameters" text x-small style="margin:auto 5px;padding:2px;" color="#1890ff">
            Clear<br/>Filters
          </v-btn>

        </v-row>
        <v-divider/>
        <v-row class="rowView" style="height:calc(100% - 45px);">
          <OverviewTable
              ref="parentDiv"
              style="height:100%;margin:5px;width:100%;"
              :headers="vessel_headers"
              :items="vessel_items"
              :expand="true"
              :search="search"
              :dataTableHeight="dataTableHeight"
              v-resize="updateDataTableHeight"
          />
          <v-navigation-drawer
              v-model="filterDrawer"
              absolute
              right
              temporary
              hide-overlay
              width=350>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Completeness</v-list-item-title>
                  <v-list-item-action>
                    <v-range-slider
                        v-model="complRange"
                        :max="max"
                        :min="min"
                        hide-details
                        class="align-center"
                        color='#1890ff'
                        style="margin:10px;max-width:280px;">
                      <template v-slot:prepend>
                        <div class="sub-title" tstyle="width: 45px;height:100%;">{{ complRange[0] }}</div>
                      </template>
                      <template v-slot:append>
                        <div class="sub-title" tstyle="width: 45px;height:100%;">{{ complRange[1] }}</div>
                      </template>
                    </v-range-slider>
                  </v-list-item-action>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Quality</v-list-item-title>
                  <v-list-item-action>
                    <v-range-slider
                        v-model="qualRange"
                        :max="max"
                        :min="min"
                        hide-details
                        class="align-center"
                        color='#1890ff'
                        style="margin:10px;max-width:280px;">
                      <template v-slot:prepend>
                        <div class="sub-title" tstyle="width: 45px;height:100%;">{{ qualRange[0] }}</div>
                      </template>
                      <template v-slot:append>
                        <div class="sub-title" tstyle="width: 45px;height:100%;">{{ qualRange[1] }}</div>
                      </template>
                    </v-range-slider>
                  </v-list-item-action>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="tag in Object.keys(dropDownData)" :key="tag">
                <v-list-item-content>
                  <v-list-item-title>{{ dropDownData[tag].title }}</v-list-item-title>
                  <v-list-item-action>
                    <v-autocomplete
                        solo flat
                        v-model="dropDownData[tag].model"
                        :items="dropDownData[tag].options"
                        hide-details dense
                        item-text="name" item-value="value"
                        style="font-size:16px;"
                    />
                  </v-list-item-action>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-row style="padding:16px;width:100%;margin:0px;">
              <v-spacer/>
              <v-btn @click="setFilterParameters" dark depressed color="#1890ff" style="margin:5px;">
                OK
              </v-btn>
              <!-- <v-btn @click="resetFilterParameters" dark depressed color="#1890ff" style="margin:5px;">
                Reset
              </v-btn> -->
            </v-row>
          </v-navigation-drawer>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';

highchartsMore(Highcharts);
solidGauge(Highcharts);

import OverviewTable from "../controls/OverviewTable";

export default {
  name: 'VesselDetails',
  components: {
    OverviewTable
  },
  data: function () {
    return {
      loading: true,
      filterDrawer: null,
      min: 0,
      max: 100,
      complRange: [0, 100],
      qualRange: [0, 100],
      search: '',
      status: ['All'],
      allAlerts: [],
      statusOptions: [
        {
          title: 'All',
          value: 'All',
          color: '#595959'
        },
        {
          title: 'Alert',
          value: 'Alert',
          color: '#FF4D4F'
        },
        {
          title: 'Warning',
          value: 'Warning',
          color: '#FFAA40'
        },
        {
          title: 'Healthy',
          value: 'Healthy',
          color: '#23CE75'
        }
      ],
      periodDict: {
        'last_day': 'daily',
        'last_week': 'weekly',
        'last_month': 'monthly'
      },
      vessel_headers: [
        {text: 'STATUS', value: 'status', class: "big-text titleBlack", align: 'center'},
        {text: 'LOCATION', value: 'location', class: "big-text titleBlack"},
        {text: 'SENSOR', value: 'sensor', class: "big-text titleBlack"},
        {text: 'SIGNAL', value: 'signal', class: "big-text titleBlack"},
        {text: 'COMPLETENESS', value: 'completeness', class: "big-text titleBlack", align: 'center'},
        {text: 'QUALITY', value: 'quality', class: "big-text titleBlack", align: 'center'},
        // { text: 'CUMULATIVE KPI', value: 'cmlvKPI' , class:"big-text titleBlack"  },
        {text: 'SEVERITY', value: 'severity', class: "big-text titleBlack", align: 'center'},
        // {text: 'TIER', value: 'tier', class: "big-text titleBlack", align: 'center'},
        {text: 'LAST UPDATE', value: 'dateText', class: "big-text titleBlack"},
        // { text: 'METHODS', value: 'tempMethods' , class:"big-text titleBlack" },
        {text: '', value: 'data-table-expand', class: "big-text titleBlack"},
      ],
      vessel_items: [],
      vessels: {},
      dateRange: {
        dateMenu: false,
        startDate: null,
        endDate: null,
      },
      dropDownData: {
        severity: {
          title: "Severity",
          model: '',
          options: [
            {
              name: 'All',
              value: ''
            },
            {
              name: 'High',
              value: 'High'
            },
            {
              name: 'Medium',
              value: 'Medium'
            },
            {
              name: 'Low',
              value: 'Low'
            }
          ]
        },
        tier: {
          title: "Tier",
          model: '',
          options: [
            {
              name: 'All',
              value: ''
            },
            {
              name: 'Tier 1',
              value: '1'
            },
            {
              name: 'Tier 2',
              value: '2'
            },
            {
              name: 'Tier 3',
              value: '3'
            }
          ]
        },
        location: {
          title: "Location",
          model: '',
          options: [
            {
              name: 'All',
              value: ''
            },
            {
              name: 'Bridge',
              value: 'Bridge'
            },
            {
              name: 'CCR',
              value: 'CCR'
            },
            {
              name: 'Engine Room',
              value: 'Engine Room'
            }
          ]
        },
        sensor: {
          title: "Sensor",
          model: '',
          options: []
        },
        condition: {
          title: "Condition",
          model: '',
          options: [
            {
              name: 'Expected',
              value: ''
            },
            {
              name: 'Active',
              value: 'active'
            },
            {
              name: 'Missing',
              value: 'missing'
            }
          ]
        },
        application: {
          title: "Application Specific",
          model: '',
          options: []
        }
      },
      severityDropDown: ['All', 'High', 'Medium', 'Low'],
      selectedSeverity: 'All',
      dataTableHeight: 100
    }

  },
  computed: {
    ...mapGetters(['getVessels', 'getSignalMapping', 'getSignalData', 'getFilterParameters', 'getSelPeriod', 'getAdminData'])
  },
  mounted() {
    //this.fetchAdminData();
  },
  beforeDestroy() {
    this.fetchFilterParameters({
      'status': 'All',
      'completeness': [],
      'quality': [],
      'tier': '',
      'severity': '',
      'location': '',
      'active': false,
      'missing': false,
      'sensor': ''
    });
  },
  methods: {
    ...mapActions(['fetchFilterParameters', 'fetchAdminData']),
    btnStyle(btn) {

      if (btn.value != this.status)
        return {
          'color': btn.color,
          'text-transform': 'none',
          'font-weight': 500
        };
      else
        return {
          'color': btn.color,
          'text-transform': 'none',
          'font-weight': 500,
          'border-bottom': '3px solid ' + btn.color
        };

    },
    updateDataTableHeight() {
      this.dataTableHeight = this.$refs['parentDiv'].$el.clientHeight;
    },
    setFilterParameters() {

      let data =
          {
            'status': this.status,
            'completeness': this.complRange,
            'quality': this.qualRange,
            'tier': this.dropDownData['tier'].model,
            'severity': this.dropDownData['severity'].model,
            'location': this.dropDownData['location'].model,
            'active': false,
            'missing': false,
            'sensor': this.dropDownData['sensor'].model,
            'application': this.dropDownData['application'].model
          };

      if (this.dropDownData['condition'].model != '') data[this.dropDownData['condition'].model] = true;

      this.fetchFilterParameters(data);

      this.filterDrawer = false;
    },
    resetFilterParameters() {

      let data =
          {
            'status': this.status,
            'completeness': [0, 100],
            'quality': [0, 100],
            'tier': '',
            'severity': '',
            'location': '',
            'active': false,
            'missing': false,
            'sensor': '',
            'application': '',
          };

      this.fetchFilterParameters(data);

      this.filterDrawer = false;
    },
    filter() {
      this.vessel_items = this.allAlerts;

      if (this.getFilterParameters['location'] != '') {
        let loc = this.getFilterParameters['location'];

        this.vessel_items = this.vessel_items.filter(item => {
          return item.location == loc;
        })
      }
      this.dropDownData['location'].model = this.getFilterParameters['location'];

      if (this.getFilterParameters['active']) {
        this.dropDownData['condition'].model = 'active';

        this.vessel_items = this.vessel_items.filter(item => {
          return !item.missing;
        })
        //this.vessel_items = this.vessel_items.filter(item=>{ return item.fdqmKpi > 0 ;})
      } else if (this.getFilterParameters['missing']) {
        this.dropDownData['condition'].model = 'missing';

        this.vessel_items = this.vessel_items.filter(item => {
          return item.missing;
        })

      } else this.dropDownData['condition'].model = '';

      if (this.getFilterParameters['sensor'] != '') {
        let sens = this.getFilterParameters['sensor'];

        this.vessel_items = this.vessel_items.filter(item => {
          return item.sensor == sens;
        })
      }
      this.dropDownData['sensor'].model = this.getFilterParameters['sensor'];

      if (this.getFilterParameters['severity'] != '') {
        let sev = this.getFilterParameters['severity'];

        this.vessel_items = this.vessel_items.filter(item => {
          return item.severity == sev;
        })
      }
      this.dropDownData['severity'].model = this.getFilterParameters['severity'];

      if (this.getFilterParameters['tier'] != '') {
        let tier = this.getFilterParameters['tier'];

        this.vessel_items = this.vessel_items.filter(item => {
          return item.tier == tier;
        })
      }
      this.dropDownData['tier'].model = this.getFilterParameters['tier'];

      if (this.getFilterParameters['quality'].length > 0) {
        let max = this.getFilterParameters['quality'][1];
        let min = this.getFilterParameters['quality'][0];

        this.qualRange = [min, max];

        if (!((min == 0) && (max == 100)))
          this.vessel_items = this.vessel_items.filter(item => {
            return item.quality != null && item.quality <= max && item.quality >= min;
          });
      }

      if (this.getFilterParameters['completeness'].length > 0) {
        let max = this.getFilterParameters['completeness'][1];
        let min = this.getFilterParameters['completeness'][0];

        this.complRange = [min, max];

        if (!((min == 0) && (max == 100)))
          this.vessel_items = this.vessel_items.filter(item => {
            return item.completeness != null && item.completeness <= max && item.completeness >= min;
          });
      }

      try {
        if (this.getFilterParameters['application'].length) {
          let signals = this.getAdminData.signals
              .filter(item => {
                return item[this.getFilterParameters['application']]
              })
              .map(item => {
                return item.tag
              })

          this.vessel_items = this.vessel_items.filter(item => {
            return signals.indexOf(item.tag) > -1;
          })
        }
      } catch (e) {
      }

      for (let i = 1; i < this.statusOptions.length; i++) {
        let st = this.statusOptions[i];
        let num = this.vessel_items.filter(item => {
          return item.status == st.value;
        }).length;
        st.title = st.value + ' (' + num + ')';
      }

      if (this.getFilterParameters['status'] != 'All') {
        let st = this.getFilterParameters['status'];

        this.vessel_items = this.vessel_items.filter(item => {
          return item.status == st;
        })
      }
    },
    setAllAlerts() {
      this.allAlerts = [];

      let kpis = [
        {
          title: 'Completeness',
          tag: 'completeness'
        },
        {
          title: 'Quality',
          tag: 'health'
        }
      ];

      let aggrKey = this.periodDict[this.getSelPeriod];

      for (let i = 0; i < this.getSignalData.length; i++) {
        let signal = this.getSignalData[i];

        let aggrData = signal['aggregated'][aggrKey];

        let statusText = "";

        if (signal.kpi >= 80) {
          statusText = "Healthy";
        } else if (signal.kpi >= 50) {
          statusText = "Warning";
        } else {
          statusText = "Alert";
        }

        let values = [
          {
            title: 'Value',
            tag: 'value'
          },
          {
            title: 'Raw Value',
            tag: 'value_raw'
          },
          {
            title: 'Reference',
            tag: 'value_ref'
          }
        ];

        values.forEach(item => {
          let val = signal[item.tag];

          if ((val == null) || (val == '-')) item.value = '-';
          else item.value = val.toFixed(1);
        })

        let signalMap = this.getSignalMapping.find(item => item.id == signal.signals_id);

        kpis.forEach(item => {
          let val = aggrData[item.tag];

          if ((val == null) || (val == '-')) {
            item.value = '-';
            item.color = '#16222d';
          } else {
            item.value = val.toFixed(1);

            if (val < 50) item.color = '#FF4D4F';
            else if (val < 80) item.color = '#FFAA40';
            else item.color = '#23CE75';
          }
        })

        let ts = '-';
        if (signal.validated.last_update != null) {
          let temp = new Date(signal.validated.last_update);
          ts = temp.toISOString().slice(0, 10) + ' ' + temp.toISOString().slice(11, 16) + ' UTC';
        }

        let alert = {
          id: signal.signals_id,
          sensor: signal.system_name,
          signal: signal.signals_name,
          tag: signal.signals_tag,
          description: signal.description,
          cause: signal.cause,
          status: statusText,
          completeness: aggrData.completeness,//signal.completeness,
          quality: signal.validated.quality,//aggrData.quality,//signal.quality,
          severity: signal.severity,
          tier: signal.tier,
          location: signal.system_location,
          fdqmKpi: aggrData.kpi,//signal.kpi,
          timestamp: signal.validated.last_update,//signal.timestamp,
          dateText: ts,
          values: values,
          kpis: kpis,
          unit: signalMap.unit,
          period: aggrKey,
          missing: aggrData.missing,//signal.missing,
          tempValid: signal.validated,
          tempMethods: signal.methods.length,
          cmlvKPI: signal.cumulative_kpi,
          methods: JSON.parse(JSON.stringify(signal.methods))
        };

        // alert.methods.splice(0, 0, {
        //   name: 'Pre-process',
        //   score: aggrData.quality
        // })

        alert.methods.forEach(item => {
          let scText;

          if ((item.score != null) && (item.score >= 0)) {
            item.scText = parseFloat(item.score).toFixed(0) + ' %';
          } else {
            item.scText = '-';
          }
        })

        alert.methods.sort(function (a, b) {
          return a.score < b.score ? 1 : -1;
        })

        this.allAlerts.push(alert);
      }

      this.dropDownData['application'].options = [{
        name: 'None',
        value: ''
      }, ...(this.getAdminData.consumers || []).map(item => {
        return {name: item.name, value: item.look_up}
      })];

      let allSensors = [...new Set(this.allAlerts.map(item => item.sensor))];
      this.dropDownData['sensor'].options = allSensors.map(item => {
        return {name: item, value: item}
      });
      this.dropDownData['sensor'].options.splice(0, 0, {
        name: 'All',
        value: ''
      });

    }
  },
  watch: {
    getSelPeriod: {
      handler: function () {
        this.setAllAlerts();
        this.filter();
      },
      deep: true,
      immediate: true
    },
    status() {
      let data = this.getFilterParameters;
      data['status'] = this.status;

      this.fetchFilterParameters(data);
    },
    getSignalData: {
      handler: function () {
        if (this.getSignalData.length > 0) {
          this.setAllAlerts();

          this.filter();

          this.loading = false;

        }


      },
      deep: true,
      immediate: true
    },
    getFilterParameters: {
      handler: function () {
        this.filter();
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
