<template>
   <v-container class="containerView">
    <v-row class="rowView" style="height:100%;">
      <v-col :cols=12 class="colView">
        <v-row class="rowView" style="height:45px;">          
          <v-spacer/>
          <v-text-field
            v-model="search"
            style="max-width:450px;padding-top:0px;"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            color='#1890ff'
          ></v-text-field>   
        </v-row>
        <v-divider/>
        <v-row class="rowView" style="height:calc(100% - 45px);" ref="parentDiv" v-resize="updateDataTableHeight">
          <v-data-table   
            style="height:100%;margin:5px;width:100%;"     
            :height="dataTableHeight"      
            fixed-header disable-pagination  hide-default-footer 
            :headers="headers" :items="items"
            :search="search"
            :calculate-widths="true">
            <template v-slot:item.actions="{ item }">
              <v-icon color="#1890ff" :disabled="!item.actions.edit" style="margin:5px;" @click="openPopup(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.isActive="{ item }">
              <v-checkbox
                style="width:24px;height:24px;margin:auto 0px;"
                :input-value="item.isActive"
                value
                disabled
              ></v-checkbox>
            </template>
          </v-data-table>
        </v-row>
            
      </v-col>
    </v-row> 
    <v-dialog v-model="editPopup" width="1000" persistent style="max-height: none;">
      <v-card outlined rounded="lg">
        <v-card-title v-if="selMethod!=null">{{selMethod.name}}</v-card-title>
        <v-card-actions>
          <v-container class="containerView" :key="redraw" > 
            <v-row class="rowView" style="height:45px;">          
              <v-spacer/>
              <v-text-field
                v-model="editSearch"
                style="max-width:450px;padding-top:0px;"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                color='#1890ff'
              ></v-text-field>   
            </v-row>
            <v-divider/>
            <v-row class="rowView" > 
              <v-data-table   
                v-model="selectedRows"
                style="margin:5px;width:100%;"     
                height="500"  
                disable-pagination  hide-default-footer hide-default-header
                :headers="vessHeaders" :items="methodVessels"
                :calculate-widths="true"
                item-key="name"                
                :search="editSearch"
                show-select>
                <template v-slot:item.signals="{ item }">
                  <v-autocomplete v-model="item.model" :items="item.signals" 
                    @change="dropDownChange(item)"
                    multiple dense rounded chips deletable-chips
                    style="font-size:14px;"
                    item-text="title"
                    item-value="id"
                  ></v-autocomplete>
                </template>
              </v-data-table>  
            </v-row>          
           
            <v-row class="rowView" style="height:45px;">
              <v-spacer/>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="editMethod()">
                OK
              </v-btn>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="editPopup=false">
                Cancel
              </v-btn>

            </v-row>
          </v-container>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loginPopup" width="400" persistent>
      <v-card>
        <v-card-title>
          Admin Password
        </v-card-title>
        <v-card-actions>
          <v-col class="colView">
            <v-row class="rowView">
              <v-text-field 
                required v-model="password"
                outlined dense :type="pwdShow ? 'text' : 'password'"
                style="font-size:14px;"
                :class="pwdError ? 'errorInput' : ''" 
                :persistent-hint="pwdError" :hint="pwdMsg"
                :append-icon="pwdShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="pwdShow = !pwdShow"
                @keydown.enter="login()"/> 
            </v-row>
            <v-row class="rowView" style="height:45px;">
              <v-spacer/>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="login()">
                Submit
              </v-btn>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="leaveAdmin()">
                Cancel
              </v-btn>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
      
    </v-dialog>
  </v-container>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';   
import Highcharts from 'highcharts'
import axios from 'axios'

export default {
  name: 'AdminMethods',
  components: {
  },  
  data: function () {    
    return { 
      password:null,
      pwdError:false,
      pwdMsg:'',
      pwdShow:false,  
      loginPopup:true,
      redraw:0,   
      dataTableHeight:100,
      search:'',
      editSearch:'',
      fieldTitles:{
        'name': 'Name',
        'isActive':'Active',
        'description':'Description'
      },
      headerTags:['name', 'description'],//'isActive'],
      headers:[], 
      items:[],
      editPopup:false,
      selMethod:null,
      dropDown:[
        {
          field:'severity',
          model:null,
          options:[
            {text:'High', value:'High'},
            {text:'Medium', value:'Medium'},             
            {text:'Low', value:'Low'}
          ], 
          errorInput:false
        },
        {
          field:'tier',
          model:null,
          options:[
            {text:1, value:1},
            {text:2, value:2},
            {text:3, value:3}
          ], 
          errorInput:false
        },
        {
          field:'signalTypeId',
          optionsTag:'signal_types',
          model:null,
          options:[], 
          errorInput:false
        },
        {
          field:'systemId',
          optionsTag:'systems',
          model:null,
          options:[], 
          errorInput:false
        }
      ],
      inputTags:['name','tag', 'unit','interval','intervalUnit','max','min'],
      input:[],
      methodVessels:[],
      selectedRows:[],
      vessHeaders:[
        { text: 'VESSEL', value: 'name', class:"big-text titleBlack"},
        { text: 'SIGNALS', value: 'signals', class:"big-text titleBlack" }],
    }
  },  
  computed:{
    ...mapGetters(['getVessels','getAdminData','getAdminApiUrl']),
  },
  mounted(){
    if (this.$cookies.get("adminLogged")) this.loginPopup = false;
  
  },
  methods:{
    ...mapActions(['fetchAdminData']),   
    dropDownChange(item){
      if (this.selectedRows.includes(item))
      {
        let selModel = item.model;

        this.selectedRows.forEach(v=> v.model = selModel);
      }

      this.redraw++;

    },
    updateDataTableHeight () {
      this.dataTableHeight = this.$refs['parentDiv'].clientHeight;    
    },
    columnInputs(column, columns) {
      const total = this.input.length;
      const step = Math.ceil(total / columns);

      let top = (step * column);
      const bottom = top - step;

      return this.input.filter(item =>
        this.input.indexOf(item) >= bottom
        && this.input.indexOf(item) < top,
      ); 
    },
    openPopup(item)
    {
      this.selectedRows = [];
      this.selMethod = item;

      this.methodVessels = Object.values(this.selMethod.vessels);

      let sigList=[];

      this.selMethod.allowed.forEach(list=>
      {
        let listMap = this.getAdminData.signal_lists.find(item=>item.id==list);

        let tmp=[];
        
        listMap.list.forEach(sig=>{
          tmp.push(this.getAdminData.signals.find(item=>item.id==sig).name);
        })

        sigList.push({
          title: tmp.reduce((a,b)=>a+', '+b),
          id: list
        });
      })

      this.methodVessels.forEach(item=>{

        let imo = this.getAdminData.vessels.find(v=>v.name==item.name).imo;

        item.imo = imo;
        item.signals = sigList.filter(s=>item.allowed.includes(s.id));
        item.model = item.active;
      })

      this.editPopup = true;    
    },
    async editMethod()
    {          

      let vessDict = {};
      this.methodVessels.forEach(vess=> vessDict[vess.imo]=vess.model);

      let postData = {
        'func':this.selMethod.func,
        'vessels':vessDict
      };
      
      const response = await axios.post(this.getAdminApiUrl+'/methods/update', postData);
      
      if (response.data.success) 
      { 
        this.fetchAdminData();
        
        this.editPopup = false;
      }
    },
    async login()
    {
      const response = await axios.post(this.getAdminApiUrl+'/login', 
      {
        password: this.password
      });

      if (response.data.success)
      {        
        this.$cookies.set("adminLogged", true, "1d");
        this.loginPopup = false;
      }
      else
      {
        this.pwdError = true;
        this.pwdMsg = "The password you submited is wrong.";
      }

    },
    leaveAdmin()
    {
       this.$router.push({ name: 'fleet'});
    },
  }, 
  watch: {  
    getAdminData: {
      handler: function() {
        this.headers =[];
        
        this.headers =this.headerTags.map(item=>{
          return { text: this.fieldTitles[item], value: item, class:['big-text', 'titleBlack', 'headerClass']};
        });

        this.headers.splice(0,0,{ text: "", value: 'actions', class:['big-text', 'titleBlack'], align:'right', width:40}); 

        this.items=this.getAdminData.methods.map(item=>{
          item.actions = {edit:true};
          return item;
        });

      },
      deep: true,
      immediate: true
    } ,
    selectedRows: {
      handler: function() {
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

.errorInput >>> .v-messages__message
{
  color: #FF4D4F;
  font-size:14px;
  padding:2px;
  font-weight:500;
}

.errorInput.v-text-field--outlined >>> fieldset {
  border-color: #FF4D4F;
}

.v-input--selection-controls {
  margin-top: 0px; 
  padding-top: 0px;
  margin-left:8px;
}

.v-text-field {
  margin: auto; 
  padding-top: 0px;
}

</style>
