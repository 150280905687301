import axios from 'axios'
import { getCustomInstance, state } from './msalConfig'

export function msalService(azureAppId, azureRedUri) {
    let msalInstance = getCustomInstance(azureAppId, azureRedUri)

    const initialize = async () => {
        try {
            await msalInstance.initialize() // Call the initialize function
        } catch (error) {
            console.error('Initialization error', error)
        }
    }

    const login = async () => {
        try {
            // Check if MSAL is initialized before using it
            if (!msalInstance) {
                throw new Error('MSAL not initialized. Call initializeMsal() before using MSAL API.')
            }
            await msalInstance.loginRedirect()
            state.isAuthenticated = true
        } catch (error) {
            console.error('Login error:', error)
        }
    }

    const logout = () => {
        if (!msalInstance) {
            throw new Error('MSAL not initialized. Call initializeMsal() before using MSAL API.')
        }
        const accounts = msalInstance.getAllAccounts();

        if (accounts.length > 0) {
            msalInstance.logoutRedirect({
                account: accounts[0],
                postLogoutRedirectUri: window.location,
                onRedirectNavigate: (url) => {
                    // Return false if you would like to stop navigation after local logout
                    return false;
                }
            })
        }
        state.isAuthenticated = false
        state.user = null
    }

    const handleRedirect = async () => {
        try {
            await msalInstance.handleRedirectPromise()
            state.isAuthenticated = msalInstance.getAllAccounts().length > 0
            state.user = msalInstance.getAllAccounts()[0]
            return msalInstance.getAllAccounts()[0]
        } catch (error) {
            console.error('Redirect error:', error)
        }
    }
    const getToken = async () => {
        if (!msalInstance) {
            throw new Error('MSAL not initialized. Call initializeMsal() before using MSAL API.')
        }
        try {
            const accounts = msalInstance.getAllAccounts()
            if (accounts.length === 0) {
                throw new Error('No accounts found. Please login first.')
            }

            msalInstance.setActiveAccount(accounts[0])

            const silentRequest = {
                "scopes": [`api://${azureAppId}/Read`],
            }
            const silentResponse = await msalInstance.acquireTokenSilent(silentRequest)
            return silentResponse.accessToken
        } catch (error) {
            // console.error('Silent token acquisition error:', error)
        }
    }
    const registerAuthorizationHeaderInterceptor = () => {
        axios.interceptors.request.use(async (config) => {
            const accessToken = await getToken()
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`
            }
            return config
        })
    }

    const getActiveAccount = () => {
        let accounts = msalInstance.getAllAccounts();
        return accounts[0]
    }

    return {
        initialize,
        login,
        logout,
        handleRedirect,
        getToken,
        registerAuthorizationHeaderInterceptor,
        getActiveAccount
    }
}