<template>
  <v-container class="containerView">
    <v-row class="rowView" style="height:100%;">
      <v-col :cols=12 class="colView">
        <v-row class="rowView" style="height:45px;">
          <v-autocomplete v-model="selType" :items="alertTypes"
                          solo flat dense hide-details
                          item-color="#638399"
                          color="#638399"
                          item-text="title" return-object
                          style="font-size:16px;margin:auto;max-width:250px;"/>
          <v-spacer/>
          <v-text-field
              v-model="search"
              style="max-width:450px;padding-top:0px;"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              color='#1890ff'
          ></v-text-field>
          <v-btn outlined color="#16304E" style="margin:auto 10px;" @click="openPopup('addEditPopup','add', null)" :disabled="selType==null">
            Add New
            <v-icon right>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
        <v-divider/>
        <v-row class="rowView" style="height:calc(100% - 45px);" ref="parentDiv" v-resize="updateDataTableHeight">
          <v-data-table
              style="height:100%;margin:5px;width:100%;"
              :height="dataTableHeight"
              fixed-header disable-pagination hide-default-footer
              :headers="headers" :items="items"
              :search="search"
              :calculate-widths="true">
            <template v-slot:item.actions="{ item }">
              <v-icon color="#1890ff" :disabled="!item.actions.edit" style="margin:5px;" @click="openPopup('addEditPopup', 'edit', item)">
                mdi-pencil
              </v-icon>
              <v-icon right color="#FF4D4F" :disabled="!item.actions.delete" style="margin:5px;" @click="openPopup('deletePopup', 'delete', item)">
                mdi-close
              </v-icon>
            </template>
            <template v-slot:item.difference="{ item }">
              {{ differenceToText(item.difference) }}
            </template>
            <template v-slot:item.applicationId="{ item }">
              {{ applicationTitle(item.applicationId) }}
            </template>
          </v-data-table>
        </v-row>

      </v-col>
    </v-row>
    <v-dialog v-model="loginPopup" width="400" persistent>
      <v-card>
        <v-card-title>
          Admin Password
        </v-card-title>
        <v-card-actions>
          <v-col class="colView">
            <v-row class="rowView">
              <v-text-field
                  required v-model="password"
                  outlined dense :type="pwdShow ? 'text' : 'password'"
                  style="font-size:14px;"
                  :class="pwdError ? 'errorInput' : ''"
                  :persistent-hint="pwdError" :hint="pwdMsg"
                  :append-icon="pwdShow ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="pwdShow = !pwdShow"
                  @keydown.enter="login()"/>
            </v-row>
            <v-row class="rowView" style="height:45px;">
              <v-spacer/>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="login()">
                Submit
              </v-btn>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="leaveAdmin()">
                Cancel
              </v-btn>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog v-model="addEditPopup" width="400" persistent style="max-height: none;">
      <v-card outlined rounded="lg">
        <v-card-title v-if="popUpMode=='edit'">Edit Alert</v-card-title>
        <v-card-title v-else-if="selType!=null">Add a new {{ selType.title }} alert</v-card-title>
        <v-card-actions>
          <v-container class="containerView">
            <v-row class="rowView" :key="redraw">
              <v-col :cols="12" class="colView">
                <v-row class="rowView" v-for="item in dropDown" :key="item">
                  <v-col :cols="12" class="rowView" style="height:85px;" v-if="item.enabled">
                    <div class="big-text subtleBlue">{{ titleDict[item.field] }}</div>
                    <v-autocomplete
                        v-model="item.model"
                        :items="item.options"
                        outlined flat dense
                        style="font-size:14px;"
                        @change="dropDownChange(item)"
                    />
                  </v-col>
                  <div v-if="item.field=='signal'" class="medium-text darkBlue" style="font-weight:500;margin-bottom:5px;">You can also choose signals based on the following groupings (severity, tier, system)</div>
                </v-row>
                <v-row class="rowView" v-for="item in input" :key="item">
                  <v-col :cols="12" class="rowView" style="height:85px;" v-if="item.enabled">
                    <div class="big-text subtleBlue">{{ titleDict[item.field] }}</div>
                    <v-text-field v-model="item.model" outlined dense
                                  style="font-size:14px;" :rules="item.rules"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="rowView" style="height:45px;">
              <v-spacer/>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="addEditAlert()">
                OK
              </v-btn>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="addEditPopup=false">
                Cancel
              </v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletePopup" width="450" persistent>
      <v-card v-if="selAlert!=null">
        <v-card-title>
          Are you sure you want to delete this alert?
        </v-card-title>
        <v-card-actions>
          <v-row class="rowView" style="height:45px;">
            <v-spacer/>
            <v-btn dark color="#1890ff" style="margin:5px;" @click="deleteAlert()">
              OK
            </v-btn>
            <v-btn dark color="#1890ff" style="margin:5px;" @click="deletePopup=false">
              Cancel
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

import {mapGetters, mapActions} from 'vuex';
import Highcharts from 'highcharts'
import axios from 'axios'

export default {
  name: 'AdminAlerts',
  components: {},
  data: function () {
    return {
      password: null,
      pwdError: false,
      pwdMsg: '',
      pwdShow: false,
      loginPopup: true,
      selType: null,
      dropDown: [
        {
          field: 'vessel',
          model: null,
          options: [],
          errorInput: false,
          enabled: true
        },
        {
          field: 'issue',
          model: null,
          options: [],
          errorInput: false,
          enabled: true
        },
        {
          field: 'signal',
          model: null,
          options: [],
          errorInput: false,
          enabled: true
        },
        {
          field: 'severity',
          model: null,
          options: ['High', 'Medium', 'Low'],
          errorInput: false,
          enabled: true
        },
        {
          field: 'tier',
          model: null,
          options: ['Tier 1', 'Tier 2', 'Tier 3'],
          errorInput: false,
          enabled: true
        },
        {
          field: 'signalSystem',
          model: null,
          options: [],
          errorInput: false,
          enabled: true
        },
        {
          field: 'applicationId',
          model: null,
          options: [],
          errorInput: false,
          enabled: true
        },
        {
          field: 'target',
          model: null,
          options: ['kpi', 'quality', 'completeness'],
          errorInput: false,
          enabled: true
        }
      ],
      levelValue: 100,
      input: [
        {
          field: 'level',
          model: null,
          enabled: true,
          rules: [
            value => value <= this.levelValue || 'Value cannot be over ' + this.levelValue + '.'
          ]
        },
        {
          field: 'difference',
          model: null,
          enabled: true,
          rules: [value => {
            const pattern = /\b(\d+\.?\d*)\s*(s|min|h|d)/
            return pattern.test(value) || 'Number followed by s, min, h or d'
          }]
        }
      ],
      alertTypes: [
        {
          title: 'Issue',
          headerTags: ['vessel', 'issue', 'signal', 'severity', 'tier', 'signalSystem', 'applicationId'],
          typeTags: ['issue']
        },
        {
          title: 'Level',
          headerTags: ['vessel', 'signal', 'severity', 'tier', 'signalSystem', 'applicationId', 'target', 'level'],
          typeTags: ['vessel_lvl', 'signal_lvl'],
          levelTitle: 'Level',
          levelValue: 100
        },
        {
          title: 'Update',
          headerTags: ['vessel', 'signal', 'severity', 'tier', 'signalSystem', 'applicationId', 'difference'],
          typeTags: ['vessel_time_diff', 'signal_time_diff']
        },
        {
          title: 'Hourly',
          headerTags: ['vessel', 'signal', 'severity', 'tier', 'signalSystem', 'applicationId', 'level'],
          typeTags: ['hourly'],
          levelTitle: 'No. of samples',
          levelValue: 3600
        }
      ],
      titleDict:
          {
            'vessel': 'Vessel',
            'issue': 'Issue',
            'signal': 'Signal',
            'severity': 'Severity',
            'tier': 'Tier',
            'signalSystem': 'System',
            'applicationId': 'Application Specific',
            'target': 'Target',
            'operator': 'Operator',
            'level': 'Level',
            'difference': 'Difference'
          },
      redraw: 0,
      dataTableHeight: 100,
      search: '',
      headers: [],
      items: [],
      deletePopup: false,
      addEditPopup: false,
      popUpMode: '',
      selAlert: null
    }
  },
  computed: {
    ...mapGetters(['getVessels', 'getAdminData', 'getAdminApiUrl', 'getAdminVesselSignals']),
  },
  mounted() {
    if (this.$cookies.get("adminLogged")) this.loginPopup = false;
  },
  methods: {
    ...mapActions(['fetchAdminData', 'fetchAdminVesselSignals']),
    updateDataTableHeight() {
      this.dataTableHeight = this.$refs['parentDiv'].clientHeight;
    },
    differenceToText(diff) {
      let day = 24 * 60 * 60;
      let hour = 60 * 60;
      let min = 60;

      if (diff > day) return (diff / day) + " d";
      else if (diff > hour) return (diff / hour) + " h";
      else if (diff > min) return (diff / min) + " min";
      else return diff + " s";

    },
    applicationTitle(id) {
      return id ? this.getAdminData.consumers.find(v => v.id == id).name : '-'
    },
    textToDifference(text) {
      let tmp = text.match(/\b(\d+\.?\d*)\s*(s|min|h|d)/);

      let unit = tmp[2], num = Number(tmp[1]);

      if (unit == "d") return num * 24 * 60 * 60;
      else if (unit == "h") return num * 60 * 60;
      else if (unit == "min") return num * 60;
      else return num;

    },
    dropDownChange(dropDown) {
      let sigFields = ['severity', 'tier', 'signalSystem'];

      if (sigFields.includes(dropDown.field))
        this.dropDown.find(dd => dd.field == 'signal').model = null;
      else if (dropDown.field == 'signal')
        this.dropDown.filter(dd => sigFields.includes(dd.field)).map(dd => dd.model = null);

      if (this.selType.title != 'Issue') {
        if (dropDown.field == 'vessel') {
          let imo = this.getAdminData.vessels.find(item => item.name == dropDown.model).imo;
          this.fetchAdminVesselSignals(imo);
        }
      } else {
        if (dropDown.field != 'signal') {
          let vessName = this.dropDown.find(item => item.field == 'vessel').model;
          let issueName = this.dropDown.find(item => item.field == 'issue').model;

          let method = this.getAdminData.methods.find(v => v.name == issueName);
          let vessel = this.getAdminData.vessels.find(v => v.name == vessName);

          if ((method == null) && (vessel == null)) {
            this.dropDown.find(item => item.field == 'signal').options = this.getAdminData.signals.map(a => a.name);
            this.dropDown.find(item => item.field == 'signal').options.splice(0, 0, 'All Signals');
            this.dropDown.find(item => item.field == 'severity').options = ['High', 'Medium', 'Low'];
            this.dropDown.find(item => item.field == 'tier').options = ['Tier 1', 'Tier 2', 'Tier 3'];
            this.dropDown.find(item => item.field == 'signalSystem').options = this.getAdminData.systems.map(a => a.name);
          } else if (method == null) {
            this.fetchAdminVesselSignals(vessel.imo);
          } else {
            let allowed = method.vessels[vessel.id].allowed;

            let sigList = [];

            allowed.forEach(list => {
              let listMap = this.getAdminData.signal_lists.find(item => item.id == list);

              listMap.list.forEach(sig => {
                sigList.push(this.getAdminData.signals.find(item => item.id == sig));
              })

            })

            this.dropDown.find(item => item.field == 'severity').options = [...new Set(sigList.map(s => s.severity))];
            this.dropDown.find(item => item.field == 'tier').options = [...new Set(sigList.map(s => s.tier))].map(t => 'Tier ' + t);
            this.dropDown.find(item => item.field == 'signalSystem').options = [...new Set(sigList.map(s => s.system))];

            this.dropDown.find(item => item.field == 'signal').options = sigList.map(s => s.name);

            this.dropDown.find(item => item.field == 'signal').options.splice(0, 0, 'All Signals');

          }
        }
      }
    },
    openPopup(model, mode, alert) {
      this.selAlert = alert;

      this.dropDown.find(item => item.field == 'applicationId').options = ['All Applications', ...this.getAdminData.consumers.map(a => a.name)];

      if (mode == 'edit') {
        if (this.selType.title != 'Issue') {
          if (this.selAlert.vessel != 'All Vessels') {
            let imo = this.getAdminData.vessels.find(item => item.name == this.selAlert.vessel).imo;

            this.fetchAdminVesselSignals(imo);
          } else {
            this.dropDown.find(item => item.field == 'signal').options = this.getAdminData.signals.map(a => a.name);
            this.dropDown.find(item => item.field == 'signal').options.splice(0, 0, 'All Signals');

            this.dropDown.find(item => item.field == 'severity').options = ['High', 'Medium', 'Low'];
            this.dropDown.find(item => item.field == 'tier').options = ['Tier 1', 'Tier 2', 'Tier 3'];
            this.dropDown.find(item => item.field == 'signalSystem').options = this.getAdminData.systems.map(a => a.name);
          }
        } else {

          let vessName = this.selAlert.vessel;
          let issueName = this.selAlert.issue;

          let method = this.getAdminData.methods.find(v => v.name == issueName);
          let vessel = this.getAdminData.vessels.find(v => v.name == vessName);

          if ((method == null) && (vessel == null)) {
            this.dropDown.find(item => item.field == 'signal').options = this.getAdminData.signals.map(a => a.name);
            this.dropDown.find(item => item.field == 'signal').options.splice(0, 0, 'All Signals');

            this.dropDown.find(item => item.field == 'severity').options = ['High', 'Medium', 'Low'];
            this.dropDown.find(item => item.field == 'tier').options = ['Tier 1', 'Tier 2', 'Tier 3'];
            this.dropDown.find(item => item.field == 'signalSystem').options = this.getAdminData.systems.map(a => a.name);
          } else if (method == null) {
            this.fetchAdminVesselSignals(vessel.imo);
          } else {
            let allowed = method.vessels[vessel.id].allowed;

            let sigList = [];

            allowed.forEach(list => {
              let listMap = this.getAdminData.signal_lists.find(item => item.id == list);

              listMap.list.forEach(sig => {
                sigList.push(this.getAdminData.signals.find(item => item.id == sig));
              })

            })

            this.dropDown.find(item => item.field == 'severity').options = [...new Set(sigList.map(s => s.severity))];
            this.dropDown.find(item => item.field == 'tier').options = [...new Set(sigList.map(s => s.tier))].map(t => 'Tier ' + t);
            this.dropDown.find(item => item.field == 'signalSystem').options = [...new Set(sigList.map(s => s.system))];

            this.dropDown.find(item => item.field == 'signal').options = sigList.map(s => s.name);
            this.dropDown.find(item => item.field == 'signal').options.splice(0, 0, 'All Signals');
          }
        }
      }

      this.dropDown.forEach(dd => {
        if (this.selType.headerTags.includes(dd.field)) dd.enabled = true;
        else dd.enabled = false;

        if (mode == 'edit') {
          if ((dd.field == 'tier') && (alert[dd.field] != null)) {
            dd.model = 'Tier ' + alert[dd.field];
          } else if (dd.field == 'applicationId') {
            let consumer = this.getAdminData.consumers.find(v => v.id == alert[dd.field]);

            dd.model = (consumer?consumer.name:'All Applications')
          } else {
            dd.model = alert[dd.field];
          }
        } else {
          dd.model = null;
        }
      });

      this.input.forEach(inp => {
        if (this.selType.headerTags.includes(inp.field)) inp.enabled = true;
        else inp.enabled = false;

        if (mode == 'edit') {
          if (inp.field == 'difference') inp.model = this.differenceToText(alert[inp.field]);
          else inp.model = alert[inp.field];
        } else inp.model = null;
      })

      this.popUpMode = mode;

      this[model] = true;
    },
    async addEditAlert() {
      let postData = {};

      this.dropDown.forEach(dd => {
        if (this.selType.headerTags.includes(dd.field))
          postData[dd.field] = dd.model;
      })

      this.input.forEach(inp => {
        if (this.selType.headerTags.includes(inp.field)) {
          if (inp.field == 'difference') postData[inp.field] = this.textToDifference(inp.model);
          else postData[inp.field] = inp.model;
        }
      })

      if ('vessel' in postData) {
        let vess = this.getAdminData.vessels.find(v => v.name == postData['vessel']);

        if (vess != null) postData.vesselId = vess.id;
        else postData.vesselId = -1;
      }
      if ('signal' in postData) {
        let sig = this.getAdminData.signals.find(v => v.name == postData['signal']);

        if (sig != null) postData.signalId = sig.id;
        else postData.signalId = -1;
      }
      if ('signalSystem' in postData) {
        let sys = this.getAdminData.systems.find(v => v.name == postData['signalSystem']);

        if (sys != null) postData.signalSystemId = sys.id;
        else postData.signalSystemId = -1;
      }
      if (('tier' in postData) && (postData['tier'] != null)) {
        postData.tier = postData['tier'].match(/(\d+)/)[0];

      }
      if ('issue' in postData) {
        let meth = this.getAdminData.methods.find(v => v.name == postData['issue']);

        if (meth != null) postData.func = meth.func;
        else postData.func = null;
      }
      if ('applicationId' in postData) {
        let consumer = this.getAdminData.consumers.find(v => v.name == postData['applicationId']);

        postData.applicationId = ((consumer != null) ? consumer.id : null)
      }

      if (this.selType.typeTags.length > 1) {
        if ((postData['signal'] == null) || (postData['signal'] == 'All Signals')) postData['type'] = this.selType.typeTags[0];
        else postData['type'] = this.selType.typeTags[1];
      } else postData['type'] = this.selType.typeTags[0];

      if (this.popUpMode == 'edit') {
        const response = await axios.post(this.getAdminApiUrl + '/rulesets/update/' + this.selAlert.id, postData);

        if (response.data.success) {
          this.fetchAdminData();

          this.addEditPopup = false;
        }
      } else {
        const response = await axios.post(this.getAdminApiUrl + '/rulesets/create', postData);

        if (response.data.success) {
          this.fetchAdminData();

          this.addEditPopup = false;
        }
      }
    },

    async deleteAlert() {
      //axios delete
      const response = await axios.post(this.getAdminApiUrl + '/rulesets/delete/' + this.selAlert.id, {});

      if (response.data.success) {
        this.fetchAdminData();

        this.deletePopup = false;
      }

    },
    async login() {
      const response = await axios.post(this.getAdminApiUrl + '/login',
          {
            password: this.password
          });

      if (response.data.success) {
        this.$cookies.set("adminLogged", true, "1d");
        this.loginPopup = false;
      } else {
        this.pwdError = true;
        this.pwdMsg = "The password you submited is wrong.";
      }

    },
    leaveAdmin() {
      this.$router.push({name: 'fleet'});
    },
  },
  watch: {
    getAdminData: {
      handler: function () {

        if (typeof this.getAdminData.vessels == 'undefined') {
          return
        }

        this.dropDown.find(item => item.field == 'vessel').options = this.getAdminData.vessels.filter(v => v.isActive).map(a => a.name);
        this.dropDown.find(item => item.field == 'vessel').options.splice(0, 0, 'All Vessels');

        this.dropDown.find(item => item.field == 'issue').options = this.getAdminData.methods.map(a => a.name);
        this.dropDown.find(item => item.field == 'issue').options.splice(0, 0, 'All Issues');

        this.dropDown.find(item => item.field == 'signalSystem').options = this.getAdminData.systems.map(a => a.name);

        if (this.selType == null) this.selType = this.alertTypes[0];
        else {
          this.headers = [];

          this.headers = this.selType.headerTags.map(item => {
            return {text: this.titleDict[item], value: item, class: ['big-text', 'titleBlack', 'headerClass']};
          });

          this.headers.splice(0, 0, {text: "", value: 'actions', class: ['big-text', 'titleBlack'], align: 'right', width: 80});

          this.items = [];

          let rules = this.getAdminData.alert_rules.filter(item => this.selType.typeTags.includes(item.type));
          rules.forEach(item => {

            if ((item.vesselId != null) && (item.vesselId != -1)) item.vessel = this.getAdminData.vessels.find(v => v.id == item.vesselId).name;
            else if (this.selType.headerTags.includes('vessel')) item.vessel = 'All Vessels';

            if ((item.signalId != null) && (item.signalId != -1)) item.signal = this.getAdminData.signals.find(s => s.id == item.signalId).name;
            else if (this.selType.headerTags.includes('signal')) item.signal = 'All Signals';

            if ((item.methodId != null) && (item.methodId != -1)) item.issue = this.getAdminData.methods.find(m => m.ids.includes(item.methodId)).name;
            else if (this.selType.headerTags.includes('issue')) item.issue = 'All Issues';

            if ((item.signalSystemId != null) && (item.signalSystemId != -1)) item.signalSystem = this.getAdminData.systems.find(s => s.id == item.signalSystemId).name;

            item.actions =
                {
                  edit: true,
                  delete: true
                };

            this.items.push(item);

          })

        }
      },
      deep: true,
      immediate: true
    },
    getAdminVesselSignals: {
      handler: function () {
        try {
          let options = [];
          let sigs = this.getAdminData.signals;

          this.getAdminVesselSignals.forEach(item => {
            let sigMap = sigs.find(sig => sig.id == item.signalId);

            if (sigMap != null)
              options.push(sigMap.name);
          })

          this.dropDown.find(item => item.field == 'signal').options = options;
          this.dropDown.find(item => item.field == 'signal').options.splice(0, 0, 'All Signals');

          this.dropDown.find(item => item.field == 'severity').options = ['High', 'Medium', 'Low'];
          this.dropDown.find(item => item.field == 'tier').options = ['Tier 1', 'Tier 2', 'Tier 3'];
          this.dropDown.find(item => item.field == 'signalSystem').options = this.getAdminData.systems.map(a => a.name);
        } catch (e) {
          console.error(e)
        }
      },
      deep: true,
      immediate: true
    },
    selType: {
      handler: function () {
        try {
          if (this.selType.headerTags.includes('level')) {
            this.titleDict['level'] = this.selType.levelTitle;
            this.levelValue = this.selType.levelValue;
          }

          this.headers = [];

          this.headers = this.selType.headerTags.map(item => {
            return {text: this.titleDict[item], value: item, class: ['big-text', 'titleBlack', 'headerClass']};
          });

          this.headers.splice(0, 0, {text: "", value: 'actions', class: ['big-text', 'titleBlack'], align: 'right', width: 80});

          this.items = [];

          let rules = this.getAdminData.alert_rules.filter(item => this.selType.typeTags.includes(item.type));

          rules.forEach(item => {

            if ((item.vesselId != null) && (item.vesselId != -1)) item.vessel = this.getAdminData.vessels.find(v => v.id == item.vesselId).name;
            else if (this.selType.headerTags.includes('vessel')) item.vessel = 'All Vessels';

            if ((item.signalId != null) && (item.signalId != -1)) item.signal = this.getAdminData.signals.find(s => s.id == item.signalId).name;
            else if (this.selType.headerTags.includes('signal')) item.signal = 'All Signals';

            if ((item.methodId != null) && (item.methodId != -1)) item.issue = this.getAdminData.methods.find(m => m.ids.includes(item.methodId)).name;
            else if (this.selType.headerTags.includes('issue')) item.issue = 'All Issues';

            if ((item.signalSystemId != null) && (item.signalSystemId != -1)) item.signalSystem = this.getAdminData.systems.find(s => s.id == item.signalSystemId).name;

            item.actions =
                {
                  edit: true,
                  delete: true
                };

            this.items.push(item);

          })
        } catch (e) {}
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

.errorInput >>> .v-messages__message {
  color: #FF4D4F;
  font-size: 14px;
  padding: 2px;
  font-weight: 500;
}

.errorInput.v-text-field--outlined >>> fieldset {
  border-color: #FF4D4F;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
  margin-left: 8px;
}

.v-text-field {
  margin: auto;
  padding-top: 0px;
}

</style>
