<template>
  <v-container class="containerView">
    <v-progress-linear
        :active="loading"
        color="#1890ff"
        indeterminate
        height="6"/>
    <v-row class="rowView" :style="[loading? {height:'calc(100% - 6px)'}:{height:'100%'}]">
      <v-col :cols=12 class="colView">
        <v-row class="rowView" style="height:55px;">
          <v-col :cols=3 class="colView">
            <v-autocomplete
                solo flat dense hide-details
                label="Application Specific"
                v-model="application.model"
                :items="application.options"
                item-color="#638399"
                color="#638399"
                item-text="name"
                item-value="tag"
                item-disabled="disabled"
                style="font-size:16px;margin:auto;"
            />
          </v-col>
          <v-spacer/>
          <v-col :cols=3 class="colView">
            <v-text-field
                v-model="search"
                style="max-width:450px;padding-top:0px;"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                color='#1890ff'
            />
          </v-col>
        </v-row>
        <v-divider/>
        <v-row class="rowView" ref="parentDiv" style="height:calc(100% - 45px);">
          <v-data-table
              :headers="issuesCategoriesHeaders"
              :items="filteredIssues"
              :height="dataTableHeight"
              item-key="methodRunningId"
              fixed-header disable-pagination hide-default-footer
              v-resize="updateDataTableHeight"
              style="height:100%;margin:5px;width:100%;"
              :single-expand="categorySingleExpand"
              :expanded="categoryExpanded"
              :show-expand="false"
              @click:row="categoryClick"
              :search="search"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style=" background-color:white;">
                <v-progress-linear
                    :active="periodLoading"
                    indeterminate
                    color="#1890ff"
                    height="6"/>
                <v-row class="rowView">
                  <v-col :cols=3 class="colView">
                    <v-row class="subtleBlue sub-title rowView" style="align-items: center;">Description</v-row>
                    <v-row class="labelLightBlack big-text rowView" style="align-items: center;">{{
                        item.description
                      }}
                    </v-row>
                    <v-row>
                      <v-data-table
                          :headers="issuesPeriodHeaders"
                          :items="formattedCategoryPeriods"
                          :height="'250px'"
                          fixed-header
                          style="height:100%;margin:5px;width:100%;"
                          @click:row="issueClick"
                      />
                    </v-row>
                  </v-col>
                  <v-col :cols=9 class="colView" style="max-width:74%">
                    <v-row class="rowView" v-on:dblclick="openChartPopup(item)">
                      <highcharts style="width:100%;height:100%;" :options="chartOptions"/>
                    </v-row>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="chartPopup" max-width="1620">
      <v-card style="width:1620px;height:600px;">
        <v-card-title class="titleColumn">{{ popUpTitle }}</v-card-title>
        <v-card-actions>
          <highcharts
              v-if="chartPopup"
              ref="popUpChart"
              :options="popChartOptions"
              style="width:100%;height:100%;"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';

highchartsMore(Highcharts);
solidGauge(Highcharts);

import IssueTable from "../controls/IssueTable";

export default {
  name: 'VesselIssues',
  components: {
    // IssueTable
  },
  data: function () {
    return {
      chartPopup: false,
      chartOptions: {},
      popChartOptions: {},
      popUpTitle: '',
      loading: true,
      startDate: null,
      endDate: null,
      allIssues: [],
      issuesCategoriesHeaders: [
        {text: 'ISSUE', value: 'uiname', class: "big-text titleBlack"},
        {text: 'SIGNALS', value: 'signals', class: "big-text titleBlack"},
        {text: 'FROM', value: 't_open', class: "big-text titleBlack"},
        {text: 'TO', value: 't_close', class: "big-text titleBlack"},
        {text: 'FREQUENCY', value: 'occurrences', class: "big-text titleBlack", align: 'center', width: '120px'}
      ],
      issuesPeriodHeaders: [
        {text: 'FROM', value: 't_open', class: "big-text titleBlack"},
        {text: 'TO', value: 't_close', class: "big-text titleBlack"},
      ],
      issuesHeaders: [
        {text: 'ISSUE', value: 'issue', class: "big-text titleBlack"},
        {text: 'SIGNALS', value: 'signalsView', class: "big-text titleBlack"},
        {text: 'FREQUENCY', value: 'occurrences', class: "big-text titleBlack", align: 'center', width: '120px'},
        {text: 'SEVERITY', value: 'severity', class: "big-text titleBlack", align: 'center', width: '110px'},
        // {text: 'TIER', value: 'tier', class: "big-text titleBlack", align: 'center', width: '80px'},
        {text: 'FROM', value: 'startDate', class: "big-text titleBlack"},
        {text: 'TO', value: 'endDate', class: "big-text titleBlack"},
        {text: '', value: 'data-table-expand', class: "big-text titleBlack"},
      ],
      dataTableHeight: 100,
      search: '',
      application: {
        model: '',
        options: [
          {
            name: 'None',
            value: ''
          }
        ]
      },
      categorySingleExpand: true,
      categoryExpanded: [],
      categoryExpand: null,
      properties: [
        {title: 'Description', tag: 'description'},
        {title: 'Signals', tag: 'signalsView'},
        {title: 'From', tag: 'startDate'},
        {title: 'To', tag: 'endDate'}
      ],
      chartDict: {
        'time series of four parameters and one reference line': 'ref',
        'time series of one parameter and one error - reference line': 'errorRef',
        'time series of one parameter and one reference line': 'ref',
        'time series of one parameter and two limit lines': 'limits',
        'time series of three parameter': 'woRef',//'multiParam',
        'time series of two parameters': 'woRef',//'multiParam',
        'time series of two parameters and one reference line': 'ref'
      },
      periodLoading: false
    }
  },
  computed: {
    ...mapGetters(['getSignalMapping', 'getIssueCategories', 'getIssuesData', 'getAdminData', 'getIssueCategoriesPeriods', 'getApiUrl', 'getSelIMO']),
    dateText: function () {
      if ((this.startDate == null) || (this.endDate == null)) {
        return null;
      } else {
        return 'From ' + this.startDate + ' to ' + this.endDate;
      }
    },
    filteredIssues: function () {
      try {
        let filteredIssues = this.getIssueCategories;

        if (this.application.model.length && this.application.model != 'None') {
          let application_value = this.application.options.find(opt => {
                return opt.name == this.application.model
              }).value,
              app_sigs = this.getSignalMapping.filter(item => {
                return item[application_value] == 1
              }).map(item => {
                return item.name
              });

          filteredIssues = filteredIssues.filter((issue => {
            let matched_sigs = issue.signals.filter(sig => {
              return app_sigs.indexOf(sig) > -1
            });

            return matched_sigs.length > 0
          }))
        }

        filteredIssues = filteredIssues.map((item) => {
          item['t_open'] = (new Date(item['t_open'])).toISOString();
          item['t_open'] = `${item['t_open'].slice(0, 10)} ${item['t_open'].slice(11, 16)}`

          item['t_close'] = (new Date(item['t_close'])).toISOString();
          item['t_close'] = `${item['t_close'].slice(0, 10)} ${item['t_close'].slice(11, 16)}`

          return item;
        })

        return filteredIssues;
      } catch (e) {
        return [];
      }
    },
    formattedCategoryPeriods: function () {
      try {
        let periods = JSON.parse(JSON.stringify(this.getIssueCategoriesPeriods));

        return periods.map((item) => {
          item['t_open'] = (new Date(item['t_open'])).toISOString();
          item['t_open'] = `${item['t_open'].slice(0, 10)} ${item['t_open'].slice(11, 16)}`

          item['t_close'] = (new Date(item['t_close'])).toISOString();
          item['t_close'] = `${item['t_close'].slice(0, 10)} ${item['t_close'].slice(11, 16)}`

          return item;
        });
      } catch (e) {
        return []
      }
    }
  },
  mounted() {
    this.setFilters();
  },
  beforeDestroy() {
  },
  methods: {
    ...mapActions(['fetchIssuesCategories', 'fetchIssuesData', 'setIssueMethod', 'fetchIssueCategoriesPeriods']),
    updateDataTableHeight() {
      this.dataTableHeight = this.$refs['parentDiv'].clientHeight;
    },
    openChartPopup(item) {
      this.popUpTitle = item.issue;
      this.popChartOptions = JSON.parse(JSON.stringify(this.chartOptions))

      this.popChartOptions.chart.height = '550px';
      this.popChartOptions.title.text = undefined;
      this.chartPopup = true;
      setTimeout(() => this.$refs['popUpChart'].chart.updateContainerScaling(), 500)
    },
    calcRange(startDate, endDate) {
      let selArray = [];

      let currentDate = new Date(startDate);

      while (currentDate < new Date(endDate)) {
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        selArray.push(new Date(currentDate).toISOString().substring(0, 10));
      }

      selArray.pop();
      return selArray;
    },
    setFilters() {
      this.application.options = [
        ...this.application.options,
        // ...this.getAdminData.consumers.map(item => {
        //   return {name: item.name, value: item.look_up}
        // })
      ]
    },
    categoryClick(data, item) {
      if (item.isExpanded) {
        item.expand(false);
      } else {
        item.expand(true);

        this.chartOptions = [];

        this.setIssueMethod(data.methodRunningId)
        this.fetchIssueCategoriesPeriods()
      }
    },
    issueClick(data, item) {
      let issueId = data.id,
          dataDict = {},
          counter = 0,
          chartType = this.chartDict[data.figures],
          tags;

      if (chartType == undefined) {
        chartType = 'limits';
      }

      this.chartOptions = [];

      this.chartOptions = {
        credits: {
          enabled: false
        },
        time: {
          useUTC: true
        },
        chart: {
          zoomType: 'xy',
          height: 400,
          events: {
            load() {
            }
          }
        },
        title: {
          text: undefined
        },
        yAxis: {
          title:
              {
                text: undefined,
                align: 'high'
              }
        },
        xAxis: {
          title:
              {
                text: undefined
              },
          type: 'datetime',
          labels: {
            format: '{value: %H:%M}'
          },
          crosshair: true
        },
        tooltip: {
          formatter: function () {
            var s = '<b>' + new Date(this.x).toISOString().substring(0, 16).replace('T', ' ') + '</b>',
                actTime = this.x, allSeries = [];

            if (this.points) {
              allSeries = this.points[0].series.chart.series
            } else {
              allSeries = this.point.series.chart.series;
            }

            allSeries.forEach(series => {
              var diff = series.closestPointRange, actPoint = null;

              series.data.forEach(point => {
                let temp = Math.abs(actTime - point.x)

                if ((diff == null) || (temp < diff)) {
                  actPoint = point;
                  diff = temp;
                }
              });

              if (actPoint != null) {
                s += '<br/> ' + actPoint.series.name + ': ' + actPoint.y.toFixed(2);
              }
            });
            return s;
          },
          shared: true,
          crosshairs: true
        },
        legend: {
          enabled: true
        },
        series: []
      };

      if (chartType == 'multiParam') {
        this.chartOptions.yAxis = [];
      }

      this.periodLoading = true;

      let apiUrl = this.getApiUrl.split('//'),
          ws_protocol = (apiUrl[0].indexOf('https') > -1 ? 'wss' : 'ws');

      var socket = new WebSocket(`${ws_protocol}://${apiUrl[1]}/issues/ws`)

      socket.onmessage = (event) => {
        var data = JSON.parse(event.data);

        if (data.success) {
          if (Object.keys(data).includes('can_request')) {
            socket.send(JSON.stringify({
              'imo': this.getSelIMO,
              'issueId': issueId
            }))
          } else if (Object.keys(data).includes('completed')) {
            //close
          }
        } else {
          if ('tags' in data) {
            tags = data.tags;

            tags.forEach((tag, index) => {
              let sigMap = this.getSignalMapping.find(s => s.tag == tag.tag), title;

              if (sigMap != undefined) {
                title = sigMap.name + ' [' + sigMap.unit + ']';
              } else {
                title = tag.title;
              }

              let ser = {
                name: title,
                type: 'line',
                tag: tag,
                data: [],
                marker: {
                  enabled: false,//true,
                  radius: 2
                },
                animation: false,
                states: {
                  hover: {
                    enabled: false
                  },
                  normal: {
                    animation: false
                  },
                  inactive: {
                    opacity: 1
                  }
                }
              };

              if (tag.type == 'lim')
                ser.color = '#FF4D4F';

              if (tag.type == 'prediction') {
                ser.marker.enabled = true;
                ser.type = 'scatter';
                ser.marker.symbol = 'circle';
              }

              let axis = {
                gridLineWidth: 2,
                title: {
                  text: title,
                  align: 'high',
                  style: {
                    color: Highcharts.getOptions().colors[index]
                  }
                }
              }

              if (chartType == 'multiParam') {
                ser.yAxis = index;
                this.chartOptions.yAxis.push(axis);
              } else {
                if (sigMap != undefined && !tag.axis.length) {
                  this.chartOptions.yAxis.title.text = '[' + sigMap.unit + ']';
                } else if (tag.axis.length) {
                  this.chartOptions.yAxis.title.text = '[' + tag.axis + ']';
                }
                this.chartOptions.yAxis.title.align = 'high';
              }

              this.chartOptions.series.push(ser);
            })
          } else {
            counter++;

            if (!('from' in data))
              this.chartOptions.xAxis.labels.format = '{value:%d-%m-%Y}';

            tags.forEach((tag, index) => {
              if (!(tag.tag in dataDict))
                dataDict[tag.tag] = new Array(data.max);

              if (tag.tag in data.records) {
                let entries = Object
                    .entries(data.records[tag.tag])
                    .map(item => {
                      let time = (new Date(item[1][0])).getTime(),
                          value = null;

                      if (item[1][1] != null) {
                        value = Math.round(item[1][1] * 1000) / 1000
                      }

                      return [time, value]
                    })
                    .sort((a, b) => a[0] - b[0]);

                dataDict[tag.tag][data.index - 1] = entries.filter(item => item != undefined && !Number.isNaN(item[0]));


                let ser = this.chartOptions.series.find(item => item.tag == tag);

                ser.data = dataDict[tag.tag].flat();
              }
            })

            if (counter == data.max) {
              socket.close();
              this.periodLoading = false;
            }
          }
        }
      }
    }
  },
  watch: {
    getIssuesData: {
      handler: function () {
        this.allIssues = [];
        this.getIssuesData.forEach(issue => {
          let signals = [];
          let signalMap;

          issue.tags.forEach(tag => {
            signalMap = this.getSignalMapping.find(s => s.tag == tag);

            signals.push(signalMap.name);
          })

          this.allIssues.push({
            id: issue.ids.reduce((a, b) => a + '' + b),
            ids: issue.ids,
            occurrences: issue.occurrences,
            issue: issue.uiname,
            reason: issue.reason,
            sensor: signalMap.system_name,
            signals: signals,
            signalsView: signals.reduce((a, b) => a + ', ' + b),
            description: issue.description,
            figures: issue.figures,
            severity: signalMap.severity,
            type: issue.type,
            tier: signalMap.tier,
            location: signalMap.system_location,
            startDate: issue.t_open.substring(0, 16),
            endDate: issue.t_close.substring(0, 16),
            unit: signalMap.unit,
            chartOptions: {},
            loading: false
          })
        })

        this.loading = false;
      },
      deep: true,
      immediate: true
    },
    application: {
      handler: function () {
        if (this.application.model == 'None') {
          this.application.model = '';
        }
      },
      deep: true,
      immediate: true
    },
    getIssueCategoriesPeriods: {
      handler: function (value) {
        if (value.length) {
          this.issueClick(value[0])
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>

