<template>
  <v-container class="containerView" style="background-color:white;max-width:200mm !important;">
    <v-row class="rowView">
      <v-col :cols=6 class="colView" >    
        <v-row class="subtleBlue main-title rowView" style="align-items: center;">{{groupName}}</v-row>
        <v-row class="labelLightBlack sub-title rowView" style="align-items: center;">{{startDate}} to {{endDate}}</v-row>
      </v-col>
    </v-row> 
    <v-row class="rowView"> 
      <v-card-title>KPI Trend</v-card-title>
      <v-card-actions>
        <highcharts style="width:100%;height:100%;" :options="kpiHistory" />
      </v-card-actions>
    </v-row>
    <v-row class="rowView" style="page-break-after:always;"> 
      <v-card-title>KPI Change Rate</v-card-title>
      <v-card-actions>
        <highcharts style="width:100%;height:100%;" :options="kpiRate" />
      </v-card-actions>
    </v-row>
    <v-row class="rowView" style="page-break-after:always;"> 
      <v-card-title>Sensors</v-card-title>
      <v-card-actions>
        <v-data-table 
          style="width:100%;height:100%;"
          fixed-header disable-pagination  hide-default-footer
          :headers="sensorsStatusHead"
          :items="sensorsStatusData"
          sort-by='avg'>
          <template v-slot:item.avg="{ item }">
            <div v-if="item.avg!=null&&item.avg!=''">
                {{ item.avg.toFixed(1) }}
            </div>
            <div v-else>
                -
            </div>
          </template>
          <template v-slot:item.min_kpi="{ item }">
            <div v-if="item.min_kpi!=null&&item.min_kpi!=''">
                {{ item.min_kpi.toFixed(1) }} at {{item.min_vessel}}
            </div>
            <div v-else>
                -
            </div>
          </template>
          <template v-slot:item.max_kpi="{ item }">
            <div v-if="item.max_kpi!=null&&item.max_kpi!=''">
                {{ item.max_kpi.toFixed(1) }}  at {{item.max_vessel}}
            </div>
            <div v-else>
                -
            </div>
          </template>
        </v-data-table>
      </v-card-actions>
    </v-row>
    <v-row class="rowView" style="page-break-after:always;"> 
      <v-card-title>Vessels</v-card-title>
      <v-card-actions>
        <v-data-table 
          style="width:100%;height:100%;"
            fixed-header disable-pagination  hide-default-footer
            :headers="vesselStatusHead"
            :items="vesselStatusData"
            sort-by='avg_kpi'>
            <template v-slot:item.avg_kpi="{ item }">
              <div v-if="item.avg_kpi!=null&&item.avg_kpi!=''">
                  {{ item.avg_kpi.toFixed(1) }}
              </div>
              <div v-else>
                  -
              </div>
            </template>
            <template v-slot:item.min_kpi="{ item }">
              <div v-if="item.min_kpi!=null&&item.min_kpi!=''">
                  {{ item.min_kpi.toFixed(1) }} 
              </div>
              <div v-else>
                  -
              </div>
            </template>
            <template v-slot:item.max_kpi="{ item }">
              <div v-if="item.max_kpi!=null&&item.max_kpi!=''">
                  {{ item.max_kpi.toFixed(1) }} 
              </div>
              <div v-else>
                  -
              </div>
            </template>
          </v-data-table>
      </v-card-actions>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';   
import axios from "axios";
import Highcharts from "highcharts";
import Annot from 'highcharts/modules/annotations'


Annot(Highcharts)

export default {
  name: "GroupReportTimeline",
  data: function () {
    return {
      vesselStatusHead: [
        { text: 'Vessel', value: 'name' , class:"big-text titleBlack"},
        { text: 'Avg KPI %', value: 'avg_kpi', class:"big-text titleBlack" ,align: 'center'},
        { text: 'Min KPI %', value: 'min_kpi', class:"big-text titleBlack",align: 'center'},
        { text: 'Max KPI %', value: 'max_kpi', class:"big-text titleBlack" ,align: 'center'}
      ],
      vesselStatusData:[],
      sensorsStatusHead: [
        { text: 'Sensor', value: 'name' , class:"big-text titleBlack" },
        { text: 'Avg KPI %', value: 'avg', class:"big-text titleBlack" ,align: 'center', width:'120px'},
        { text: 'Min KPI %', value: 'min_kpi', class:"big-text titleBlack"},
        { text: 'Max KPI %', value: 'max_kpi', class:"big-text titleBlack"},
      ],
      sensorsStatusData:[],
      kpiHistory:{   
        credits:{
          enabled:false
        },
        time:{
          useUTC:true
        },       
        chart: {
          zoomType: 'xy'
        },
        title: {
          text: undefined
        },
        yAxis:{
          max:100,
          gridLineWidth: 1,
          title: {
            text:'[%]',
            align:'high'
          }
        },
        xAxis:{
          title:
          {
            text:undefined
          },
          type:'datetime',
          labels: {
            format: '{value: %e %b}'
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return new Date(this.x).toISOString().slice(0, 10) +
              ' : <b>' + this.y.toFixed(1) + ' %</b>';
          }
        },
        series: [
          {      
            type:'scatter',  
            data:[],
            marker:{
              enabled:true
            },
            animation: false,                        
            states: {
              hover: {
                  enabled: false
              },
              normal:{
                animation:false
              }, 
              inactive:{
                opacity: 1
              }
            }
          }
        ]
      }, 
      kpiRate:{   
        credits:{
          enabled:false
        },
        time:{
          useUTC:true
        },       
        chart: {
          zoomType: 'xy'
        },
        title: {
          text: undefined
        },
        yAxis:{
          gridLineWidth: 1,
          title: {
            text:'[%]',
            align:'high'
          }
        },
        xAxis:{
          title:
          {
            text:undefined
          },    
          type:'datetime',
          labels: {
            format: '{value: %e %b}'
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return new Date(this.x).toISOString().slice(0, 10) +
              ' : <b>' + this.y.toFixed(1) + ' %</b>';
          }
        },
        series: [
          {      
            type:'column',  
            data:[],
            marker:{
              enabled:true
            },
            animation: false,                        
            states: {
              hover: {
                  enabled: false
              },
              normal:{
                animation:false
              }, 
              inactive:{
                opacity: 1
              }
            }
          }
        ]
      }, 
      groupName:'',
      startDate:null,
      endDate:null
    }
  },
  methods: {
    setData(data) {

      this.vesselStatusData = Object.values(data.vessel_data);
      this.sensorsStatusData = Object.values(data.sorted_devices);

      Object.keys(data.trend).forEach(dt=>{
        let val = data.trend[dt],
          clr = '#FF4D4F';

        if (val>80) clr ='#23CE75';
        else if (val>50) clr = '#FFAA40';

        this.kpiHistory.series[0].data.push({
          x: new Date(dt).getTime(),
          y: val,
          color: clr
        });
      })

      this.kpiHistory.annotations = [{
        labelOptions: {
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          style: {
            fontSize: '13px'
          },       
          crop:true,
          shape: 'connector'
        },
        labels: [{   
          point: {
            x: new Date(data.min.date).getTime(),
            xAxis:0,
            y: data.min.score,
            yAxis:0
          },
          text: 'Min KPI : {y:.1f}'
        },
        {
          point: {
            x: new Date(data.max.date).getTime(),
            xAxis:0,
            y: data.max.score,
            yAxis:0
          },
          text: 'Max KPI : {y:.1f}'
        }]
      }]

      Object.keys(data.rates).forEach(dt=>{
        let val = data.rates[dt];

        this.kpiRate.series[0].data.push({
          x: new Date(dt).getTime(),
          y: val
        });
      })
    },
  },
  computed:{
    ...mapGetters(['getApiUrl']) 
  },
  watch: { 
    getApiUrl: {
      handler: async function() {

        this.startDate = this.$route.params.startDate;
        this.endDate = this.$route.params.endDate;        
        this.groupName = this.$route.params.groupName;

        let url;
        
        if (this.groupName == 'fleet') url = this.getApiUrl+'/fleet/'+this.startDate+'/'+this.endDate;
        else url = this.getApiUrl+'/groups/'+this.groupName+'/'+this.startDate+'/'+this.endDate;        

        const response  = await axios.get(url);

        this.setData(response.data.data);
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style scoped>

</style>