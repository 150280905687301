<template>
  <v-container class="containerView">

    <v-row class="rowView" style="height:55px;">

      <v-col class="colView" style="min-width:290px;">
        <v-menu v-model="dateMenu" :close-on-content-click="false" :close-on-click="false"
                :nudge-right="40" lazy transition="scale-transition" offset-y
                full-width min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field v-model="dateText"
                          label="Date range" prepend-icon="event" readonly
                          v-on="on" style="max-width:300px;padding-top:0px;"/>
          </template>
          <v-col :cols=12 class="rowView" style="background-color:white;">
            <v-row class="rowView">
              <v-col :cols=6 class="colView">
                <v-date-picker
                    v-model="startDate"
                    color="#29445c"
                    scrollable
                    event-color="#29445c"
                    :min="minDate"
                    :max="endDate"
                    :events="calcRange(startDate, endDate)"/>
              </v-col>
              <v-col :cols=6 class="colView">
                <v-date-picker
                    v-model="endDate"
                    color="#29445c" scrollable
                    event-color="#29445c"
                    :min="startDate"
                    :max="maxDate"
                    :events="calcRange(startDate, endDate)"/>
              </v-col>
            </v-row>
            <v-row class="rowView" style="justify-content:flex-end;">
              <v-btn style="margin:5px;" @click="dateMenu=false" text>OK</v-btn>
              <v-btn style="margin:5px;" @click="datePickerCancel" text>Cancel</v-btn>
            </v-row>
          </v-col>
        </v-menu>
      </v-col>
      <v-col class="colView" style="min-width: calc(100% - 360px);">
        <v-autocomplete v-model="selSignals" :items="availableSignals"
                        solo flat dense multiple chips deletable-chips hide-details
                        item-color="#638399"
                        color="#638399"
                        item-text="name" item-value="tag" item-disabled="disabled"
                        style="font-size:16px;margin:auto;"
                        :suffix="suffixText"/>
      </v-col>
      <v-spacer/>
      <v-btn style="margin:auto;" @click="fetchData()" text>OK</v-btn>
    </v-row>
    <v-divider/>
    <v-progress-linear
        :active="loading"
        color="#1890ff"
        indeterminate
        height="6"/>
    <v-row class="rowView" :style="[loading? {height:'calc(100% - 62px)' }:{height:'calc(100% - 56px)'}]">
      <!-- <v-row class="rowView" style="height:calc(100% - 45px);overflow:auto;"> -->
      <v-col :cols="12" class="colView" style="overflow:auto;">
        <v-row v-for="chart in signalData" :key="chart" style="background-color:white;"
               v-on:mousemove="updateCoordinates" :id="chart.tag">
          <highcharts :options="chart" :ref='chart.tag' style="width:100%;margin:12px;"></highcharts>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import Highcharts from 'highcharts';

export default {
  name: 'VesselData',
  components: {},
  computed: {
    ...mapGetters(['getVessels', 'getSignalMapping', 'getOverviewData', 'getSelIMO', 'getApiUrl', 'getLastUpdate']),
    dateText: function () {
      if ((this.startDate == null) || (this.endDate == null)) return null;
      else {
        return 'From ' + this.startDate + ' to ' + this.endDate;
      }
    },
    minDate: function () {
      let thresholdDate = new Date('2024-01-01'),
          minDate = new Date(this.endDate);

      if (this.endDate) {
        minDate.setTime(minDate.getTime() - 30 * 24 * 60 * 60 * 1000);

        if (minDate < thresholdDate) {
          minDate = thresholdDate;
        }
      } else {
        minDate = thresholdDate;
      }

      return minDate.toISOString().substring(0, 10);
    },
    maxDate: function () {
      let today = new Date(),
          maxDate = new Date(this.startDate);

      if (this.startDate) {
        maxDate.setTime(maxDate.getTime() + 30 * 24 * 60 * 60 * 1000);

        if (maxDate > today) {
          maxDate = today;
        }
      } else {
        maxDate = today;
      }

      return maxDate.toISOString().substring(0, 10);
    },
    suffixText: function () {
      return this.selSignals.length + '/' + this.maxSignals;
    }
  },
  data: function () {
    return {
      signalData: [],
      selSignals: [],
      availableSignals: [],
      dateMenu: false,
      startDate: null,
      endDate: null,
      tooltipPoints: [],
      loading: false,
      maxSignals: 5
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['fetchOverviewData']),
    calcRange(startDate, endDate) {
      let selArray = [];

      let currentDate = new Date(startDate);

      while (currentDate < new Date(endDate)) {
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        selArray.push(new Date(currentDate).toISOString().substring(0, 10));
      }

      selArray.pop();
      return selArray;
    },
    getLocalDateTime(currentDate) {
      var tzoffset = (new Date()).getTimezoneOffset() * 60000;
      var localISOTime = (new Date(currentDate - tzoffset)).toISOString().slice(0, 19).replace('T', ' ');
      var mySqlDT = localISOTime;
      return mySqlDT;
    },
    fetchData() {
      this.loading = true;
      this.signalData = [];
      let dataDict = {}, counter = 0, minX, maxX;

      let apiUrl = this.getApiUrl.split('//'),
          ws_protocol = (apiUrl[0].indexOf('https') > -1 ? 'wss' : 'ws');

      var socket = new WebSocket(`${ws_protocol}://${apiUrl[1]}/data/ws`)

      socket.onmessage = (event) => {
        var data = JSON.parse(event.data);
        if (data.success) {
          if (Object.keys(data).includes('can_request')) {
            socket.send(JSON.stringify({
              'imo': this.getSelIMO,
              'tags': this.selSignals,
              'from': this.startDate,
              'to': this.endDate + ' 23:59'
            }))
          }
        } else {
          counter++;

          if (this.signalData.length == 0) {
            this.selSignals.forEach(tag => {
              let sigMap = this.getSignalMapping.find(s => s.tag == tag);
              dataDict[tag] = new Array(data.max);

              var ser = {
                name: sigMap.name,
                type: 'line',
                data: [],
                marker: {
                  enabled: false
                },
                animation: false,
                states: {
                  hover: {
                    enabled: false
                  },
                  normal: {
                    animation: false
                  },
                  inactive: {
                    opacity: 1
                  }
                }
              };

              if (tag in data.records) {
                let entries = Object.entries(data.records[tag]).map(item => [new Date(item[0]).getTime(), item[1]]).sort((a, b) => a[0] - b[0]);

                dataDict[tag][data.index - 1] = entries;
                ser.data = entries;

                if ((data.index == 1) && ((minX == undefined) || (entries[0][0] < minX))) minX = entries[0][0];
                if ((data.index == data.max) && ((entries[entries.length - 1][0] > maxX) || (maxX == undefined))) maxX = entries[entries.length - 1][0];
              }

              this.signalData.push({
                tag: tag,
                credits: {
                  enabled: false
                },
                time: {
                  useUTC: true
                },
                chart: {
                  zoomType: 'xy',
                  height: 200
                },
                tooltip: {
                  shared: true,
                  formatter: this.tooltipFormatter,
                  hideDelay: 100,
                  shape: 'rect'
                },
                title: {
                  text: sigMap.name,
                  align: 'right',
                  x: -10
                },
                yAxis: {
                  gridLineWidth: 2,
                  title: {
                    text: '[' + sigMap.unit + ']',
                    align: 'high'
                  }
                },
                xAxis: {
                  type: 'datetime',
                  title:
                      {
                        text: undefined
                      },
                  labels: {
                    format: '{value:%H:%M<br>%Y-%m-%d}',
                    step: 2
                  },
                  events: {
                    afterSetExtremes: this.afterSetExtremes
                  },
                  crosshair: {
                    enabled: true,
                    width: 1,
                    color: '#595959'
                  }
                },
                legend: {
                  enabled: false
                },
                series: [ser]
              });

            })

          } else {
            data.tags.forEach(tag => {
              if (tag in data.records) {

                let entries = Object.entries(data.records[tag]).map(item => [new Date(item[0]).getTime(), item[1]]).sort((a, b) => a[0] - b[0]);

                dataDict[tag][data.index - 1] = entries;

                if ((data.index == 1) && ((minX == undefined) || (entries[0][0] < minX))) minX = entries[0][0];
                if ((data.index == data.max) && ((entries[entries.length - 1][0] > maxX) || (maxX == undefined))) maxX = entries[entries.length - 1][0];

                let ser = this.signalData.find(item => item.tag == tag).series[0];

                ser.data = dataDict[tag].flat();
              }

            })
          }

          if (counter == data.max) {
            let diff = (maxX - minX) / 10,
                hourMS = 3600 * 1000,
                interval;

            interval = (((diff / hourMS) > 1) ? Math.round(diff / hourMS) * hourMS : Math.round(diff));

            this.signalData.forEach(item => {
              item.xAxis.min = minX;
              item.xAxis.max = maxX;
            });

            socket.close();

            this.loading = false;
          }
        }
      }

      this.loading = true;
    },
    afterSetExtremes(e) {
      this.signalData.forEach(item => {
        let chart = this.$refs[item.tag][0].chart,
            extremes = chart.xAxis[0].getExtremes();

        if ((e.min != extremes.min) && (e.max != extremes.max)) {
          chart.xAxis[0].setExtremes(e.min, e.max);

          let diff = (e.max - e.min) / 10,
              hourMS = 3600 * 1000, interval;

          interval = ((diff / hourMS) > 1 ? Math.round(diff / hourMS) * hourMS : Math.round(diff));

          //  item.xAxis.tickInterval = interval;
        }
      })
    },
    updateCoordinates(e) {
      this.tooltipPoints = [];

      this.signalData.forEach(item => {
        let chart = this.$refs[item.tag][0].chart,
            ev = chart.pointer.normalize(e),
            point = chart.series[0].searchPoint(ev, true);

        chart.xAxis[0].drawCrosshair(e, point);

        this.tooltipPoints.push(point);
      });
    },
    tooltipFormatter(tooltip) {
      let a = '';
      var d = new Date(this.tooltipPoints[0].x).toISOString().replace("T", " ")
      d = d.substring(0, d.length - 8);
      a += '<br>  ' + d + '<br>';

      this.tooltipPoints.forEach(element => {
        a += '<br> <b>' + element.series.name + ' </b>: ' + element.y + '<br>';
      });
      return a;
    },
    datePickerCancel() {
      this.dateMenu=false
      this.startDate = null
      this.endDate = null
    }
  },
  watch: {
    getSignalMapping: {
      handler: function () {
        this.availableSignals = this.getSignalMapping.filter(item => item.tag != null).map(item => {
          item.disabled = false;
          return item;
        })
      },
      deep: true,
      immediate: true
    },
    getLastUpdate: {
      handler: function () {
        this.selSignals = ["me_rpm", "me_shaft_power", "sog", "stw"];

        this.endDate = this.getLastUpdate.substring(0, 10);
        this.startDate = this.getLastUpdate.substring(0, 10);

        this.fetchData();
      },
      deep: true,
      immediate: true
    },
    selSignals: {
      handler: function () {
        if (this.selSignals.length == this.maxSignals) {
          this.availableSignals.forEach(item => {
            item.disabled = !(this.selSignals.includes(item.tag));
          })
        } else this.availableSignals.forEach(item => item.disabled = false)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.v-text-field {
  padding: 0px;
  margin: 2px;
}

.v-select.v-input--dense .v-chip {
  background-color: white;
  color: #638399;
}

.v-chip .v-chip__close.v-icon {
  color: #638399;
}

/* 
.v-autocomplete__content .v-list .v-list-item--active {
  background-color:#98C9EA;
} */

</style>

<style scoped>


</style>
