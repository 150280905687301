import { render, staticRenderFns } from "./VesselData.vue?vue&type=template&id=4e7c0674&scoped=true"
import script from "./VesselData.vue?vue&type=script&lang=js"
export * from "./VesselData.vue?vue&type=script&lang=js"
import style0 from "./VesselData.vue?vue&type=style&index=0&id=4e7c0674&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e7c0674",
  null
  
)

export default component.exports