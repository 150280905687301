<template>
  <v-app>
    <TopBar v-show="!reportRoute && authorised"/>
    <SideBar v-show="!reportRoute && authorised"/>
    <div>
      <div
          v-if="getVesselError.length"
          style="
            position: fixed;
            top: 0;
            left: 200px;
            width: calc(100% - 200px);
            height: 100%;
            background-color: rgba(0,0,0, 0.5);
            z-index: 99;
          "
      >
        <v-layout row flex align-center justify-center style="height: 100%;">
          <div style="background-color: white">
            <v-alert
                text
                type="error"
                style="margin-bottom: 0"
            >
              <!--              No data where found for Sapphira-->
              {{ getVesselError }}
            </v-alert>
          </div>
        </v-layout>
      </div>
      <router-view v-if="authorised" :class="reportRoute ? 'reportView' : 'routerView'"/>
      <router-view v-else class="routerView" style="width: 100%; height: 100%; left: 0; top: 0;"/>
    </div>
  </v-app>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import axios from 'axios';
import SideBar from './controls/SideBar'
import TopBar from './controls/TopBar'

import {msalService} from '@/useAuth'

export default {
  name: 'App',
  components: {
    SideBar, TopBar
  },
  data: () => ({
    checkUserInterval: null
  }),
  computed: {
    ...mapGetters([
      'getIndex',
      'getSelIMO',
      'getSelGroup',
      'getSelPeriod',
      'getGroups',
      'getVessels',
      'getApiUrl',
      'getAdminData',
      'getFleetOverview',
      'getVesselError',
      'getAuthorised',
      'getAzureAppId',
      'getAzureRedUri',
      'getMSALInstance'
    ]),
    reportRoute: function () {
      return !!this.$route.name.includes('Report');
    },
    authorised: function () {
      return !!this.getAuthorised
    },
    redirectLogin: function () {
      return this.$route.name.toLowerCase() != 'login' && !this.authorised;
    },
    activeAccount: function () {
      return this.getActiveAccount();
    }
  },
  methods: {
    ...mapActions([
      'fetchIndex',
      'fetchVesselOverview',
      'fetchFleetOverview',
      'fetchGroupOverview',
      'fetchSignalData',
      'fetchAdminData',
      'fetchApiUrl',
      'fetchAdminApiUrl',
      'fetchInfoDict',
      'fetchSelIMO',
      'fetchSelGroup',
      'fetchIssuesCategories',
      'fetchIssuesData',
      'fetchLastUpdate',
      'setAuthorised',
      'getActiveAccount',
      'setAzureIdToken',
      'getToken',
      'setAzureAppId',
      'setAzureRedUri',
      'setMSALInstance',
      'setAPIToken',
      'setAuthHeaders',
      'logout',
    ]),
    getData() {
      if (!this.authorised) {
        return null
      }
      let routeName = this.$route.name;

      if (this.getApiUrl == '') return;

      if (this.getSelIMO != null) {
        let data = {
          imo: this.getSelIMO,
          period: this.getSelPeriod
        };
        this.fetchVesselOverview(data);
      }

      switch (routeName) {
        case 'fleet':
        case 'fleetReport':
          this.fetchFleetOverview();
          break;
        case 'group':
        case 'groupReport':
          if (this.getSelGroup != null) {
            this.fetchGroupOverview(this.getSelGroup);
          }
          break;
        case 'vesseldetails':
          if (this.getSelIMO != null) {
            this.fetchSignalData(this.getSelIMO);
          }
          break;
        case 'vesselissues':
          if (this.getSelIMO != null) {
            this.fetchIssuesCategories();
          }
          break;
        case 'timelineReport':
          if (this.getSelIMO != null) {
            this.fetchIssuesData();
          }
          break;
      }

      if (Object.keys(this.getAdminData).length == 0) {
        this.fetchAdminData();
      }
    },
    getCookie(cname) {
      let first_split = decodeURIComponent(document.cookie).split(cname + "=")[1]

      if (typeof first_split !== 'undefined') {
        return first_split.split(';')[0]
      }

      return null
    }
  },
  created() {
    axios.get('/config.json').then(async (response) => {

      let apiPost = response.data.api_postfix;
      let adminPost = response.data.admin_postfix;

      let apiUrl, adminUrl, wsUrl;

      if ('api_source' in response.data) {
        apiUrl = response.data.api_source;
        adminUrl = response.data.admin_source;
      } else {
        apiUrl = location.protocol + '//' + location.hostname;
        adminUrl = location.protocol + '//' + location.hostname;
      }

      this.fetchApiUrl(apiUrl + ':' + apiPost);
      this.fetchAdminApiUrl(adminUrl + ':' + adminPost);

      this.fetchInfoDict(response.data.infoDict);
      this.setAzureAppId(response.data.azure_app_id);
      this.setAzureRedUri(response.data.azure_red_uri);
    });
  },
  watch: {
    getSelGroup: {
      handler: function () {
        if (this.getSelGroup != null) {
          this.getData();
        }
      },
      deep: true,
      immediate: true
    },
    getFleetOverview: {
      handler: function () {
      },
      deep: true,
      immediate: true
    },
    getSelIMO: {
      handler: function () {
        if (this.getSelIMO != null) {
          this.getData();
        }
      },
      deep: true,
      immediate: true
    },
    getSelPeriod: {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true
    },
    '$route.params': {
      handler: function () {

        let params = this.$route.params;

        if ('vesselName' in params) {
          let vessel = this.getVessels.find(item => item.vessel == params.vesselName);

          if (vessel != 'undefined' && vessel != null) {
            let imo = this.getVessels.find(item => item.vessel == params.vesselName).imo;

            if (imo != this.getSelIMO) {
              this.fetchSelIMO(imo);
            } else {
              this.getData();
            }
          } else {
            this.getData();
          }
        } else if ('groupName' in params) {
          this.fetchSelGroup(params.groupName);
          this.fetchSelIMO(null);
        } else {
          this.fetchSelGroup(null);
          this.fetchSelIMO(null);
          this.getData();
        }
      },
      deep: true,
      immediate: true
    },
    getApiUrl: {
      handler: function () {
        if (this.getApiUrl == '') return;

        let routeName = this.$route.name;

        if ((routeName != 'fleet') && (routeName != 'fleetReport')) {
          this.fetchFleetOverview();
        }

        this.getData();
      },
      deep: true,
      immediate: true
    },
    getVessels: {
      handler: function () {
        let params = this.$route.params;

        if ('vesselName' in params) {
          let vessel = this.getVessels.find(item => item.vessel == params.vesselName);

          if (vessel != 'undefined' && vessel != null) {
            let imo = vessel.imo;

            if (imo != this.getSelIMO) {
              this.fetchSelIMO(imo);
            }
          } else {
            this.getData();
          }
        } else {
          this.getData();
        }
      },
      deep: true,
      immediate: true
    },
    redirectLogin: {
      handler: async function () {
        let activeAccount = await this.activeAccount;
        let invalid_url = this.$route.name.toLowerCase() != 'login' && this.$route.name.toLowerCase().indexOf('report') < 0
        if (this.redirectLogin && typeof activeAccount == 'undefined' && invalid_url) {
          this.$router.push({name: 'login'});
        }
      },
      deep: true,
      immediate: true
    },
    activeAccount: {
      handler: async function (value) {
        await value

        if (this.authorised && this.$route.name.toLowerCase() == 'login') {
          this.$router.push({name: 'fleet'});
        }
      },
      deep: true,
      immediate: true
    },
    authorised: {
      handler: async function () {
        if (this.authorised) {
          if (this.$route.name.toLowerCase() == 'login') {
            this.$router.push({name: 'fleet'});
          }

          try {
            clearInterval(this.checkUserInterval);
          } catch (e) {
          }

          this.checkUserInterval = setInterval(async () => {
            let token = this.getCookie('azure_jwt'),
                api_token = this.getCookie('api_jwt');

            if (token == null || token === '' || api_token == null || api_token === '') {
              await this.logout();

              try {
                clearInterval(this.checkUserInterval);
              } catch (e) {
              }
            }
          }, 10000)
        }
      },
      deep: true,
      immediate: true
    },
    getAzureRedUri: function (newValue, oldValue) {
      if (newValue.length && this.getAzureAppId.length) {
        this.setMSALInstance(msalService(this.getAzureAppId, newValue))
      }
    },
    getMSALInstance: function () {
      let token = this.getCookie('azure_jwt');

      if (token != null) {
        this.setAzureIdToken(token);

        let api_token = this.getCookie('api_jwt');

        if (api_token != null) {
          this.setAPIToken(api_token);
          this.setAuthHeaders(api_token);
        } else {
          this.logout();
        }
      } else {
        this.logout();
      }
    }
  }
};
</script>

<style>
@import './assets/fdqmiStyle.css';

.routerView {
  width: calc(100% - 200px);
  height: calc(100% - 70px);
  position: absolute;
  left: 200px;
  top: 70px;
  background-color: #f2f8fc;
}

.reportView {
  width: 100%;
  height: 100%;
  /* position: absolute;
  left: 200px;
  top:70px; */
  background-color: #f2f8fc;
}
</style>