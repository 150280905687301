<template> 
    <v-tooltip bottom color="#DBE7F0">
        <template v-slot:activator="{ on, attrs }">
            <v-icon size="24" color="#638399" style="cursor: pointer;" 
            v-bind="attrs"
            v-on="on">
            mdi-information-outline
            </v-icon>
        </template>
            <span v-html="html"></span>
    </v-tooltip>
</template>

<script>

    export default {
        name: "InfoHover",
        components: {
        },
        props: {
            html:String
        },
        data: function () {
            return {
            }
        },
        methods: {
        },
        mounted() {
        }, 
        watch:
        {
        },
        computed:{
        }
    }
</script>

<style scoped>

</style>

