import {PublicClientApplication} from '@azure/msal-browser'
import { reactive } from 'vue'

export const msalConfig = {
    auth: {
        // clientId: '0ff5f9e5-4cc3-4c39-8d79-9e7da8a0602d',
        clientId: '27477ab0-bedd-4580-8091-0e13ffa168a4',
        authority: 'https://login.microsoftonline.com/organizations',
        redirectUri: 'http://localhost:8080'
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false
    },
    "scopes": {
        "scopes": [],
        "prompt": "select_account"
    },
    "group": "57841541-13c7-4923-bea3-790f4d33dd79",
    "guard": {
        "login": "/login",
        "home": "/"
    }
}

export const graphScopes = {
    scopes: ['user.read']
}
export const state = reactive({
    isAuthenticated: false,
    user: null
})

export const msalInstance = new PublicClientApplication(msalConfig)

export const getCustomInstance = function (azureAppId, azureRedUri) {
    let newMSALConfig = Object.assign({}, msalConfig);


    newMSALConfig.auth.clientId = azureAppId;
    newMSALConfig.auth.redirectUri = azureRedUri;

    return new PublicClientApplication(newMSALConfig)
}