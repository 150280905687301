<template>
  <div style="display: flex; flex-direction: column; align-items: center">
    <svg
        :style="`width: ${size}`"
        version="1.0"
        viewBox="0 0 1691.2 532.25"
        xmlns="http://www.w3.org/2000/svg"
        zoomAndPan="magnify"
    >
      <text
          x="0"
          y="401"
          style="
          fill-opacity: 1;
          fill: #000000;
          font-size: 260px;
          stroke-linecap: round;
          stroke-opacity: 1;
          stroke-width: 0.75118;
        "
          xml:space="preserve"
      >
        <tspan
            x="10"
            y="202"
            style="
            fill-opacity: 0.99608;
            fill: #000000;
            font-family: 'Agency FB';
            font-size: 260px;
            stroke-opacity: 0.99608;
            stroke-width: 0.75118;
          "
        >
          Propulsion Analytics
        </tspan>
      </text>
      <path
          d="m16.674 294.66c1.4454-1.6381 14.947-15.476 33.421-28.868 18.565-13.458 40.857-25.414 73.714-28.112 31.674-2.7476 62.396 16.172 98.876 37.713 44.084 26.031 78.577 44.81 119.43 44.521 70.533-0.38543 161.53-77.07 246.13-76.492 48.671-0.18534 98.038 17.223 147.84 36.208s98.691 38.971 141.47 39.645c42.397 0 65.852-15.996 90.415-32.593 24.563-16.598 47.922-34.183 87.814-36.592 44.152-1.311 92.133 16.999 141.58 35.178 49.445 18.18 102.67 35.266 150.36 34.399 38.616-0.43796 68.992-4.389 127.92-13.539 33.061-5.1338 79.919-16.568 113.63-25.83 33.71-9.2621 70.022-24.611 82.346-35.6"
          style="
          fill: none;
          stroke-linecap: square;
          stroke-opacity: 1;
          stroke-width: 22.5;
          stroke: #4880bd;
        "
      />
      <text
          x="27"
          y="401"
          style="
          fill-opacity: 1;
          fill: #000000;
          font-size: 260px;
          stroke-linecap: round;
          stroke-opacity: 1;
          stroke-width: 0.75118;
        "
          xml:space="preserve"
      >
        <tspan
            x="400"
            y="451"
            style="
            fill-opacity: 1;
            fill: #000000;
            font-family: 'Calibri';
            font-size: 100px;
            stroke-opacity: 1;
            stroke-width: 0.75118;
          "
        >
<!--            :style="`fill: ${theme.current.text.primary}`"-->
          extracting meaning from data
        </tspan>
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "PropanLogo",
  props: {
    size: {
      type: String,
      default: "100%",
    },
  }
};
</script>

<style scoped>
h1 {
  font-family: "Agency FB", sans-serif;
  letter-spacing: 0.15rem;
  font-weight: 500;
}

h2 {
  font-family: "Calibri", sans-serif;
  letter-spacing: 0.03rem;
  font-weight: 300;
  place-self: flex-end;
}
</style>
