import Vue from 'vue'
import Router from 'vue-router'

import Login from './views/Login.vue'

import FleetDashboard from './views/FleetDashboard.vue'
import FleetAlerts from './views/FleetAlerts.vue'
import GroupDashboard from './views/GroupDashboard.vue'
import VesselDashboard from './views/VesselDashboard.vue'
import VesselDetails from './views/VesselDetails.vue'
import VesselIssues from './views/VesselIssues.vue'
import VesselData from './views/VesselData.vue'

import AdminVessels from './views/AdminVessels.vue'
import AdminSignals from './views/AdminSignals.vue'
import AdminMethods from './views/AdminMethods.vue'
import AdminAlerts from './views/AdminAlerts.vue'

import FleetReport from './views/FleetReport.vue'
import GroupReport from './views/GroupReport.vue'
import VesselReport from './views/VesselReport.vue'
import GroupReportTimeline from "./views/GroupReportTimeline";
import VesselReportTimeline from "./views/VesselReportTimeline";

Vue.use(Router)

const router = new Router({
    mode: "history",
    routes: [
        {
            path: '/fleet',
            name: 'fleet',
            component: FleetDashboard
        },
        {
            path: '/fleet/alerts',
            name: 'fleetalerts',
            component: FleetAlerts
        },
        {
            path: '/group/:groupName',
            name: 'group',
            component: GroupDashboard
        },
        {
            path: '/vessel/:vesselName',
            name: 'vessel',
            component: VesselDashboard
        },
        {
            path: '/vessel/:vesselName/details',
            name: 'vesseldetails',
            component: VesselDetails
        },
        {
            path: '/vessel/:vesselName/issues',
            name: 'vesselissues',
            component: VesselIssues
        },
        {
            path: '/vessel/:vesselName/data',
            name: 'vesseldata',
            component: VesselData
        },
        {
            path: '/admin/vessels',
            name: 'adminVessels',
            component: AdminVessels
        },
        {
            path: '/admin/signals',
            name: 'adminSignals',
            component: AdminSignals
        },
        {
            path: '/admin/methods',
            name: 'adminMethods',
            component: AdminMethods
        }, {
            path: '/admin/alerts',
            name: 'adminAlerts',
            component: AdminAlerts
        },
        {
            path: '/report/fleet',
            name: 'fleetReport',
            component: FleetReport
        },
        {
            path: '/report/group/:groupName',
            name: 'groupReport',
            component: GroupReport
        },
        {
            path: '/report/group/timeline/:groupName/:startDate/:endDate/',
            name: 'groupReportTimeline',
            component: GroupReportTimeline
        },
        {
            path: '/report/vessel/:vesselName',
            name: 'vesselReport',
            component: VesselReport
        },
        {
            path: '/report/vessel/timeline/:vesselName/:startDate/:endDate/:requestId?',
            name: 'vesselReportTimeline',
            component: VesselReportTimeline
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/',
            redirect: '/login'
        },
    ]
})

export default router;