<template>
   <v-container class="containerView">
    <v-row class="rowView" style="height:100%;">
      <v-col :cols=12 class="colView">
        <v-row class="rowView" style="height:45px;">          
          <v-spacer/>
          <v-text-field
            v-model="search"
            style="max-width:450px;padding-top:0px;"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            color='#1890ff'
          ></v-text-field>
          <v-btn outlined color="#16304E" style="margin:auto 10px;" @click="openPopup('addEditPopup','add', '')">
            Add New      
            <v-icon right>
              mdi-plus
            </v-icon>
          </v-btn>
          
        </v-row>
        <v-divider/>
        <v-row class="rowView" style="height:calc(100% - 45px);" ref="parentDiv" v-resize="updateDataTableHeight">
          <v-data-table   
            style="height:100%;margin:5px;width:100%;"     
            :height="dataTableHeight"      
            fixed-header disable-pagination  hide-default-footer 
            :headers="headers" :items="items"
            :search="search"
            :calculate-widths="true">
            <template v-slot:item.actions="{ item }">
              <v-icon color="#1890ff" :disabled="!item.actions.edit" style="margin:5px;" @click="openPopup('addEditPopup','edit', item.id)">
                mdi-pencil
              </v-icon>
              <v-icon right color="#FF4D4F" :disabled="!item.actions.delete" style="margin:5px;" @click="openPopup('deletePopup', 'delete', item.id)">
                mdi-close
              </v-icon>
              <!-- <v-icon color="#809dbe" :disabled="!item.actions.showVessels" style="margin:5px;" @click="showVessels(item.id)">
                mdi-ferry
              </v-icon> -->
            </template>
            <template v-slot:item.isActive="{ item }">
              <v-checkbox
                style="width:24px;height:24px;margin:auto 0px;"
                :input-value="item.isActive"
                value
                disabled
              ></v-checkbox>
            </template>
          </v-data-table>
        </v-row>
            
      </v-col>
    </v-row> 
    <v-dialog v-model="addEditPopup" width="800" persistent style="max-height: none;">
      <v-card outlined rounded="lg">
        <v-card-title  v-if="popUpMode=='edit'">{{selSignal.name}}</v-card-title>
        <v-card-title  v-else>Add a new signal</v-card-title>
        <v-card-actions>
          <v-container class="containerView" :key="redraw" > 
            <v-row class="rowView" >                
              <v-col :cols="4" class="colView"> 
                <v-row class="rowView" style="height:85px;" v-for="item in dropDown" :key="item">
                  <v-col :cols="12" class="rowView">  
                    <div class="big-text subtleBlue">{{item.title}}</div>              
                    <v-autocomplete v-model="item.model" :items="item.options" 
                      :class="item.errorInput ? 'errorInput' : ''" 
                      :persistent-hint="item.errorInput" :hint="item.errorMsg"
                      item-text="text" item-value="value" 
                      outlined flat dense   
                      style="font-size:14px;"/>
                  </v-col>   
                </v-row>               
              </v-col>
              <v-col :cols="4" class="colView" v-for="n in 2" :key="n">
                <v-row class="rowView" style="height:85px;" v-for="item in columnInputs(n,2)" :key="item">
                  <v-col :cols="12" class="rowView">  
                    <div class="big-text subtleBlue">{{item.title}}</div>                   
                    <v-text-field v-model="item.model"  outlined dense 
                      :class="item.errorInput ? 'errorInput' : ''" 
                      :persistent-hint="item.errorInput" :hint="item.errorMsg"
                      style="font-size:14px;"/> 
                  </v-col>   
                </v-row>
              </v-col>
            </v-row>          
           
            <v-row class="rowView" style="height:45px;">
              <v-spacer/>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="addEditSignal()">
                OK
              </v-btn>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="addEditPopup=false">
                Cancel
              </v-btn>

            </v-row>
          </v-container>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletePopup" width="600" persistent>
      <v-card v-if="selSignal!=null">
        <v-card-title>
          Are you sure you want to delete {{selSignal.name}} ?
        </v-card-title>
        <v-card-actions>
          <v-row class="rowView" style="height:45px;">
            <v-spacer/>
            <v-btn dark color="#1890ff" style="margin:5px;" @click="deleteSignal()">
              OK
            </v-btn>
            <v-btn dark color="#1890ff" style="margin:5px;" @click="deletePopup=false">
              Cancel
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="vesselsPopup" width="800">
      <v-card v-if="selSignal!=null">
        <v-card-title>
          Vessels with {{selSignal.name}}
        </v-card-title>
        <v-card-actions>
          <v-data-table style="width:100%;height:100%;"
            height="500"
            fixed-header disable-pagination hide-default-footer       
            :headers="selSignalHead"
            :items="selSignalData"
            sort-by="name"
            @click:row="vesselClick"
            @current-items="current">
            <!-- <template v-slot:item.index="{ item }">
              {{ selSignalData.indexOf(item) +1}}
            </template> -->
            <template v-slot:item.completeness="{ item }">
              <div v-if="item.completeness!=-100">
                  {{ item.completeness.toFixed(1) }}
              </div>
              <div v-else>
                  -
              </div>
            </template>
            <template v-slot:item.quality="{ item }">
              <div v-if="item.quality!=-100">
                  {{ item.quality.toFixed(1) }} 
              </div>
              <div v-else>
                  -
              </div>
            </template>
          </v-data-table>              
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loginPopup" width="400" persistent>
      <v-card>
        <v-card-title>
          Admin Password
        </v-card-title>
        <v-card-actions>
          <v-col class="colView">
            <v-row class="rowView">
              <v-text-field 
                required v-model="password"
                outlined dense :type="pwdShow ? 'text' : 'password'"
                style="font-size:14px;"
                :class="pwdError ? 'errorInput' : ''" 
                :persistent-hint="pwdError" :hint="pwdMsg"
                :append-icon="pwdShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="pwdShow = !pwdShow"
                @keydown.enter="login()"/> 
            </v-row>
            <v-row class="rowView" style="height:45px;">
              <v-spacer/>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="login()">
                Submit
              </v-btn>
              <v-btn dark color="#1890ff" style="margin:5px;" @click="leaveAdmin()">
                Cancel
              </v-btn>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
      
    </v-dialog>
  </v-container>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';   
import Highcharts from 'highcharts'
import axios from 'axios'

export default {
  name: 'AdminSignals',
  components: {
  },  
  data: function () {    
    return { 
      password:null,
      pwdError:false,
      pwdMsg:'',
      pwdShow:false,  
      loginPopup:true,
      redraw:0,   
      dataTableHeight:100,
      search:'',
      fieldTitles:{
        'interval': 'Interval',
        'intervalUnit': 'Interval Unit',
        'location': 'Location',
        'max': 'Max Value',
        'min': 'Min Value',
        'name': 'Name',
        'severity': 'Severity',
        // 'source':'Source',
        'signalTypeId':'Type',
        'systemId': 'System',
        'type':'Type',
        'system': 'System',
        'tag': 'Tag',
        'tier': 'Tier',
        'unit': 'Unit'
      },
      headerTags:['name','tag','unit','system','location','severity','tier', 'type'],
      headers:[], 
      items:[],
      deletePopup:false,
      addEditPopup:false,
      vesselsPopup:false,
      popUpMode:'',
      selSignal:null,
      dropDown:[
        {
          field:'severity',
          model:null,
          options:[
            {text:'High', value:'High'},
            {text:'Medium', value:'Medium'},             
            {text:'Low', value:'Low'}
          ], 
          errorInput:false
        },
        {
          field:'tier',
          model:null,
          options:[
            {text:1, value:1},
            {text:2, value:2},
            {text:3, value:3}
          ], 
          errorInput:false
        },
        {
          field:'signalTypeId',
          optionsTag:'signal_types',
          model:null,
          options:[], 
          errorInput:false
        },
        {
          field:'systemId',
          optionsTag:'systems',
          model:null,
          options:[], 
          errorInput:false
        }
      ],
      inputTags:['name','tag', 'unit','interval','intervalUnit','max','min'],
      input:[],
      selSignalHead: [
        {
          value: 'index',
          text: '#',
          class:"big-text titleBlack"
        },
        { 
          text: 'VESSEL',
          value: 'name', 
          class:"big-text titleBlack"
        },
        { 
          text: 'COMPLETENESS %',
          value: 'completeness',
          class:"big-text titleBlack"
        },
        { 
          text: 'QUALITY %',
          value: 'quality', 
          class:"big-text titleBlack"
        },
        { 
          text: 'LAST UPDATE',
          value: 'dateFormatted', 
          class:"big-text titleBlack"
        }
      ],
      selSignalData:[]
    }
  },  
  computed:{
    ...mapGetters(['getVessels','getAdminData','getAdminApiUrl','getApiUrl']),
  },
  mounted(){
    if (this.$cookies.get("adminLogged")) this.loginPopup = false;
  
  },
  methods:{
    ...mapActions(['fetchAdminData','fetchSignalId']),  
    current(e) {

      let tmp = e.map(t=>t.name);

      this.selSignalData.forEach(vess=>{
        vess.index = tmp.indexOf(vess.name) +1;
      })
      
    }, 
    updateDataTableHeight () {
      this.dataTableHeight = this.$refs['parentDiv'].clientHeight;    
    },
    columnInputs(column, columns) {
      const total = this.input.length;
      const step = Math.ceil(total / columns);

      let top = (step * column);
      const bottom = top - step;

      return this.input.filter(item =>
        this.input.indexOf(item) >= bottom
        && this.input.indexOf(item) < top,
      ); 
    },
    vesselClick(data)
    {
      this.fetchSignalId(this.selSignal.id);
      this.$router.push({ name:'vesseldetails', params: {'vesselName':data.name} });
    },
    async showVessels(id)
    {
      document.body.style.cursor = 'progress';

      if (id!='') this.selSignal = this.items.find(item=>item.id == id);      

      const response = await axios.get(this.getApiUrl+'/signals/'+this.selSignal.tag+'/vessels');

      this.selSignalData = response.data.data.results;
      
      this.selSignalData.forEach(item=>{

        let vessMap = this.getAdminData.vessels.find(v=>v.imo == item.imo);

        item.name = vessMap.name;

        item.dateFormatted = item.date.substring(0,16).replace('T',' ');
      })        

      this.vesselsPopup = true;
      
      document.body.style.cursor = 'default';
    },
    openPopup(model, mode, id)
    {
      if (id!='') this.selSignal = this.items.find(item=>item.id == id);

      if (mode=='add')
      {
        this.dropDown.forEach(item=>{
          item.model = null;
          item.errorInput = false;
          item.errorMsg = '';
        })
          
        this.input.forEach(item=>{
          item.model = null;
          item.errorInput = false;
          item.errorMsg = '';
        })        
      }
      else if (mode=='edit')
      {
        this.dropDown.forEach(item=>{
          item.model = this.selSignal[item.field];
          item.errorInput =  false;
          item.errorMsg = '';
        })
        
        this.input.forEach(item=>{
          item.model = this.selSignal[item.field];
          item.errorInput = false;
          item.errorMsg = '';
        })
      }

      this.popUpMode = mode;

      this[model] = true;    
    },
    async addEditSignal()
    {
      if (this.popUpMode=='edit')
      {
        let postData = {};
        
        let selId = this.selSignal.id;

        this.dropDown.forEach(item=>{
          postData[item.field] = item.model;
       //   this.selSignal[item.field] = item.model;
        })
          
        this.input.forEach(item=>{
          postData[item.field] = item.model;
        //  this.selSignal[item.field] = item.model;
        }) 
         

        const response = await axios.post(this.getAdminApiUrl+'/signals/update/'+selId, postData);
        
        if (response.data.success) 
        { 
          this.fetchAdminData();
          this.addEditPopup = false;
        }
        else
        {
          this.setErrorMessages(response.data.data);
        }
      }
      else 
      {
        let postData = {}; 
        
        this.dropDown.forEach(item=>{
          postData[item.field] = item.model;
        })
          
        this.input.forEach(item=>{
          postData[item.field] = item.model;
        })

        const response = await axios.post(this.getAdminApiUrl+'/signals/create', postData);

        if (response.data.success) 
        { 
          this.fetchAdminData();
          this.addEditPopup = false;
        }
        else
        {
          this.setErrorMessages(response.data.data);
        }

      }

      
    },
    async deleteSignal()
    {
      let selId = this.selSignal.id;
      
      const response = await axios.post(this.getAdminApiUrl+'/signals/delete/'+selId, {});

      if (response.data.success) {
        this.fetchAdminData();        
        this.deletePopup = false;
      }
    },
    setErrorMessages(errorDict) {
      this.dropDown.forEach(item=>{
        if (item.field in errorDict) {
          item.errorInput = true;
          let msg = errorDict[item.field];

          if (msg == 'required') item.errorMsg ='This field is required.'
        } else {
          item.errorInput = false;
          item.errorMsg ='';
        } 
      })

      this.input.forEach(item=>{
        
        if (item.field in errorDict)
        {
          item.errorInput = true;

          let msg = errorDict[item.field];

          if (msg == 'required') item.errorMsg ='This field is required.'
          else item.errorMsg = 'The value of this field should be a '+msg+'.'
        }
        else
        {
          item.errorInput = false;
          item.errorMsg ='';
        } 
      })
      
      this.redraw++;
     
    },
    async login()
    {
      const response = await axios.post(this.getAdminApiUrl+'/login', 
      {
        password: this.password
      });

      if (response.data.success)
      {        
        this.$cookies.set("adminLogged", true, "1d");
        this.loginPopup = false;
      }
      else
      {
        this.pwdError = true;
        this.pwdMsg = "The password you submited is wrong.";
      }

    },
    leaveAdmin()
    {
       this.$router.push({ name: 'fleet'});
    },
  }, 
  watch: {  
    getAdminData: {
      handler: function() {
        this.headers =[];
        
        this.headers =this.headerTags.map(item=>{
          return { text: this.fieldTitles[item], value: item, class:['big-text', 'titleBlack', 'headerClass']};//, width: this.fieldTitles[item].length*10 + 40};
        });

        this.headers.splice(0,0,{ text: "", value: 'actions', class:['big-text', 'titleBlack'], align:'right', width:80}); 

        this.items =  this.getAdminData.signals.map(item=>{ 
          item.actions=
          {
            edit:true,
            delete:true,
            showVessels:true
          };
          if (item.isActive == 0) item.isActive = false;
          else item.isActive = true;
          return item;
        })

        this.dropDown.forEach(item=>{
          item.title = this.fieldTitles[item.field];

          if ('optionsTag' in item)
            item.options = this.getAdminData[item.optionsTag].map(op=>{return {'text':op.name, 'value':op.id }});
        })

        this.input = [];

        this.inputTags.forEach(tag=>{

          this.input.push({
            field:tag,
            title:this.fieldTitles[tag],
            model:null,
            errorInput:false
          });          
        })
      },
      deep: true,
      immediate: true
    },
    selSignal: {
      handler: function() {
        
      },
      deep: true,
      immediate: true
    }    
  }
}
</script>

<style scoped>

.errorInput >>> .v-messages__message
{
  color: #FF4D4F;
  font-size:14px;
  padding:2px;
  font-weight:500;
}

.errorInput.v-text-field--outlined >>> fieldset {
  border-color: #FF4D4F;
}

.v-input--selection-controls {
  margin-top: 0px; 
  padding-top: 0px;
  margin-left:8px;
}

.v-text-field {
  margin: auto; 
  padding-top: 0px;
}

</style>
