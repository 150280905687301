<template>
  <v-data-table
      :height="dataTableHeight" fixed-header disable-pagination hide-default-footer
      :headers="headers"
      :items="items"
      item-key="id"
      :single-expand="singleExpand"
      :expanded="expanded"
      :show-expand="expand"
      @click:row="rowClick"
      @item-expanded="expandChange"
      :search="search">
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" style=" background-color:white;">
        <v-progress-linear
            :active="item.loading"
            indeterminate
            color="#1890ff"
            height="6"/>
        <!-- <v-row style="height:300px;" class="rowView"> -->
        <v-row class="rowView">
          <v-col :cols=3 class="colView">
            <v-row class="rowView titleBlack main-title">{{ item.issue }}</v-row>
            <v-row v-for="pr in properties" style="padding:5px; width:100%; margin:0px;" :key="pr">
              <v-row class="subtleBlue sub-title rowView" style="align-items: center;">{{ pr.title }}</v-row>
              <v-row class="labelLightBlack big-text rowView" style="align-items: center;">{{ item[pr.tag] }}</v-row>
            </v-row>
          </v-col>
          <v-divider vertical/>
          <v-col :cols=9 class="colView" style="max-width:74%">
            <v-row class="rowView" v-on:dblclick="openPopUp(item)">
              <highcharts style="width:100%;height:100%;" :options="item.chartOptions"></highcharts>
            </v-row>
          </v-col>
        </v-row>
      </td>
    </template>

  </v-data-table>
</template>

<script>

import Highcharts from 'highcharts'
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "IssueTable",
  props: {
    headers: Array,
    items: Array,
    expand: Boolean,
    dataTableHeight: Number,
    search: String
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      properties: [
        {title: 'Description', tag: 'description'},
        {title: 'Signals', tag: 'signalsView'},
        {title: 'From', tag: 'startDate'},
        {title: 'To', tag: 'endDate'}
      ],
      chartDict: {
        'time series of four parameters and one reference line': 'ref',
        'time series of one parameter and one error - reference line': 'errorRef',
        'time series of one parameter and one reference line': 'ref',
        'time series of one parameter and two limit lines': 'limits',
        'time series of three parameter': 'woRef',//'multiParam',
        'time series of two parameters': 'woRef',//'multiParam',
        'time series of two parameters and one reference line': 'ref'
      }
    }
  },
  computed: {
    ...mapGetters(['getIssueDetails', 'getSelIMO', 'getSignalMapping', 'getApiUrl', 'getIssueId']),
    itemLoad: function () {
      if (this.items.length == 0) return false;
      else return true;
    }
  },
  methods: {
    ...mapActions(['fetchIssueDetails', 'fetchIssueId']),
    rowClick(data, item) {
      if (item.isExpanded) item.expand(false);
      else item.expand(true);
    },
    openPopUp(item) {
      this.$emit('openChartPopup', JSON.parse(JSON.stringify(item)));
    },
    getRowData(rowItem) {
      let dataDict = {};
      let counter = 0;

      let chartType = this.chartDict[rowItem.figures];

      let tags;

      if (chartType == undefined) chartType = 'limits';

      if (Object.keys(rowItem.chartOptions).length == 0) {
        rowItem.chartOptions = {
          credits: {
            enabled: false
          },
          time: {
            useUTC: false
          },
          chart: {
            zoomType: 'xy',
            height: 400,
            events: {
              load() {
              }
            }
          },
          title: {
            text: undefined
          },
          yAxis: {
            title:
                {
                  text: undefined,
                  align: 'high'
                }
          },
          xAxis: {
            title:
                {
                  text: undefined
                },
            type: 'datetime',
            labels: {
              format: '{value: %H:%M}'
            },
            crosshair: true
          },
          tooltip: {
            formatter: function () {
              var s = '<b>' + new Date(this.x).toISOString().substring(0, 16).replace('T', ' ') + '</b>',
                  actTime = this.x, allSeries = [];

              if (this.points) {
                allSeries = this.points[0].series.chart.series
              } else {
                allSeries = this.point.series.chart.series;
              }

              allSeries.forEach(series => {
                var diff = series.closestPointRange, actPoint = null;

                series.data.forEach(point => {
                  let temp = Math.abs(actTime - point.x)

                  if ((diff == null) || (temp < diff)) {
                    actPoint = point;
                    diff = temp;
                  }
                });

                if (actPoint != null)
                  s += '<br/> ' + actPoint.series.name + ': ' + actPoint.y.toFixed(2);
              });
              return s;
            },
            shared: true,
            crosshairs: true
          },
          legend: {
            enabled: true
          },
          series: []
        };

        if (chartType == 'multiParam') {
          rowItem.chartOptions.yAxis = [];
        }

        rowItem.loading = true;

        let apiUrl = this.getApiUrl.split('//'),
            ws_protocol = (apiUrl[0].indexOf('https') > -1 ? 'wss' : 'ws');

        var socket = new WebSocket(`${ws_protocol}://${apiUrl[1]}/issues/ws`)

        socket.onmessage = (event) => {
          var data = JSON.parse(event.data);

          if (data.success) {
            if (Object.keys(data).includes('can_request')) {
              socket.send(JSON.stringify({
                'imo': this.getSelIMO,
                'issueId': rowItem.ids[0]
              }))
            } else if (Object.keys(data).includes('completed')) {
              //close
            }
          } else {
            if ('tags' in data) {
              tags = data.tags;

              tags.forEach((tag, index) => {
                let sigMap = this.getSignalMapping.find(s => s.tag == tag.tag), title;

                if (sigMap != undefined) {
                  title = sigMap.name + ' [' + sigMap.unit + ']';
                } else {
                  title = tag.title;
                }

                let ser = {
                  name: title,
                  type: 'line',
                  tag: tag,
                  data: [],
                  marker: {
                    enabled: false,//true,
                    radius: 2
                  },
                  animation: false,
                  states: {
                    hover: {
                      enabled: false
                    },
                    normal: {
                      animation: false
                    },
                    inactive: {
                      opacity: 1
                    }
                  }
                };

                if (tag.type == 'lim')
                  ser.color = '#FF4D4F';

                if (tag.type == 'prediction') {
                  ser.marker.enabled = true;
                  ser.type = 'scatter';
                  ser.marker.symbol = 'circle';
                }

                let axis = {
                  gridLineWidth: 2,
                  title: {
                    text: title,
                    align: 'high',
                    style: {
                      color: Highcharts.getOptions().colors[index]
                    }
                  }
                }

                if (chartType == 'multiParam') {
                  ser.yAxis = index;
                  rowItem.chartOptions.yAxis.push(axis);
                } else {
                  if (sigMap != undefined && !tag.axis.length) {
                    rowItem.chartOptions.yAxis.title.text = '[' + sigMap.unit + ']';
                  } else if (tag.axis.length) {
                    rowItem.chartOptions.yAxis.title.text = '[' + tag.axis + ']';
                  }
                  rowItem.chartOptions.yAxis.title.align = 'high';
                }

                rowItem.chartOptions.series.push(ser);
              })
            } else {
              counter++;

              if (!('from' in data))
                rowItem.chartOptions.xAxis.labels.format = '{value:%d-%m-%Y}';

              tags.forEach((tag, index) => {
                if (!(tag.tag in dataDict))
                  dataDict[tag.tag] = new Array(data.max);

                if (tag.tag in data.records) {
                  let entries = Object
                      .entries(data.records[tag.tag])
                      .map(item => {
                        let time = (new Date(item[1][0])).getTime(),
                            value = null;

                        if (item[1][1] != null) {
                          value = Math.round(item[1][1] * 1000) / 1000
                        }

                        return [time, value]
                      })
                      .sort((a, b) => a[0] - b[0]);

                  dataDict[tag.tag][data.index - 1] = entries.filter(item => item != undefined && !Number.isNaN(item[0]));


                  let ser = rowItem.chartOptions.series.find(item => item.tag == tag);

                  ser.data = dataDict[tag.tag].flat();
                }
              })

              if (counter == data.max) {
                socket.close();
                rowItem.loading = false;
              }
            }
          }
        }
      }
    },
    expandChange(data) {
      if (data.value) {
        this.getRowData(data.item);
      }
    },
    scrollParentToChild(parent, child) {
      var parentRect = parent.getBoundingClientRect();
      var parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth
      };

      var childRect = child.getBoundingClientRect();
      var isViewable = (childRect.top >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height);
      if (!isViewable) {
        parent.scrollTop = (childRect.top + parent.scrollTop) - parentRect.top
      }
    }
  },
  watch: {
    itemLoad: {
      handler: function () {
        if (this.itemLoad) {
          let selIssue = this.items.find(i => i.ids.includes(this.getIssueId));

          if (selIssue != undefined) {
            this.expanded.push(selIssue);

            this.getRowData(selIssue);

            setTimeout(() => {
              var row = document.getElementsByClassName('v-data-table__expanded__row')[0];

              var parent = document.getElementsByClassName('v-data-table__wrapper')[0];

              this.scrollParentToChild(parent, row);

              this.fetchIssueId(null);

            }, 100)

          }

        }


      },
      deep: true,
      immediate: true
    },
    getIssueId: {
      handler: function () {
        if (this.itemLoad) {
          let selIssue = this.items.find(i => i.ids.includes(this.getIssueId));

          if (selIssue != undefined) {
            this.expanded.push(selIssue);

            this.getRowData(selIssue);

            setTimeout(() => {
              var row = document.getElementsByClassName('v-data-table__expanded__row')[0];

              var parent = document.getElementsByClassName('v-data-table__wrapper')[0];

              this.scrollParentToChild(parent, row);

              this.fetchIssueId(null);

            }, 100)

          }

        }


      },
      deep: true,
      immediate: true
    },
    '$route.params': {
      handler: function () {
        this.expanded = [];

      },
      deep: true,
      immediate: true
    }

  }
}
</script>

<style scoped>
</style>

<style>

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px;
  color: rgba(0, 0, 0, .85);
}

/*
.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper){ */

.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover {
  background: rgba(24, 144, 255, 0.2) !important;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded {
  background-color: rgba(24, 144, 255, 0.2)
}

</style>