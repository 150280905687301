<template> 
    <v-card outlined rounded="lg" :style="'border: 1px solid '+cardColor+';'">          
        <v-card-title :style="'color:'+cardColor+''">{{cardData.title}}</v-card-title>
        <v-card-actions  :style="'color:'+cardColor+''">
           {{cardData.value}}
        </v-card-actions>
    </v-card>
</template>

<script>

    export default {
        name: "SingleValCard",
        components: {
        },
        props: {
            cardData:Object
        },
        data: function () {
            return {
            }
        },
        methods: {
        },
        mounted() {
        }, 
        watch:
        {
        },
        computed:{
            cardColor: function () { 
                if (this.cardData.color==0) return '#638399';//'#595959'; //'#16304E';
                else if (this.cardData.color==10) return  '#FFAA40';
                else return '#FF4D4F';
            }
        }
    }
</script>

<style scoped>
.v-card__title
{
    padding:5px;    
}
.v-card__actions
{
    padding:5px;
    font-size: 60px;
    font-weight:500;
}
.row
{
    width:100%;
    margin:0px;
}
.col
{
    padding:0px;
}
</style>

