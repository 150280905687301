<template>
  <v-container class="containerView">    
    <v-progress-linear
      :active="loading"
      color="#1890ff"
      indeterminate              
      height="6"/>
    <v-row class="rowView" :style="[loading? {height:'calc(100% - 6px)'}:{height:'100%'}]">
      <v-col :cols=4 style="padding:0px; height: 100%;">
        <v-row class="rowView" style="height:50%">
          <v-col :cols="12" class="colView">            
            <v-card outlined rounded="lg">
              <v-card-title>
                KPI
                <v-spacer/>
                <v-spacer/>
                <InfoHover :html="getInfoDict['groupKPI']"/>
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="24" color="#638399" style="cursor: pointer;" 
                      v-bind="attrs"
                      v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>  65% High Severity <br/> 30% Medium Severity <br/> 5% Low Severity</span>
                </v-tooltip> -->
              </v-card-title>
              <v-card-text style="max-height: 479px;">
                <highcharts :options="kpiGauge" />
<!--                <highcharts :options="quadKpi" />-->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="rowView" style="height:50%;">
          <v-col :cols="12" class="colView">            
            <v-card outlined rounded="lg">
              <v-card-title>Equipment Failures</v-card-title>
              <v-card-actions  ref="equipDiv" v-resize="updateEquipTableHeight" >
                <v-col class="rowView">
                  <v-data-table style="width:100%;"
                    fixed-header disable-pagination hide-default-footer   
                    @click:row="equipClick"  
                    :height="equipTableHeight"             
                    :headers="topEquipHead"
                    :items="topEquipData">
                    <template v-slot:item.completeness="{ item }">
                      <div v-if="item.completeness!=''">
                          {{ item.completeness.toFixed(1) }}
                      </div>
                      <div v-else>
                          -
                      </div>
                    </template>
                    <template v-slot:item.quality="{ item }">
                      <div v-if="item.quality!=''">
                          {{ item.quality.toFixed(1) }} 
                      </div>
                      <div v-else>
                          -
                      </div>
                    </template>
                  </v-data-table>  
                  <v-row style="margin:5px 0px;">
                    <v-spacer/>
                    <v-btn small text @click="equipBtnClick()">
                      {{equipBtnText}}
                    </v-btn>
                  </v-row>  
                </v-col>             
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols=8 style="padding:0px;">
        <v-row class="rowView" style="height:100%;">
          <v-col :cols="12" class="colView">            
            <v-card outlined rounded="lg" ref="parentDiv" v-resize="updateDataTableHeight" >
              <v-card-title>
                <v-text-field
                  v-model="search"
                  style="max-width:450px;padding-top:0px;"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  color='#1890ff'
                ></v-text-field>
                <v-spacer/>
                <InfoHover :html="getInfoDict['GroupGrid']"/>
              </v-card-title>
              <v-card-actions >
                <v-data-table style="width:100%;height:100%;"
                  fixed-header disable-pagination  hide-default-footer  
                  :height="dataTableHeight"      
                  :headers="vesselStatusHead"
                  :items="vesselStatusData"
                  sort-by='kpi'
                  :sort-desc=false
                  :search="search"
                  @click:row="navToVessel">
                  <template v-slot:item.status="{ item }">
                    <div  class="statusCircle" :style="'background-color: '+getStatusColor(item.status)+';'"></div>                    
                  </template>
                  <template v-slot:item.onFast="{ item }">
                    <v-checkbox :input-value="item.onFast" value disabled class="ma-auto pa-0" style="height:24px;width:24px;"/>
                  </template>
                  <template v-slot:item.kpiTrend="{ item }"> 
                    <div v-if="item.kpiTrend==undefined">
                      -
                    </div>     
                    <div v-else>
                      {{item.kpiTrend}}                 
                      <v-icon v-if="item.kpiTrend>0" size="32" color="#23CE75" style="margin-left:5px;">mdi-trending-up</v-icon>
                      <v-icon v-else-if="item.kpiTrend<0" size="32" color="#FF4D4F" style="margin-left:5px;">mdi-trending-down</v-icon>
                    </div>                    
                  </template>
                  <template v-slot:item.lastUpdate="{ item }">
                    <div :style="[item.overdueUpdate ? {'color': '#FF4D4F'}:{} ]">
                      {{item.lastUpdate}}                
                      <!-- <v-icon v-if="item.overdueUpdate" size="32" color="#FF4D4F" style="margin-left:5px;">mdi-update</v-icon> -->
                    </div>                  
                  </template>
                </v-data-table>
              </v-card-actions>
              
            </v-card>
          </v-col>
        </v-row>
      </v-col>      
    </v-row>
    <v-dialog v-model="equipPopup" width="600">
      <v-card v-if="selEquip!=null">
        <v-card-title>
          {{selEquip.name}}
        </v-card-title>
        <v-card-actions>
          <v-data-table style="width:100%;height:100%;"
            height="500"
            fixed-header disable-pagination hide-default-footer   
            @click:row="vesselClick"               
            :headers="selEquipHead"
            :items="selEquip.vessels"
            @current-items="current">
            <template v-slot:item.completeness="{ item }">
              <div v-if="item.completeness!='-'">
                  {{ item.completeness.toFixed(1) }}
              </div>
              <div v-else>
                  -
              </div>
            </template>
            <template v-slot:item.quality="{ item }">
              <div v-if="item.quality!='-'">
                  {{ item.quality.toFixed(1) }} 
              </div>
              <div v-else>
                  -
              </div>
            </template>
          </v-data-table>              
        </v-card-actions>
      </v-card>   
    </v-dialog>
  </v-container>
 
</template>

<script>
/* eslint-disable vue/no-unused-components */

import { mapGetters, mapActions } from 'vuex';   
import SingleValCard from '../controls/SingleValCard';
import Highcharts from 'highcharts'
import InfoHover from '../controls/InfoHover';


export default {
  name: 'GroupDashboard',
  components: {
    SingleValCard,
    InfoHover
  },
  data: function () {
    return {
      topEquipHead: [
        { 
          text: 'SENSOR',
          value: 'name', 
          class:"big-text titleBlack"
        },
        { 
          text: 'COMPLETENESS %',
          value: 'completeness',
          class:"big-text titleBlack",
          width:'170px'
        },
        { 
          text: 'QUALITY %',
          value: 'quality', 
          class:"big-text titleBlack",
          width:'120px'
        }
      ],
      topEquipData: [],
      selEquipHead: [
        {
          value: 'index',
          text: '#',
          class:"big-text titleBlack"
        },
        { 
          text: 'VESSEL',
          value: 'name', 
          class:"big-text titleBlack"
        },
        { 
          text: 'COMPLETENESS %',
          value: 'completeness',
          class:"big-text titleBlack"
        },
        { 
          text: 'QUALITY %',
          value: 'quality', 
          class:"big-text titleBlack"
        }
      ],
      selEquipData: [],
      equipPopup:false,
      search:'',
      kpiGauge:{
        chart: {
          type: 'solidgauge',
          height: '60%',
        },          
        credits:{
          enabled:false
        },
        title: {
          text: undefined
        },
        pane: 
        {
          startAngle: -180,
          endAngle: 180,
          background: [{
              outerRadius: '100%',
              innerRadius: '85%',
              borderWidth: 0
          }]
        },
        tooltip: {
          enabled:false
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: []
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: true
            },
            linecap: 'round',
            stickyTracking: false,
            rounded: true
          }
        },
        series: []
      },
      quadKpi: {
        chart: {
          type: 'bar',
          height: '10%',
        },
        credits: {
          enabled: false
        },
        title: {
          text: undefined
        },
        tooltip: {
          enabled: false
        },
        series: [
          {
            name: 'QUAD',
            data: [50],
            // label: 'QUAD'
          }
        ],
        yAxis: {
          min: 0,
          max: 100,
          labels: {
            enabled: false
          },
          title: {
            enabled: false
          }
        },
        xAxis: {
          labels: {
            format: 'QUAD',
            // enabled: false
          }
        },
        legend: {
          enabled: false
        },
      },
      vesselStatusHead: [
        { text: 'STATUS', value: 'status' , class:"big-text titleBlack" ,align: 'center'},
        { text: 'VESSEL', value: 'vessel', class:"big-text titleBlack"},
        { text: 'KPI %', value: 'kpi', class:"big-text titleBlack" ,align: 'center'},
        { text: 'KPI TREND %', value: 'kpiTrend', class:"big-text titleBlack" ,align: 'center'},
        { text: 'ISSUES', value: 'issues', class:"big-text titleBlack" },
        { text: 'LAST UPDATE', value: 'lastUpdate', class:"big-text titleBlack" },
        // { text: 'FAST', value: 'onFast', class:"big-text titleBlack" , align: 'center', sortable:false}
      ],
      vesselStatusData:[],
      dataTableHeight:100,
      equipTableHeight:100,
      equipBtnText:'More',
      loading:true,
      selEquip:null
    }
  },  
  computed:{
    ...mapGetters(['getGroupOverview', 'getVessels', 'getInfoDict'])
  },
  methods:{
    ...mapActions(['fetchFilterParameters']),
    current(e) {

      let tmp = e.map(t=>t.name);

      this.selEquip.vessels.forEach(vess=>{
        vess.index = tmp.indexOf(vess.name) +1;
      })
      
    },
    equipBtnClick() {
      if (this.equipBtnText=='More')
      {
        this.topEquipData = this.getGroupOverview.devices;

        this.equipBtnText = 'Less';
      }
      else 
      {
        this.topEquipData = this.getGroupOverview.devices.slice(0,5);

        this.equipBtnText = 'More';
      }
    },
    updateEquipTableHeight () {
      this.equipTableHeight =this.$refs['equipDiv'].clientHeight -45;
    },
    updateDataTableHeight () {
      this.dataTableHeight =this.$refs['parentDiv'].$children[2].$el.clientHeight ;
    },
    getStatusColor(status){
      if (status==20) return '#FF4D4F';
      else if (status==10) return '#FFAA40';
      else return '#23CE75'; 
    },
    navToVessel(item)
    {
      let paramItem={
        'vesselName':item.vessel
      };

      this.$router.push({ name: 'vessel', params: paramItem });
    },
    equipClick(data, item)
    {
      this.selEquip = data;
      this.equipPopup = true;
    },
    vesselClick(data, item)
    {
      let postData = 
      {
        'status':'All',
        'completeness':[],
        'quality':[],
        'tier':'',
        'severity':'',
        'location':'',
        'active':false,
        'missing':false,
        'sensor':this.selEquip.name
      };

      this.fetchFilterParameters(postData);
      this.$router.push({ name:'vesseldetails', params: {'vesselName':data.name} });
    }
  },  
  mounted(){
  },
  watch:{    
    getGroupOverview(){
      if (this.getGroupOverview.length==0) return;
      
      this.kpiGauge.series=[];

      let kpiVal = this.getGroupOverview.kpi;//map(item=>item.severity.weighted_kpi).reduce((a,b) => a + b, 0) / this.getGroupOverview.length;
      let kpiColor= '#FF4D4F';

      if (kpiVal>80) kpiColor ='#23CE75';
      else if (kpiVal>50) kpiColor = '#FFAA40';

      this.kpiGauge.pane.background[0].backgroundColor = Highcharts.color(kpiColor).setOpacity(0.3).get();

      this.kpiGauge.series.push({
        name: 'KPI',
        data: [{
          color: kpiColor,
          radius: '100%',
          innerRadius: '85%',
          y:  Math.round(kpiVal * 10) / 10 
        }],
        dataLabels: {             
          y: -40,
          borderWidth: 0,
          useHTML:true,
          format: '<div style="text-align:center;"><span style="font-size: 60px;color:'+kpiColor+';">{y}%</span></div>'
        },
      })

      // let quadKPI = (this.getGroupOverview.application_kpis.quad.completeness + this.getGroupOverview.application_kpis.quad.quality) / 2,
      //     quadKPIColor = ((quadKPI > 80) ? quadKPIColor = '#23CE75' : ((quadKPI > 50) ? quadKPIColor = '#FFAA40' : '#FF4D4F'));
      //
      // this.quadKpi.xAxis.labels.format = `QUAD (${quadKPI.toPrecision(3)}%)`
      // this.quadKpi.series[0].data = [quadKPI];
      // this.quadKpi.series[0].color = quadKPIColor;
      this.vesselStatusData=[];

      Object.keys(this.getGroupOverview.vessels).forEach(vessIMO=>{
        let vessel = this.getVessels.find(v=>v.imo == vessIMO);
        let vessData = this.getGroupOverview.vessels[vessIMO];

        let status;

        let temp = new Date(vessData.last_date);
        let ts = '';
        let overdueUpdate = false, diff = (new Date()-temp)/(60*60*1000) ;

        if (diff>24) overdueUpdate = true;
        
        if (vessData.last_date!=null)
          ts = temp.toISOString().slice(0,10)+' '+temp.toISOString().slice(11,16)+ ' UTC';
        
        let vessKPI = vessData.kpi;

        if (vessKPI>80) status =0;
        else if (vessKPI>50) status = 10;
        else status = 20;
        
        let diffKPI = vessData.kpi_diff;

        if (diffKPI!=undefined) diffKPI = Math.round(diffKPI*100)/100;

        let row = 
        {
          status:status,
          vessel:vessel.vessel,
          kpi:Math.round(vessKPI*10)/10,
          kpiTrend:diffKPI,
          issues:vessData.issues,
          lastUpdate:ts, 
          overdueUpdate: overdueUpdate,
          onFast:vessel.onFast
        };

        this.vesselStatusData.push(row);
      })

      this.topEquipData = [];      

      this.getGroupOverview.devices.forEach(item=>{
        let q = item.quality, c = item.completeness;

        if (q=='') q=0;

        item.score = 0.5*q+0.5*c;

        item.vessels.forEach(vess=>
        {
          let tempVess = this.getVessels.find(v=>v.imo == vess.imo);
          vess.name = tempVess.vessel; 
        })
      })

      this.getGroupOverview.devices.sort((a,b) => (a.score > b.score) ? 1 : ((b.score > a.score) ? -1 : 0));


      this.topEquipData = this.getGroupOverview.devices.slice(0,5);

      this.equipBtnText = 'More';
      
      this.loading = false;

    }
  }
}
</script>

<style scoped>

table.v-table tbody td {
  font-size: 18px !important;
}

.statusCircle
{
  height:24px;              
  width:24px;
  border-radius:50%;
  display:inline-block;
  margin:auto;
}
</style>
