<template>
  <v-container class="containerView" style="background-color:white;max-width:200mm !important;">
    <v-row class="rowView">
      <v-col :cols=6 class="colView">
        <v-row class="subtleBlue main-title rowView" style="align-items: center;">{{ vesselName }}</v-row>
        <v-row class="labelLightBlack sub-title rowView" style="align-items: center;">{{ startDate }} to {{ endDate }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="rowView" v-for="metric in metrics" :key="metric" style="margin-top:10px;">
      <v-card-title>{{ metric.label }} Trend</v-card-title>
      <v-card-actions>
        <highcharts style="width:100%;height:100%;" v-if="historyCharts[metric.tag]"
                    :options="historyCharts[metric.tag]"/>
      </v-card-actions>
    </v-row>
    <v-row class="rowView" style="page-break-before:always;">
      <v-card-title> Signals with KPI &#60; 85%</v-card-title>
      <v-row style="margin-left:12px;">
        <div v-for="filter in filters" :key="filter" class="big-text subtleBlue"
             style="margin:2px 5px; padding: 2px 5px;border: 1px solid; border-radius:7px; ">{{ filter }}
        </div>
      </v-row>
      <v-card-actions>
        <v-data-table
            style="width:100%;height:100%;"
            fixed-header disable-pagination hide-default-footer
            :headers="signalsStatusHead"
            :items="signalsStatusData"
            sort-by='kpi'>
          <template v-slot:item.kpi="{ item }">
            <div v-if="item.kpi!=null&&item.kpi!=''">
              {{ item.kpi.toFixed(1) }}<br/>({{ item.min_kpi.toFixed(1) }} - {{ item.max_kpi.toFixed(1) }})
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.completeness="{ item }">
            <div v-if="item.completeness!=null&&item.completeness!=''">
              {{ item.completeness.toFixed(1) }}<br/>({{ item.min_completeness.toFixed(1) }} -
              {{ item.max_completeness.toFixed(1) }})
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.quality="{ item }">
            <div v-if="item.quality!=null&&item.quality!=''">
              {{ item.quality.toFixed(1) }}<br/>({{ item.min_quality.toFixed(1) }} - {{ item.max_quality.toFixed(1) }})
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <div
                :style="'height:16px;width:16px;border-radius:50%;display:inline-block;background-color:'+getStatusColor(item.kpi)+';'"/>
          </template>
        </v-data-table>
      </v-card-actions>
    </v-row>
    <v-row class="rowView" style="page-break-before:always;">
      <v-card-title>Issues with most appearances in this period</v-card-title>
      <v-card-actions>
        <v-data-table
            style="width:100%;height:100%;"
            fixed-header disable-pagination hide-default-footer
            :headers="issuesHead"
            :items="issuesData"
            sort-by='kpi'>
          <template v-slot:item.tlChart="{ item }">
            <highcharts style="width:300px;" :options="item.tlChart"/>
          </template>
        </v-data-table>
      </v-card-actions>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import axios from "axios";
import Highcharts from "highcharts";
import Annot from 'highcharts/modules/annotations'


Annot(Highcharts)

export default {
  name: "VesselReportTimeline",
  data: function () {
    return {
      metrics: [
        {label: 'KPI', historyTag: 'kpi_trend', tag: 'kpi'},
        {label: 'Quality', historyTag: 'trend_q', tag: 'q'},
        {label: 'Completeness', historyTag: 'trend_c', tag: 'c'}
      ],
      historyCharts: {},
      kpiRate: {
        credits: {
          enabled: false
        },
        time: {
          useUTC: true
        },
        chart: {
          zoomType: 'xy'
        },
        title: {
          text: undefined
        },
        yAxis: {
          gridLineWidth: 1,
          title: {
            text: '[%]',
            align: 'high'
          }
        },
        xAxis: {
          title:
              {
                text: undefined
              },
          type: 'datetime',
          labels: {
            format: '{value: %e %b}'
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return new Date(this.x).toISOString().slice(0, 10) +
                ' : <b>' + this.y.toFixed(1) + ' %</b>';
          }
        },
        series: [
          {
            type: 'column',
            data: [],
            marker: {
              enabled: true
            },
            animation: false,
            states: {
              hover: {
                enabled: false
              },
              normal: {
                animation: false
              },
              inactive: {
                opacity: 1
              }
            }
          }
        ]
      },
      signalsStatusHead: [
        {text: 'Status', value: 'status', class: "big-text titleBlack", align: 'center', width: '80px'},
        {text: 'Signal', value: 'name', class: "big-text titleBlack"},
        // { text: 'Tag', value: 'tag' , class:"big-text titleBlack" },
        {text: 'KPI %', value: 'kpi', class: "big-text titleBlack", align: 'center', width: '110px'},
        {text: 'Completeness %', value: 'completeness', class: "big-text titleBlack", align: 'center', width: '160px'},
        {text: 'Quality %', value: 'quality', class: "big-text titleBlack", align: 'center', width: '120px'},
      ],
      signalsStatusData: [],
      issuesHead: [
        {text: 'Issue', value: 'issue', class: "big-text titleBlack"},
        {text: 'Signal', value: 'signal', class: "big-text titleBlack"},
        //{ text: 'Duration', value: 'duration', class:"big-text titleBlack"},
        {text: 'Timeline', value: 'tlChart', class: "big-text titleBlack"}
      ],
      issuesData: [],
      vesselName: '',
      startDate: null,
      endDate: null,
      filters: []
    }
  },
  methods: {
    setData(data) {

      this.historyCharts = {};

      this.metrics.forEach(metric => {

        this.historyCharts[metric.tag] = {
          credits: {
            enabled: false
          },
          time: {
            useUTC: true
          },
          chart: {
            height: 250,
            zoomType: 'xy'
          },
          title: {
            text: undefined
          },
          yAxis: {
            max: 100,
            gridLineWidth: 1,
            title: {
              text: '[%]',
              align: 'high'
            }
          },
          xAxis: {
            title:
                {
                  text: undefined
                },
            type: 'datetime',
            labels: {
              format: '{value: %e %b}'
            }
          },
          legend: {
            enabled: false
          },
          tooltip: {
            formatter: function () {
              return new Date(this.x).toISOString().slice(0, 10) +
                  ' : <b>' + this.y.toFixed(1) + ' %</b>';
            }
          },
          series: [
            {
              type: 'scatter',
              data: [],
              marker: {
                enabled: true
              },
              animation: false,
              states: {
                hover: {
                  enabled: false
                },
                normal: {
                  animation: false
                },
                inactive: {
                  opacity: 1
                }
              }
            }
          ]
        };

        Object.keys(data[metric.historyTag]).forEach(dt => {
          let val = data[metric.historyTag][dt],
              clr = '#FF4D4F';

          if (val > 80) clr = '#23CE75';
          else if (val > 50) clr = '#FFAA40';

          this.historyCharts[metric.tag].series[0].data.push({
            x: new Date(dt).getTime(),
            y: val,
            color: clr
          });
        })

        this.historyCharts[metric.tag].annotations = [{
          labelOptions: {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            style: {
              fontSize: '13px'
            },
            crop: true,
            shape: 'connector'
          },
          labels: [{
            point: {
              x: new Date(data['min_' + metric.tag].date).getTime(),
              xAxis: 0,
              y: data['min_' + metric.tag][metric.label.toLowerCase()],
              yAxis: 0
            },
            text: 'Min ' + metric.label + ' : {y:.1f}'
          },
            {
              point: {
                x: new Date(data['max_' + metric.tag].date).getTime(),
                xAxis: 0,
                y: data['max_' + metric.tag][metric.label.toLowerCase()],
                yAxis: 0
              },
              text: 'Max ' + metric.label + ' : {y:.1f}'
            }]
        }]

      })

      this.signalsStatusData = [];

      data.signal_def.forEach(sig => {
        let avgSig = data.avg_sigs[sig.tag];

        if (avgSig != undefined)
          this.signalsStatusData.push({
            name: sig.name,
            tag: sig.tag,
            kpi: avgSig.kpi,
            min_kpi: data.min_sigs[sig.tag].kpi,
            max_kpi: data.max_sigs[sig.tag].kpi,
            completeness: avgSig.completeness,
            min_completeness: data.min_c_sigs[sig.tag].completeness,
            max_completeness: data.max_c_sigs[sig.tag].completeness,
            quality: avgSig.quality,
            min_quality: data.min_q_sigs[sig.tag].quality,
            max_quality: data.max_q_sigs[sig.tag].quality,
          })

      })

      this.issuesData = [];

      Object.values(data.issues).forEach(item => {

        let sigName = data.signal_def.find(sig => sig.tag == item.tag);

        let chData = [];
        let x = new Date(this.startDate).getTime(), endX = new Date(this.endDate).getTime(), day = 24 * 60 * 60 * 1000;

        while (x <= endX) {
          let key = new Date(x).toISOString().substring(0, 10);

          if (key in item.days) chData.push({x: x, y: Math.round(item.days[key] / 3600)});
          else chData.push({x: x, y: 0});
          x += day;
        }

        let chart = {
          credits: {
            enabled: false
          },
          time: {
            useUTC: true
          },
          chart: {
            height: 80,
            spacingBottom: 0
          },
          title: {
            text: undefined
          },
          yAxis: {
            max: 24,
            title: {
              text: '[hr]',
              align: 'high'
            }
          },
          xAxis: {
            title:
                {
                  text: undefined
                },
            type: 'datetime',
            labels: {
              enabled: true,
              format: '{value: %e %b}'
            }
          },
          legend: {
            enabled: false
          },
          series: [
            {
              type: 'column',
              data: chData,
              step: 'right',
              marker: {
                enabled: false
              },
              animation: false,
              states: {
                hover: {
                  enabled: false
                },
                normal: {
                  animation: false
                },
                inactive: {
                  opacity: 1
                }
              }
            }
          ]
        };

        this.issuesData.push({
          issue: item.name,
          signal: sigName.name,//item.tag,
          duration: item.duration_display,
          tlChart: chart
        })
      })

      this.filters = [];

      let keys = Object.keys(data.filters);

      if (keys.length > 0) {
        keys.forEach(key => {
          data.filters[key].forEach(val => this.filters.push(key.charAt(0).toUpperCase() + key.slice(1) + ': ' + val))
        })
      }


    },
    getStatusColor(kpi) {
      if (kpi <= 50) return '#FF4D4F';
      else return '#FFAA40';
    },
  },
  computed: {
    ...mapGetters(['getApiUrl'])
  },
  watch: {
    getApiUrl: {
      handler: async function () {
        this.startDate = this.$route.params.startDate;
        this.endDate = this.$route.params.endDate;

        this.vesselName = this.$route.params.vesselName;

        let url = this.getApiUrl + '/vessels/' + this.vesselName + '/' + this.startDate + '/' + this.endDate;

        if (this.$route.params.requestId != undefined)
          url += '/' + this.$route.params.requestId;

        const response = await axios.get(url);

        this.setData(response.data.data);
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style scoped>

</style>