<template>
  <v-data-table
      :height="dataTableHeight" fixed-header disable-pagination hide-default-footer
      v-if="expand"
      :headers="headers"
      :items="items"
      :single-expand="singleExpand" :expanded.sync="expanded"
      item-key="id" show-expand
      @click:row="rowClick"
      :search="search">
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" style=" background-color:white;">
        <v-progress-linear
            :active=loading
            indeterminate
            color="#1890ff"
            height="6"/>
        <v-row style="height:270px;" class="rowView">
          <v-col :cols=2 class="colView">
            <v-row v-for="param in item.values" :key=param class="rowView" style="height:33%;">
              <v-col class="rowView main-title subtleBlue" style="height:100%;align-items:center;display:flex;">
                {{ param.title }} :
              </v-col>
              <v-col class="rowView sub-title darkBlue" style="height:100%; align-items:center;display:flex;">
                {{ param.value }} [{{ item.unit }}]
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols=3 class="colView">
            <!-- <v-row  v-if="item.methods.length == 0" class="rowView darkBlue" style="height:75%;align-items:center;display:flex;">
                No validation methods used for this signal.
            </v-row>   -->
            <v-data-table
                height="250"
                fixed-header
                disable-pagination
                hide-default-footer
                :headers="methHeaders"
                :items="item.methods"
                item-key="name"
            />
            <!-- <v-row  v-for="meth in item.methods" :key=meth  class="rowView darkBlue" style="height: 50px;align-items:center;display:flex;">
                {{meth.name}} : {{meth.scText}}
            </v-row>   -->
          </v-col>
          <v-divider vertical/>
          <v-col :cols=7 class="colView" style="max-width:58%">
            <highcharts style="width:100%;height:100%;" :options="chartOptions"></highcharts>
          </v-col>
        </v-row>
      </td>
    </template>
    <template v-slot:header.data-table-expand="{ item }">
      <InfoHover :html="getInfoDict['signalTable']"/>
    </template>
    <template v-slot:item.completeness="{ item }">
      <div v-if="item.completeness!=null && item.completeness>=0">
        {{ item.completeness.toFixed(1) }} %
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template v-slot:item.quality="{ item }">
      <div v-if="item.quality!=null && item.quality>=0">
        {{ item.quality.toFixed(1) }} %
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip :color="getStatusColor(item.status)" :text-color="getStatusTextColor(item.status)"
              style=" font-weight:500 ;font-size:14px;">
        {{ item.status }}
      </v-chip>
    </template>

  </v-data-table>
</template>

<script>

import Highcharts from 'highcharts'
import {mapGetters, mapActions} from 'vuex';
import InfoHover from '../controls/InfoHover';

export default {
  name: "OverviewTable",
  props: {
    headers: Array,
    items: Array,
    expand: Boolean,
    dataTableHeight: Number,
    search: String
  },
  components: {
    InfoHover
  },
  data() {
    return {
      expanded: [],
      singleExpand: true,
      chartOptions: {},
      tempData: [],
      methHeaders: [
        {text: 'Method', value: 'name', class: "big-text titleBlack"},
        {text: 'Quality', value: 'scText', class: "big-text titleBlack", align: 'center', width: '100px'}
      ],
      loading: true
    }
  },
  computed: {
    ...mapGetters(['getExpandRowData', 'getSelIMO', 'getInfoDict', 'getSignalId']),
    itemLoad: function () {
      if (this.items.length == 0) return false;
      else return true;
    }
  },
  methods: {
    ...mapActions(['fetchExpandRowData', 'fetchSignalId']),
    getStatusColor(status) {
      if (status == 'Alert') return '#FFE5E5';
      else if (status == 'Warning') return '#FFEDD8';
      else return '#DFF8EA';
    },
    getStatusTextColor(status) {
      if (status == 'Alert') return '#FF4D4F';
      else if (status == 'Warning') return '#FFAA40';
      else return '#23CE75';
    },
    rowClick(data, item) {

      if (item.isExpanded) item.expand(false);
      else item.expand(true);
    },
    scrollParentToChild(parent, child) {

      var parentRect = parent.getBoundingClientRect();
      var parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth
      };

      var childRect = child.getBoundingClientRect();
      var isViewable = (childRect.top >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height);
      if (!isViewable) {
        parent.scrollTop = (childRect.top + parent.scrollTop) - parentRect.top
      }


    }

  },
  watch: {
    expanded: {
      handler: function () {
        if (this.expanded.length > 0) {

          this.chartOptions = {
            credits: {
              enabled: false
            },
            time: {
              useUTC: true
            },
            chart: {
              zoomType: 'xy'
            },
            title: {
              text: undefined
            },
            tooltip: {
              formatter: function () {
                var s = '<b>' + new Date(this.x).toISOString().substring(0, 16).replace('T', ' ') + ' :</b>';

                s += ' ' + this.point.y.toFixed(2);

                return s;
              },
              crosshairs: true
            },
            yAxis: {
              gridLineWidth: 2,
              title: {
                text: '[' + this.expanded[0].unit + ']',
                align: 'high'
              }
            },
            xAxis: {
              title:
                  {
                    text: undefined
                  },
              type: 'datetime',
              labels: {
                format: '{value: %H:%M}'
              }
            },
            legend: {
              enabled: false
            },
            series: [{
              type: 'line',
              data: [],
              marker: {
                enabled: false
              },
              animation: false,
              states: {
                hover: {
                  enabled: false
                },
                normal: {
                  animation: false
                },
                inactive: {
                  opacity: 1
                }
              }
            }]
          };

          let endDate = new Date(this.expanded[0].timestamp);

          var startDate = new Date(this.expanded[0].timestamp);
          startDate.setMinutes(startDate.getMinutes() - 60);

          let dataForStore =
              {
                imo: this.getSelIMO,
                tag: this.expanded[0].tag,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString()
              };

          this.tempData = [this.expanded[0].tempValid];

          this.loading = true;

          this.fetchExpandRowData(dataForStore);
        }
      },
      deep: true,
      immediate: true
    },
    getExpandRowData: {
      handler: function () {
        let dates = Object.keys(this.getExpandRowData.avg);

        for (let i = 0; i < dates.length; i++) {
          let dt = new Date(dates[i]);

          this.chartOptions.series[0].data.push([dt.getTime(), this.getExpandRowData.avg[dates[i]]]);
        }

        Object.keys(this.getExpandRowData.validated_score).forEach(key => {
          this.tempData.push(key + " : " + this.getExpandRowData.validated_score[key])
        });

        this.loading = false;
      },
      deep: true,
      immediate: true
    },
    getSignalId: {
      handler: function () {
        if (this.itemLoad) {
          let selSignal = this.items.find(i => i.id == this.getSignalId);

          if (selSignal != undefined) {
            this.expanded = [selSignal];

            setTimeout(() => {
              var row = document.getElementsByClassName('v-data-table__expanded__row')[0];

              var parent = document.getElementsByClassName('v-data-table__wrapper')[0];

              this.scrollParentToChild(parent, row);

              this.fetchSignalId(null);

            }, 100)
          }
        }
      },
      deep: true,
      immediate: true
    },
    itemLoad: {
      handler: function () {
        if (this.itemLoad) {
          let selSignal = this.items.find(i => i.id == this.getSignalId);

          if (selSignal != undefined) {
            this.expanded = [selSignal];

            // this.getRowData(selIssue);

            setTimeout(() => {
              var row = document.getElementsByClassName('v-data-table__expanded__row')[0];

              var parent = document.getElementsByClassName('v-data-table__wrapper')[0];

              this.scrollParentToChild(parent, row);

              this.fetchSignalId(null);

            }, 100)

          }

        }

      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style scoped>
</style>

<style>

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px;
  color: rgba(0, 0, 0, .85);
}

/*
.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper){ */

.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover {
  background: rgba(24, 144, 255, 0.2) !important;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded {
  background-color: rgba(24, 144, 255, 0.2)
}

</style>