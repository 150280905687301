<template>
  <v-container class="containerView" style="background-color:white;max-width:200mm !important;">  
    <v-row class="rowView">
      <v-col :cols=6 class="colView" >    
        <v-row class="subtleBlue main-title rowView" style="align-items: center;">{{groupName}}</v-row>
        <v-row class="labelLightBlack sub-title rowView" style="align-items: center;">Last Update: {{reportDate}}</v-row>
      </v-col>
    </v-row>  
    <v-row class="rowView" style="height: 100mm;">
      <v-col :cols="12" class="colView" >  
        <v-card-title>
          KPI
        </v-card-title>
        <v-card-actions>
          <highcharts style="width:100%;height:100%;" :options="kpiGauge" ></highcharts>
        </v-card-actions>      
    
      </v-col>
    </v-row>
     <v-row class="rowView"  style="page-break-after:always;">
      <v-col :cols="12" class="colView"> 
        <v-card-title>Equipment Failures</v-card-title>
        <v-card-actions>
          <v-data-table style="width:100%;height:100%;"
            fixed-header disable-pagination hide-default-footer   
            :headers="topEquipHead"
            :items="topEquipData">
            <template v-slot:item.completeness="{ item }">
              <div v-if="item.completeness!=''">
                  {{ item.completeness.toFixed(1) }}
              </div>
              <div v-else>
                  -
              </div>
            </template>
            <template v-slot:item.quality="{ item }">
              <div v-if="item.quality!=''">
                  {{ item.quality.toFixed(1) }} 
              </div>
              <div v-else>
                  -
              </div>
            </template>
          </v-data-table>              
        </v-card-actions>
        
      </v-col>
    </v-row>
    <v-row class="rowView">
      <v-col :cols="12" class="colView">            
        <v-data-table style="width:100%;height:100%;"
          fixed-header disable-pagination  hide-default-footer                   
          :headers="vesselStatusHead"
          :items="vesselStatusData"
          sort-by='kpi'
          :sort-desc=false
          :search="search">
          <template v-slot:item.status="{ item }">
            <div  class="statusCircle" :style="'background-color: '+getStatusColor(item.status)+';'"></div>                    
          </template>
          <template v-slot:item.kpiTrend="{ item }"> 
            <div v-if="item.kpiTrend==undefined">
              -
            </div>     
            <div v-else>
              {{item.kpiTrend}}                 
              <v-icon v-if="item.kpiTrend>0" size="32" color="#23CE75" style="margin-left:5px;">mdi-trending-up</v-icon>
              <v-icon v-else-if="item.kpiTrend<0" size="32" color="#FF4D4F" style="margin-left:5px;">mdi-trending-down</v-icon>
            </div>                    
          </template>
          <template v-slot:item.lastUpdate="{ item }">
            <div :style="[item.overdueUpdate ? {'color': '#FF4D4F'}:{} ]">
              {{item.lastUpdate}}                
              <!-- <v-icon v-if="item.overdueUpdate" size="32" color="#FF4D4F" style="margin-left:5px;">mdi-update</v-icon> -->
            </div>                    
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    
   
  </v-container>
 
</template>

<script>
/* eslint-disable vue/no-unused-components */

import { mapGetters, mapActions } from 'vuex';   
import Highcharts from 'highcharts'


export default {
  name: 'GroupReport',
  components: {
  },
  data: function () {
    return {
      topEquipHead: [
        { 
          text: 'SENSOR',
          value: 'name', 
          class:"big-text titleBlack"
        },
        { 
          text: 'COMPLETENESS %',
          value: 'completeness',
          class:"big-text titleBlack"
        },
        { 
          text: 'QUALITY %',
          value: 'quality', 
          class:"big-text titleBlack"
        }
      ],
      topEquipData: [],
      selEquipHead: [
        { 
          text: 'VESSEL',
          value: 'name', 
          class:"big-text titleBlack"
        },
        { 
          text: 'COMPLETENESS',
          value: 'completeness',
          class:"big-text titleBlack"
        },
        { 
          text: 'QUALITY',
          value: 'quality', 
          class:"big-text titleBlack"
        }
      ],
      selEquipData: [],
      equipPopup:false,
      search:'',
      kpiGauge:{
        chart: {
          animation: false,
          type: 'solidgauge'
        },          
        credits:{
          enabled:false
        },
        title: {
          text: undefined
        },
        pane: 
        {
          startAngle: -180,
          endAngle: 180,
          background: [{
            outerRadius: '100%',
            innerRadius: '85%',
            borderWidth: 0
          }]
        },
        tooltip: {
          enabled:false
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: []
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: true
            },
            linecap: 'round',
            stickyTracking: false,
            rounded: true
          }
        },
        series: []
      }, 
      vesselStatusHead: [
        { text: 'STATUS', value: 'status' , class:"big-text titleBlack" ,align: 'center'},
        { text: 'VESSEL', value: 'vessel', class:"big-text titleBlack"},
        { text: 'KPI %', value: 'kpi', class:"big-text titleBlack" ,align: 'center'},
        { text: 'KPI TREND %', value: 'kpiTrend', class:"big-text titleBlack" ,align: 'center'},
        { text: 'LAST UPDATE', value: 'lastUpdate', class:"big-text titleBlack" }],
      vesselStatusData:[],
      topVesselHead: [  
        { text: 'VESSEL', value: 'vessel', class:"big-text titleBlack"},
        { text: 'KPI TREND', value: 'kpiTrend', class:"big-text titleBlack" ,align: 'center'}],
      topOptimizedData:[],
      topDeterioratedData:[],
      dataTableHeight:100,
      loading:true,
      selEquip:null,
      groupName:'',
      reportDate:null
    }
  },  
  computed:{
    ...mapGetters(['getGroupOverview', 'getVessels',])
  },
  methods:{
    ...mapActions([]),
    updateDataTableHeight () {
      this.dataTableHeight =this.$refs['parentDiv'].$children[2].$el.clientHeight ;
    },
    getStatusColor(status){
      if (status==20) return '#FF4D4F';
      else if (status==10) return '#FFAA40';
      else return '#23CE75'; 
    },
  },  
  mounted(){
  },
  watch:{    
    getGroupOverview(){  

      if (this.getGroupOverview.length==0) return;
      
      this.kpiGauge.series=[];

      let kpiVal = this.getGroupOverview.kpi;
      let kpiColor= '#FF4D4F';

      if (kpiVal>80) kpiColor ='#23CE75';
      else if (kpiVal>50) kpiColor = '#FFAA40';

      this.kpiGauge.pane.background[0].backgroundColor = Highcharts.color(kpiColor).setOpacity(0.3).get();

      this.kpiGauge.series.push({
        name: 'KPI',
        data: [{
          color: kpiColor,
          radius: '100%',
          innerRadius: '85%',
          y:  Math.round(kpiVal * 10) / 10 
        }],
        dataLabels: {             
          y: -40,
          borderWidth: 0,
          useHTML:true,
          format: '<div style="text-align:center;"><span style="font-size: 60px;color:'+kpiColor+';">{y}%</span></div>'
        },
        animation:false,
        states: {
          normal:{
            animation:false
          }
        },
      })      

      this.vesselStatusData=[];

      Object.keys(this.getGroupOverview.vessels).forEach(vessIMO=>{

        let vessel = this.getVessels.find(v=>v.imo == vessIMO);
        let vessData = this.getGroupOverview.vessels[vessIMO];

        let status;

        let temp = new Date(vessData.last_date);
        let ts = '';

        let overdueUpdate = false, diff = (new Date()-temp)/(60*60*1000) ;

        if (diff>24) overdueUpdate = true;
        
        if (vessData.last_date!=null)
          ts = temp.toISOString().slice(0,10)+' '+temp.toISOString().slice(11,16);
        
        let vessKPI = vessData.kpi;

        if (vessKPI>80) status =0;
        else if (vessKPI>50) status = 10;
        else status = 20;

        let diffKPI = vessData.kpi_diff;
        
        if (diffKPI!=undefined) diffKPI = Math.round(diffKPI*100)/100;

        let row = 
        {
          status:status,
          vessel:vessel.vessel,
          kpi:Math.round(vessKPI*10)/10,
          kpiTrend:diffKPI,
          lastUpdate:ts, 
          overdueUpdate: overdueUpdate
        };

        this.vesselStatusData.push(row);
      })

      this.topEquipData = [];      

      this.getGroupOverview.devices.forEach(item=>{
        let q = item.quality, c = item.completeness;

        if (q=='') q=0;

        item.score = 0.5*q+0.5*c;

        item.vessels.forEach(vess=>
        {
          let tempVess = this.getVessels.find(v=>v.imo == vess.imo);
          vess.name = tempVess.vessel; 
        })
      })

      this.getGroupOverview.devices.sort((a,b) => (a.score > b.score) ? 1 : ((b.score > a.score) ? -1 : 0));

      this.topEquipData = this.getGroupOverview.devices;//.slice(0,5);      

      let dates = Object.values(this.getGroupOverview.vessels).map(v=>v.last_date).sort();

      this.reportDate = dates[dates.length-1].substring(0,16);

      this.loading = false;
    },
    '$route.params': {
      handler: function() {         
        this.groupName = this.$route.params.groupName;
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style scoped>

table.v-table tbody td {
  font-size: 18px !important;
}

.statusCircle
{
  height:24px;              
  width:24px;
  border-radius:50%;
  display:inline-block;
  margin:auto;
}
</style>
